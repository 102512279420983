import MonthSelectorUtils from '../../month_selector/utils/MonthSelectorUtils';
import api from '../../api/index';

const IMMEDIATELY_VISIBLE_MONTHS_COUNT = 6;

export default {
    namespaced: true,

    state: {
        loading: false,
        showAllMonths: false,
        monthSourceData: [],
    },

    getters: {
        getLoading(state) {
            return state.loading;
        },

        showAllMonths(state) {
            return state.showAllMonths;
        },

        monthSourceData(state) {
            return state.monthSourceData;
        },

        limitedMonthSourceData(state) {
            return state.showAllMonths ? state.monthSourceData
                : state.monthSourceData.slice(0, IMMEDIATELY_VISIBLE_MONTHS_COUNT);
        },

        isSelectorOpen(state) {
            return state.monthSourceData.some((item) => item.openDaySelector);
        },

        getMonthDataForRequest(state) {
            const selectedWholeMonths = state.monthSourceData.filter(
                (item) => item.selected && item.is_whole_month,
            );

            const selectedNotWholeMonths = state.monthSourceData.filter(
                (item) => item.selected && !item.is_whole_month,
            );

            const selectedWholeMonthsResult = selectedWholeMonths.map((item) => ({
                start: item.start,
                end: item.end,
            }));

            const selectedNotWholeMonthsResult = selectedNotWholeMonths.map((item) => ({
                month: item.start.substr(0, 7),
                dayz: item.dayz,
            }));

            return selectedWholeMonthsResult.concat(selectedNotWholeMonthsResult);
        },

        selectedMonthsCount(state) {
            const selectedWholeMonths = state.monthSourceData.filter((item) => item.selected);
            return selectedWholeMonths.length;
        },

        monthsSelected(state, getters) {
            return (getters.selectedMonthsCount > 0);
        },

        monthDescriptionIfOnlyOneMonthSelected(state) {
            return state.monthSourceData.find((item) => item.selected).month_info;
        },

        getMonthDescription(state) {
            return state.monthSourceData
                .filter((month) => month.selected)
                .map((month) => month.month_info)
                .join(', ');
        },
        selectedMonthsDescription(state, getters) {
            return getters.selectedMonthsCount === 1 ? getters.monthDescriptionIfOnlyOneMonthSelected : `${getters.selectedMonthsCount} мес.`;
        },
    },

    mutations: {
        setLoading(state, value) {
            // eslint-disable-next-line no-param-reassign
            state.loading = value;
        },

        toggleShowAllMonths(state) {
            state.showAllMonths = !state.showAllMonths;
        },

        toggleSelectedMonth(state, index) {
            const month = state.monthSourceData[index];
            month.selected = !month.selected;
            window.MNewCompaniesConfiguration.calculateConfiguration(true);
        },

        toggleDaySelector(state, index) {
            const month = state.monthSourceData[index];

            const anotherDaySelector = state.monthSourceData.find(
                (item) => item.openDaySelector && state.monthSourceData.indexOf(item) !== index,
            );

            if (anotherDaySelector) {
                anotherDaySelector.openDaySelector = false;
            }

            month.openDaySelector = !month.openDaySelector;
        },

        setMonthSourceData(state, monthSourceData) {
            state.monthSourceData = monthSourceData.map((month) => ({
                openDaySelector: false,
                days: MonthSelectorUtils.daysOfMonth(month.end),
                ...month,
            }));
            state.monthSourceData[0].selected = true;
        },

        setShowDaysData(state, response) {
            const monthNumber = state.monthSourceData.length;
            const showDaysData = response.dayz;
            const noGeoConfig = (response.no_geo_config);

            if (noGeoConfig) {
                for (let i = 0; i < monthNumber; i++) {
                    const month = state.monthSourceData[i];
                    const daysNumber = month.days.length;

                    for (let j = 0; j < daysNumber; j++) {
                        month.days[j].show = true;
                    }
                }
                return;
            }

            for (let i = 0; i < monthNumber; i++) {
                const month = state.monthSourceData[i];
                const monthDate = month.start.substr(0, 7);
                const daysNumber = month.days.length;

                if (showDaysData.hasOwnProperty(monthDate)) {
                    for (let j = 0; j < daysNumber; j++) {
                        if (showDaysData[monthDate].indexOf(month.days[j].day) !== -1) {
                            month.days[j].show = true;
                        } else {
                            month.days[j].show = false;
                        }
                    }
                } else {
                    for (let j = 0; j < daysNumber; j++) {
                        month.days[j].show = false;
                    }
                }
            }
        },

        toggleWholeMonth(state, index) {
            const month = state.monthSourceData[index];
            if (!month.is_whole_month) {
                month.is_whole_month = true;

                if (!month.selected) {
                    month.selected = true;
                }

                month.dayz = [];
                month.days = month.days.map((day) => ({
                    ...day,
                    selected: false,
                }));
                window.MNewCompaniesConfiguration.calculateConfiguration(true);
            }
        },

        selectAllMonths(state) {
            state.monthSourceData = state.monthSourceData.map((month, index) => {
                if (state.showAllMonths) {
                    return { ...month, selected: true };
                }
                return { ...month, selected: index < IMMEDIATELY_VISIBLE_MONTHS_COUNT };
            });
            window.MNewCompaniesConfiguration.calculateConfiguration(true);
        },

        deselectAllMonths(state) {
            state.monthSourceData = state.monthSourceData.map((month) => ({
                ...month,
                selected: false,
            }));
            window.MNewCompaniesConfiguration.calculateConfiguration(true);
        },

        toggleDay(state, dayData) {
            const monthIndex = dayData[0];
            const dayIndex = dayData[1];

            const month = state.monthSourceData[monthIndex];
            const day = month.days[dayIndex];

            day.selected = !day.selected;

            // Как при добавлении, так и при удалении дня
            // месяц этого дня автоматически становится выбранным.
            month.selected = true;

            if (day.selected) {
                month.dayz.push(day.day);
            } else {
                const index = month.dayz.indexOf(day.day);
                month.dayz.splice(index, 1);
            }

            month.is_whole_month = month.days.every((day) => !day.selected);

            window.MNewCompaniesConfiguration.calculateConfiguration(true);
        },

        closeSelector(state) {
            state.monthSourceData.find(
                (months) => months.openDaySelector === true,
            ).openDaySelector = false;
        },
    },

    actions: {
        async getInitialData({ commit }) {
            commit('setLoading', true);
            const monthSourceData = await api.getInitialData();
            commit('setLoading', false);

            commit('setMonthSourceData', monthSourceData);

            // Это сделано для выполнения порядка операций
            // (Сначала setMonthSourceData, после setShowDaysData)
            window.MNewCompaniesConfiguration.calculateConfiguration();
        },
    },
};
