<!-- компонет реализуется уникально для каждого радела -->

<template>
  <div v-if="showNotification">
    <NotificationComponent
      flipper-position="without-flipper"
      class="card-notification__body"
    >
      <div v-if="(companyCost > 0) && !emailSelected && !isSubscriptionMode">
        В выгрузке будут представлены актуальные данные о компаниях с выбранной датой регистрации.
      </div>

      <div v-if="legalFormDisabled">
        Вы не выбрали ни одной правовой формы в конфигураторе.
        Выберите необходимые правовые формы из списка для расчета конфигурации.
      </div>

      <div v-if="isSubscriptionMode && !subscriptionCountIsLow">
        {{ subscriptionStartDate }} отправим вам файл с компаниями на ваш Email, и далее вы будете
        получать файлы с {{ subscriptionPeriodText }} периодичностью. При этом всякий раз вы будете
        получать данные за предыдущ{{ subscriptionPrevPeriodText }}.
      </div>

      <div v-if="isSubscriptionMode && subscriptionCountIsLow">
        Обращаем внимание, что по выбранным вами критериям
        возможно поступление данных не каждый день.
        Рекомендуем добавить города или коды ОКВЭД, чтобы получать компании чаще.
      </div>

      <div v-if="(selectedMonthsCount === 0) && !isSubscriptionMode && selectedCityCount !== 0">
        Выберите необходимые месяцы или дни регистрации компаний
        в списке слева для расчета конфигурации.
      </div>

      <div v-if="emailSelected && (companyCost > 0) && !isSubscriptionMode">
        Вы включили фильтр "E-mail". Количество компаний уменьшилось,
        поскольку не у всех новых компаний нашелся email в открытом доступе.
      </div>

      <div v-if="companiesNotFound">
        <span
          v-if="(entrepreneurWithContactsSelected)"
          class="with-warn-icon"
        >
          У ИП в настоящее время не указываются контактные данные: телефон, мессенджер и Email.
        </span>
        <span v-else>
          К сожалению, по данной конфигурации в нашей базе нет фирм.
          Попробуйте выбрать больше городов и/или ОКВЭД или включить менее жесткие фильтры.
        </span>
      </div>

      <div v-if="companyCost >= CASHLESS_NOTIFICATION">
        Можно оплатить от организации по
        <span
          class="card-notification__clickable-text"
          @click="openCashlessPopup"
        >
          безналичному расчету
        </span>
      </div>

      <div v-if="companyCost > MIN_COST_VIP_NOTIFICATION">
        При покупке больших объемов информации,
        вы можете получить дополнительную скидку от 5 до 60% от стоимости базы. <br> <br>
        Напишите нам на
        <a
          href="mailto:info@export-base.ru"
          class="card-notification__link"
          target="_blank"
        >info@export-base.ru
        </a>
        или позвоните 8 (800) 775-29-12.
      </div>
    </NotificationComponent>
  </div>
</template>

<script>
import NotificationComponent from '../../../../common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'NewCompanyNotificationList',
  components: {
    NotificationComponent,
  },

  props: {
    companyCost: Number,

    selectedOkvedCount: Number,
    selectedCityCount: Number,
    selectedMonthsCount: Number,

    allOkvedSelected: Boolean,

    legalFormDisabled: Boolean,
    phoneSelected: Boolean,
    emailSelected: Boolean,
    selectedLegalForms: Object,

    isSubscriptionMode: Boolean,
    subscriptionCountIsLow: Boolean,
    subscriptionPeriodText: String,
    subscriptionPrevPeriodText: String,
    subscriptionStartDate: String,
  },

  emits: ['openOkvedTree', 'openCityTree', 'openCashlessPopup'],

  data() {
    return {
      CASHLESS_NOTIFICATION: 3000,
      MIN_COST_VIP_NOTIFICATION: 20000,
    };
  },

  computed: {
    entrepreneurWithContactsSelected() {
      return (
        this.selectedLegalForms.types.length === 1
          && this.selectedLegalForms.types[0] === 'entrepreneur'
          && (this.emailSelected || this.phoneSelected)
      );
    },

    companiesNotFound() {
      return (this.selectedCityCount > 0
          && (this.selectedOkvedCount > 0 || this.allOkvedSelected)
          && this.selectedMonthsCount > 0
          && this.companyCost === 0
      );
    },

    showNotification() {
      return ((this.companyCost > 0) && !this.emailSelected && !this.isSubscriptionMode)
          || this.legalFormDisabled || (this.isSubscriptionMode && !this.subscriptionCountIsLow)
          || (this.isSubscriptionMode && this.subscriptionCountIsLow)
          || ((this.selectedMonthsCount === 0)
              && !this.isSubscriptionMode && this.selectedCityCount !== 0)
          || (this.emailSelected && (this.companyCost > 0) && !this.isSubscriptionMode)
          || this.companiesNotFound
          || this.companyCost >= this.CASHLESS_NOTIFICATION
          || this.companyCost > this.MIN_COST_VIP_NOTIFICATION;
    },
  },

  methods: {
    openOkvedTree() {
      this.$emit('openOkvedTree');
    },

    openCityTree() {
      this.$emit('openCityTree');
    },

    openCashlessPopup() {
      this.$emit('openCashlessPopup');
    },
  },
};
</script>

<style scoped src="../../../../common_components/NotificationComponent/NotificationComponent.css" />

<style>
.with-warn-icon {
  display: inline-block;
  padding-left: 35px;
  background-image: url("/dist/css/images/icon_warning.png");
  background-repeat: no-repeat;
  background-size: 27px;
  background-position: left center;
}
</style>
