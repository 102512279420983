<template>
  <div>
    <form action="https://yoomoney.ru/eshop.xml" method="post" target="_blank" class="_form_yandex_kassa form_yandex_kassa">
      <input name="shopId" class="hidden_input" value="42095" type="">
      <input name="scid" class="hidden_input" value="29281" type="">
      <input name="sum" class="hidden_input" :value="sum" type="">
      <input name="customerNumber" class="hidden_input" :value="customerNumber" type="">
      <input name="orderNumber" class="hidden_input" :value="orderNumber" type="">
      <input name="cps_email" class="hidden_input" :value="email" type="">
      <input name="orderType" class="hidden_input" :value="orderType" type="">
      <input name="paymentType" class="hidden_input" value="AC" type="radio" :checked="isPaymentTypeActive('AC')">
      <input name="paymentType" class="hidden_input" value="GP" type="radio" :checked="isPaymentTypeActive('GP')">
      <input name="paymentType" class="hidden_input" value="WM" type="radio" :checked="isPaymentTypeActive('WM')">
      <input name="paymentType" class="hidden_input" value="QW" type="radio" :checked="isPaymentTypeActive('QW')">
      <input name="paymentType" class="hidden_input" value="PC" type="radio" :checked="isPaymentTypeActive('PC')">
      <input name="paymentType" class="hidden_input" value="AB" type="radio" :checked="isPaymentTypeActive('AB')">
      <input name="paymentType" class="hidden_input" value="PB" type="radio" :checked="isPaymentTypeActive('PB')">
      <input name="paymentType" class="hidden_input" value="SB" type="radio" :checked="isPaymentTypeActive('SB')">
      <input name="paymentType" class="hidden_input" value="MA" type="radio" :checked="isPaymentTypeActive('MA')">
      <input class="hidden_input" name="ym_merchant_receipt" :value="ymMerchantReceipt">
      <input type="submit" ref="submitButton" class="yandex_form__submit" value="Оплатить" @click="submitButtonClick">
    </form>
  </div>
</template>

<script>
  export default {
    name: 'YandexForm',
    props: {
      email: String,
      paymentType: String,
      customerNumber: [Number, String],
      orderNumber: [Number, String],
      sum: Number,
      orderType: {
        type: String,
        default: '',
      },
    },

    methods: {
      isPaymentTypeActive(type) {
        return type === this.paymentType;
      },
        submitButtonClick() {
            this.$emit('submitButtonClick');
            MFuncs.goYandexKassa(window.$(this.$refs.submitButton));
        },
    },

    computed: {
      ymMerchantReceipt() {
        // система налогообложения (без НДС)
        const TAX = 1;
        const text = "Предоставление информационных услуг сервиса ExportBase";

        let receipt = {
          customerContact: this.email,
          items: [{
            quantity: 1,
            price: {
              amount: this.sum
            },
            tax: TAX,
            text: text,
            paymentSubjectType: "service",
            paymentMethodType: "full_payment"
          }]
        };

        return JSON.stringify(receipt);
      }
    }

  };
</script>

<style scoped>
  .hidden_input {
    display: none;
  }
  .yandex_form__submit {
    position: relative;
    display: block;
    width: 220px;
    height: 50px;
    margin: auto;
    outline: none;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 1px #333;
    cursor: pointer;
    text-align: center;
    color: #353b31;
    background: linear-gradient(to bottom, #ffe900, #ffcf01);
    font-size: 22px;
    font-style: italic;
    font-weight: 700;
    font-family: "PTSansExportBase", sans-serif;
  }

  .yandex_form__submit:hover {
    box-shadow: 0 0 3px #333;
  }
</style>
