import inputHandling from './inputHandling';

const mutations = {
    toggleLegalForm(state, typeOfLegalForm) {
        state[typeOfLegalForm].chosen = !state[typeOfLegalForm].chosen;

        if (state[typeOfLegalForm].chosen) {
            state.legalForm.types.push(state[typeOfLegalForm].name);
            state.legalFormNameForUser.push(state[typeOfLegalForm].nameForUser);
        } else {
            const index = state.legalForm.types.indexOf(state[typeOfLegalForm].name);
            state.legalForm.types.splice(index, 1);

            const indexForPeople = state.legalFormNameForUser.indexOf(state[typeOfLegalForm].nameForUser);
            state.legalFormNameForUser.splice(indexForPeople, 1);
        }
    },

    toggleExpandAdditionalFilters(state) {
        state.expandAdditionalFilters = !state.expandAdditionalFilters;
        state.additionalFiltersChosen = !state.additionalFiltersChosen;
    },

    toggleAffiliateSumIncome(state) {
        state.affiliateSumIncome.chosen = !state.affiliateSumIncome.chosen;
    },

    toggleAffiliateMaxIncome(state) {
        state.affiliateMaxIncome.chosen = !state.affiliateMaxIncome.chosen;
    },

    setAffiliateSumIncomeMin(state, value) {
        state.affiliateSumIncome.min = inputHandling.sanitizeAffiliateIncome(value);
        window.MNewCompaniesConfiguration.calculateConfiguration();
    },

    setAffiliateSumIncomeMax(state, value) {
        state.affiliateSumIncome.max = inputHandling.sanitizeAffiliateIncome(value);
        window.MNewCompaniesConfiguration.calculateConfiguration();
    },

    setAffiliateMaxIncomeMin(state, value) {
        state.affiliateMaxIncome.min = inputHandling.sanitizeAffiliateIncome(value);
        window.MNewCompaniesConfiguration.calculateConfiguration();
    },

    setAffiliateMaxIncomeMax(state, value) {
        state.affiliateMaxIncome.max = inputHandling.sanitizeAffiliateIncome(value);
        window.MNewCompaniesConfiguration.calculateConfiguration();
    },

    togglePhoneStore(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.phoneFilter.partiallySelected = !state.phoneFilter.partiallySelected;
        } else {
            state.phoneFilter.selected = !state.phoneFilter.selected;

            if (state.phoneFilter.selected) {
                if (state.selectedBasicFilters.indexOf('phone') === -1) {
                    state.selectedBasicFilters.push('phone');
                }
            } else {
                const index = state.selectedBasicFilters.indexOf('phone');
                state.selectedBasicFilters.splice(index, 1);
            }
        }
    },

    toggleAddressStore(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.addressFilter.partiallySelected = !state.addressFilter.partiallySelected;
        } else {
            state.addressFilter.selected = !state.addressFilter.selected;

            if (state.addressFilter.selected) {
                state.selectedBasicFilters.push('address');
            } else {
                const index = state.selectedBasicFilters.indexOf('address');
                state.selectedBasicFilters.splice(index, 1);
            }
        }
    },

    toggleEmailStore(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.emailFilter.partiallySelected = !state.emailFilter.partiallySelected;
        } else {
            state.emailFilter.selected = !state.emailFilter.selected;

            if (state.emailFilter.selected) {
                state.selectedBasicFilters.push('email');
            } else {
                const index = state.selectedBasicFilters.indexOf('email');
                state.selectedBasicFilters.splice(index, 1);
            }
        }
    },

    toggleDecisionMakerStore(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.decisionMakerFilter.partiallySelected = !state.decisionMakerFilter.partiallySelected;
        } else {
            state.decisionMakerFilter.selected = !state.decisionMakerFilter.selected;

            if (state.decisionMakerFilter.selected) {
                state.selectedBasicFilters.push('decision_maker');
            } else {
                const index = state.selectedBasicFilters.indexOf('decision_maker');
                state.selectedBasicFilters.splice(index, 1);
            }
        }
    },

    toggleOgrnStore(state, isPartiallySelectedChange = false) {
        if (isPartiallySelectedChange) {
            state.ogrnSelectedFilter.partiallySelected = !state.ogrnSelectedFilter.partiallySelected;
        } else {
            state.ogrnSelectedFilter.selected = !state.ogrnSelectedFilter.selected;
            const filters = ['ogrn', 'kpp'];
            if (state.ogrnSelectedFilter.selected) {
                state.selectedBasicFilters.push(...filters);
            } else {
                state.selectedBasicFilters = state.selectedBasicFilters.filter((filter) => !filters.includes(filter));
            }
        }
    },

    setSelectedAdditionalFilters(state) {
        if (state.affiliateSumIncome.chosen && state.additionalFilters.indexOf('income_sum') === -1) {
            state.additionalFilters.push('income_sum');
        } else if (!state.affiliateSumIncome.chosen && state.additionalFilters.indexOf('income_sum') !== -1) {
            const index = state.additionalFilters.indexOf('income_sum');
            state.additionalFilters.splice(index, 1);
        }

        if (state.affiliateMaxIncome.chosen && state.additionalFilters.indexOf('income_max') === -1) {
            state.additionalFilters.push('income_max');
        } else if (!state.affiliateMaxIncome.chosen && state.additionalFilters.indexOf('income_max') !== -1) {
            const index = state.additionalFilters.indexOf('income_max');
            state.additionalFilters.splice(index, 1);
        }
    },

    setCityDescription(state, cityDescription) {
        state.cityDescription = cityDescription;
    },

    setVisibleTree(state, value) {
        state.visibleTree = value;
    },

    setVisiblePaymentPopup(state, value) {
        state.visiblePaymentPopup = value;
    },

    setHighLightGeoButton(state, value) {
        state.highlightGeoButton = value;
    },

    setCalculating(state, value) {
        state.isCalculating = value;
    },
};

export default mutations;
