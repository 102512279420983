<template>
  <div
    class="affiliate_sum-income_and_max-income"
    @click="onClick"
  >
    <div class="affiliate-income_type_item">
      <CheckboxRange
        title="сумм. выручка"
        :title-width="titleWidth"
        :checkbox-width="checkboxWidth"
        class="checkbox-range"
        unit="млн/год"
        :disabled="affiliateSumIncomeAndMaxIncomeDisabled"
        :chosen="affiliateSumIncome.chosen"
        :min-value="affiliateSumIncome.min"
        :max-value="affiliateSumIncome.max"
        :not-chosen-handler="notChosenHandler('Пожалуйста, активируйте опцию суммарной выручки аффилированных компаний')"
        @toggle="toggleAffiliateSumIncome"
        @changeMinValue="setAffiliateSumIncomeMin"
        @changeMaxValue="setAffiliateSumIncomeMax"
      />
      <div class="affiliate-income_type_item_comment">
        Опция суммарной выручки показывает суммарный доход всех аффилированных компаний
      </div>
    </div>

    <div class="affiliate-income_type_item">
      <CheckboxRange
        title="макс. выручка"
        :title-width="titleWidth"
        :checkbox-width="checkboxWidth"
        class="checkbox-range"
        unit="млн/год"
        :disabled="affiliateSumIncomeAndMaxIncomeDisabled"
        :chosen="affiliateMaxIncome.chosen"
        :min-value="affiliateMaxIncome.min"
        :max-value="affiliateMaxIncome.max"
        :not-chosen-handler="notChosenHandler('Пожалуйста, активируйте опцию максимальной выручки среди аффилированных компаний')"
        @toggle="toggleAffiliateMaxIncome"
        @changeMinValue="setAffiliateMaxIncomeMin"
        @changeMaxValue="setAffiliateMaxIncomeMax"
      />
      <div class="affiliate-income_type_item_comment">
        Опция максимальной выручки показывает наибольшее значение выручки среди аффилированных компаний
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CheckboxRange from 'checkbox-range';

export default {
  name: 'AffiliateSumIncomeAndMaxIncome',
  components: {
    CheckboxRange,
  },

  data() {
    return {
      titleWidth: 90,
      checkboxWidth: 130,
    };
  },

  computed: {
    ...mapGetters([
      'affiliateSumIncome',
      'affiliateMaxIncome',
      'affiliateSumIncomeAndMaxIncomeDisabled',
      'getPhoneSelected',
    ]),
  },

  methods: {
    ...mapMutations([
      'setAffiliateSumIncomeMin',
      'setAffiliateSumIncomeMax',
      'setAffiliateMaxIncomeMin',
      'setAffiliateMaxIncomeMax',
    ]),

    ...mapActions([
      'toggleAffiliateSumIncome',
      'toggleAffiliateMaxIncome',
    ]),

    onClick() {
      if (this.affiliateSumIncomeAndMaxIncomeDisabled) {
        window.echo('Фильтры cуммарного дохода аффилированных компаний и максимального дохода среди аффилированных компаний недоступны');
      }
    },

    notChosenHandler(msg) {
      return () => {
        echo(msg);
      };
    },
  },
};
</script>

<style scoped>
    .affiliate_sum-income_and_max-income {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .affiliate-income_type_item {
        display: flex;
        justify-content: space-between;
        margin-left: 24px;
        margin-bottom: 10px;
    }

    .affiliate-income_type_item_comment {
        width: 40%;
        margin-right: 30px;
        font-style: italic;
    }
</style>
