import { Tree, NodeValue } from 'tree';
import plural from 'plural-ru';
import api from '../../api/index';

export default {
    namespaced: true,

    state: {
        /**
           * @var {Tree}
           */
        tree: {},

        searchString: '',

        /**
           * @var {Tree}
           */
        searchTree: {},

        tooltipVisible: false,

    },

    getters: {
        tooltipVisible(state) {
            return state.tooltipVisible;
        },

        getGeoTree: (state) => state.tree,
        getGeoSearchTree: (state) => state.searchTree,
        isSearchMode: (state) => (state.searchString.length >= 3),
        emptySearchResult: (state) => state.searchTree.isEmpty(),

        // Возращает true, false. Если дерево полностью выбрано
        treeFullSelected(state) {
            if (!state.tree.root) { // Перед инициализацией дерева недоступно значение root
                return false;
            }
            return state.tree.root.value.isChosen && !state.tree.root.value.isPartiallyChosen;
        },

        // Есть ли выбранные города
        hasSelectedCities(state) {
            // когда дерево не прогрузилось возвращаем true, так как по умолчанию выбрана вся Россия
            if (!state.tree.root) {
                return true;
            }
            return state.tree.root.value.isPartiallyChosen || state.tree.root.value.isChosen;
        },

        selectedCities(state) {
            if (!state.tree.root) {
                return [];
            }
            const selectedCities = state.tree.getChosenNodesFromLevel(4);

            // Так как некоторые города используются, как регионы без городов
            // Добавляем их вручную
            state.tree.getChosenNodesFromLevel(3).forEach((region) => {
                if (region.value.obj.name === 'Севастополь'
                  || region.value.obj.name === 'Байконур'
                  || region.value.obj.name === 'Москва'
                  || region.value.obj.name === 'Санкт-Петербург'
                ) {
                    selectedCities.push(region);
                }
            });

            return selectedCities;
        },

        cityIsEmpty(state, getters) {
            return getters.selectedCities.length === 0;
        },

        // выбранные пункты, отформатированные для отправки запроса на сервер
        selectedGeoUnits(state) {
            if (!state.tree.root) {
                return { regions: [79], cities: [], hidden_cities_regions: [] };
            }
            const chosenRegions = state.tree.getChosenNodesFromLevel(3)
                .map((region) => region.value.obj.id);

            const chosenCities = state.tree.getChosenNodesFromLevel(4)
                .filter((city) => !chosenRegions.includes(city.value.obj.id_region) && !city.value.obj.hide)
                .map((city) => city.value.obj.id);

            const hiddenCities = state.tree.getChosenNodesFromLevel(4)
                .filter((city) => !chosenRegions.includes(city.value.obj.id_region) && city.value.obj.hide)
                .map((city) => city.value.obj.id - 100000); // Минусуем так как это задано вручную, что бы Id были уникальными

            return {
                regions: chosenRegions,
                cities: chosenCities,
                hidden_cities_regions: hiddenCities,
            };
        },
    },

    mutations: {
        setGeoTree: (state, payload) => {
            let { territories } = payload;

            territories = new Set(
                territories
                    .map((territory) => {
                        territory.id = Number(territory.id);
                        return territory;
                    })
                    .filter((territory) => territory.id !== 9),
            );

            state.tree = new Tree(4);

            let { regions } = payload;

            regions = regions.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            regions = regions.sort((a, b) => {
                if (a.name === 'Московская область' || a.name === 'Санкт-Петербург'
              || a.name === 'Севастополь') {
                    return -1;
                }
                if (b.name === 'Московская область' || b.name === 'Санкт-Петербург'
              || b.name === 'Севастополь') {
                    return 1;
                }
                return 0;
            });

            regions = regions.sort((a, b) => {
                if (a.name === 'Москва') {
                    return -1;
                }
                if (b.name === 'Москва') {
                    return 1;
                }
                return 0;
            });

            let { cities } = payload;
            cities = cities.sort((a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            });

            cities = cities.sort((a, b) => {
                if (a.extra === 1) {
                    return -1;
                }
                if (b.extra === 1) {
                    return 1;
                }
                return 0;
            });

            const hiddenCities = payload.hidden_cities_regions;

            // добавление 1-ого уровня - страна
            const nodeValue = new NodeValue(
                { id: 1, name: 'Россия' },
                1,
                true,
                false,
                false,
                true,
            );
            state.tree.addNodeByParentId(nodeValue, 0);

            territories.forEach((territory) => {
                const nodeValue = new NodeValue(
                    territory,
                    2,
                    true,
                    false,
                    false,
                    true,
                );
                state.tree.addNodeByParentId(nodeValue, 1);
            });

            regions.forEach((region) => {
                let nodeValue = new NodeValue(region, 3);

                if (region.name === 'Санкт-Петербург'
              || region.name === 'Москва'
              || region.name === 'Севастополь'
                ) {
                    region.additional = 1;
                    region.main = 1;
                    nodeValue = new NodeValue(
                        region,
                        3,
                        false,
                        false,
                        false,
                        true,
                    );
                }

                if (region.name === 'Байконур') {
                    region.second = 1;
                }

                state.tree.addNodeByParentId(nodeValue, region.id_territory);
            });

            cities.forEach((city) => {
                const nodeValue = new NodeValue(city, 4);
                state.tree.addNodeByParentId(nodeValue, city.id_region);
            });

            hiddenCities.forEach((city) => {
                const description = plural(
                    city.count,
                    'населенный пункт',
                    'населенных пункта',
                    'населенных пунктов',
                );
                city.name = `еще ${city.count} ${description} в регионе "${city.name}"`;
                city.important = 1;
                const nodeValue = new NodeValue(city, 4);
                state.tree.addNodeByParentId(nodeValue, city.id_region);
            });
        },

        expandGeoNode: (state, nodeDesc) => {
            state.tree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        chooseGeoNode: (state, nodeDesc) => {
            state.tree.chooseNode(nodeDesc.level, nodeDesc.id);
        },

        setSearchString(state, searchString) {
            state.searchString = searchString;
        },
        runSearch(state) {
            state.searchTree = state.tree.filterTreeByNodeName(state.searchString);
        },
        resetSearch(state) {
            state.searchString = '';
        },
        expandGeoSearchNode: (state, nodeDesc) => {
            state.searchTree.expandNode(nodeDesc.level, nodeDesc.id);
        },
        syncSearchTree: (state) => {
            state.searchTree.copyStateOfChoice(state.searchTree.root, state.tree);
        },

        chooseOrCancelTree: (state, choice) => {
            state.tree.selectOrDropAllNodes(choice);
        },

        chooseOrCancelSearchTree(state, choice) {
            state.searchTree.selectOrDropAllNodes(choice);
        },

        setTooltipVisible(state, value) {
            state.tooltipVisible = value;
        },
    },

    actions: {
        async setGeoTree({ commit }) {
            const geoUnits = await api.getGeoUnits();
            commit('setGeoTree', geoUnits);
        },
        chooseGeoSearchNode: ({ commit }, nodeDesc) => {
            commit('chooseGeoNode', nodeDesc);
            commit('syncSearchTree');
        },
        updateSearch: ({ commit, getters }, searchString) => {
            commit('setSearchString', searchString);
            if (getters.isSearchMode) {
                commit('runSearch');
            }
        },

        chooseGeoNode({ commit }, nodeDesc) {
            commit('chooseGeoNode', nodeDesc);
            commit('runSearch');
        },
    },
};
