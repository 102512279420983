<template>
  <div class="month_wrapper">
    <div
      class="month_button"
      @click="toggle"
    >
      <span class="month_checkbox">
        <Checkbox :chosen="selected" />
      </span>
      <div class="month_image">
        <img
          src="./_img/one_month.jpg"
          alt="one_month.jpg"
          width="28"
          height="32"
        >
        <div
          v-if="hotMode"
          class="icon_flame_wrapper"
        >
          <img
            class="icon_flame"
            :class="[typeOfFire]"
            src="./_img/fire_icon.png"
            alt="fire_icon.png"
          >
        </div>
        <div
          v-else
          class="month_numb"
        >
          {{ month }}
        </div>
      </div>

      <div>
        <div
          class="month_description_header"
          :class="[{ selected }]"
        >
          <span class="month_description_static_text">Зарегистрированы в</span>
          <span class="month_name_uppercase">
            {{ monthName }} {{ year }}
          </span>
        </div>
        <div class="month_description_count_info">
          Компаний с Email: <b>{{ formatNumber(totalWithEmails) }}</b> шт.,
          с телефонами: <b>{{ formatNumber(totalWithPhones) }}</b> шт.
        </div>
      </div>
    </div>

    <!--  Селектор по дням -->
    <div
      v-if="showDaysSelector"
      class="day_selector"
    >
      <span
        class="day_selector_description"
        @click="toggleSelector"
      >
        {{ selectedDaysDescription }}
      </span>

      <div
        v-if="openDaySelector && !isAllDaysEmpty"
        class="day_selector_wrapper selector-items-list"
      >
        <div
          class="selector-item"
          :class="wholeMonthItemClass"
          @click="toggleWholeMonth"
        >
          Весь месяц
        </div>
        <ul class="days_list">
          <li
            v-for="(day, num) in days"
            :key="num"
            class="selector-item"
          >
            <div
              v-if="day.show"
              :class="dayItemClass(day.selected)"
              @click="toggleDay(num)"
            >
              {{ dayText(num + 1) }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from 'checkbox';
import MonthSelectorUtils from '../../utils/MonthSelectorUtils';

export default {
  name: 'RegistrationMonth',
  components: { Checkbox },
  props: {
    index: Number,
    selected: Boolean,
    monthName: String,
    year: String,
    month: String,
    totalWithPhones: String,
    totalWithEmails: String,
    monthGenitive: String,
    hotMode: Boolean,
    hotModeBig: Boolean,
    hotModeMedium: Boolean,
    hotModeSmall: Boolean,
    showDaysSelector: Boolean,
    openDaySelector: Boolean,
    days: Array,
  },

  computed: {
    selectedDaysDescription() {
      if (!this.isAllDaysEmpty) {
        const firstDay = `${this.getFirstDayOfMonth} ${this.monthGenitive}`;
        const lastDay = `${this.getLastDayOfMonth} ${this.monthGenitive}`;
        const selectedDays = this.days.filter((day) => day.selected);

        if (this.isWholeMonthSelected || this.days.every((day) => day.selected)) {
          return `${firstDay} - ${lastDay}`;
        }
        if (selectedDays.length === 1) {
          return `${selectedDays[0].day} ${this.monthGenitive}`;
        }
        return `${selectedDays.length} ${MonthSelectorUtils.genitivePluralOfDays(selectedDays.length)}`;
      }

      return '0 компаний по данной конфигурации';
    },

    isAllDaysEmpty() {
      const days = this.days.filter((item) => item.show);
      const dayFirst = days[0];
      const dayLast = days[days.length - 1];

      return !(dayFirst && dayLast);
    },

    getFirstDayOfMonth() {
      const day = this.days.find((item) => item.show);
      return day ? day.day : '';
    },

    getLastDayOfMonth() {
      const days = this.days.filter((item) => item.show);
      return days.length ? days[days.length - 1].day : '';
    },

    wholeMonthItemClass() {
      return [
        'whole_month_item',
        { whole_month_item_active: this.isWholeMonthSelected },
      ];
    },

    isWholeMonthSelected() {
      return this.days.every((day) => !day.selected);
    },

    typeOfFire() {
      return {
        icon_flame_big: this.hotModeBig,
        icon_flame_medium: this.hotModeMedium,
        icon_flame_small: this.hotModeSmall,
      };
    },
  },

  methods: {
    toggle() {
      this.$emit('toggle');
    },

    toggleSelector() {
      this.$emit('toggleSelector');
    },

    toggleDay(dayIndex) {
      this.$emit('toggleDay', [this.index, dayIndex]);
    },

    toggleWholeMonth() {
      this.$emit('toggleWholeMonth');
    },

    dayItemClass(selected) {
      return [
        'day_selector_item',
        { day_selector_item_active: selected },
      ];
    },

    dayText(num) {
      const dayNumber = MonthSelectorUtils.makeTwoDigits(num);
      return `${dayNumber} ${this.monthGenitive}`;
    },

    formatNumber(num) {
      return Number(num).toLocaleString('ru');
    },
  },
};
</script>

<style scoped src="../../../../assets/css/element.css"></style>

<style scoped src="./RegistrationMonth.css"></style>
