export default {
    makeTwoDigits(num) {
        return ( num >= 0 && num <= 9) ? "0" + num : "" + num;
    },
    monthLastDate(year, month) {
        let date = new Date(year, month, 0);
        return date.getDate();
    },

    /**
     * подготовить структуру для селектора выбора дней
     * @returns {{day, selected: boolean}[]}
     * @param monthEndDate
     */
    daysOfMonth(monthEndDate) {
        const daysNumber = +monthEndDate.substr(-2);
        return Array(daysNumber).fill(undefined).map((val, idx) => ({
            day: this.makeTwoDigits(idx + 1),
            selected: false,
            show: false
        }));
    },
    yesterdayDate() {
        let today = new Date();
        today.setDate(today.getDate() - 1);
        return today.getDate();
    },
    genitivePluralOfDays(daysNumber) {
        if (daysNumber % 10 > 1 && daysNumber % 10 < 5 && Math.trunc(daysNumber / 10) !== 1) {
            return 'дня';
        } else if (daysNumber % 10 === 1 && Math.trunc(daysNumber / 10) !== 1) {
            return 'день';
        } else {
            return 'дней';
        }
    }
}
