import axios from "axios";

export default {
    async createSubscription(req) {
        return (await axios.post(
            '/handlers/new_companies_subscription_request.php',
            req,
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            }
        )).data;
    }
}
