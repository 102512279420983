<template>
  <div
    class="subscriptionFormItem"
    :class="{'balanceInAction': useBalanceCase, 'active': active}"
  >
    <div class="subscriptionFormItemTitle">
      <span>Ваш баланс</span>
    </div>

    <div
      class="balance"
      :class="{'balance_low': isLow}"
    >
      {{ balance }} руб.
    </div>

    <span
      v-show="useBalanceCase && active"
      class="newCompaniesSubscriptionInputSubText"
    >
      хватит на {{ enoughForCount }} компаний
    </span>
  </div>
</template>

<script>
export default {
  name: 'Balance',
  props: {
    balance: {
      type: String,
      required: true,
    },

    isLow: {
      type: Boolean,
      required: true,
    },

    enoughForCount: {
      type: Number,
      default: 0,
    },

    useBalanceCase: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
    },
  },
};
</script>

<style scoped src="../../Subscription.css"></style>
