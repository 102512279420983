const state = {
    isCalculating: false,

    affiliateSumIncome: {
        min: 0,
        max: 120,
        chosen: false,
    },

    affiliateMaxIncome: {
        min: 0,
        max: 120,
        chosen: false,
    },

    entrepreneur: {
        chosen: true,
        name: 'entrepreneur',
        nameForUser: 'ИП',
    },

    ooo: {
        chosen: true,
        name: 'ooo',
        nameForUser: 'ООО',
    },

    other: {
        chosen: false,
        name: 'other',
        nameForUser: 'АО, ПАО и другие',
    },

    legalForm: {
        types: ['entrepreneur', 'ooo'],
    },

    legalFormNameForUser: ['ИП', 'ООО'],

    affiliateSumIncomeAndMaxIncomeDisabled: false,
    expandAdditionalFilters: false,
    legalFormDisabled: false,

    nameFilter: {
        name: 'name',
        title: 'Название / ИНН / ОКВЭД',
        selected: true,
        partiallySelected: false,
        disabled: true,
    },

    phoneFilter: {
        name: 'phone',
        title: 'Телефон компании',
        selected: true,
        partiallySelected: false,
        subFilters: [
            {
                name: 'prufen',
                title: 'подтвержденные',
                selected: true,
            },
            {
                name: 'privaten',
                title: 'мобильные',
                selected: true,
                explain: '<b>(Все проверены через HLR-сервис)</b>',
            },
        ],
    },

    addressFilter: {
        name: 'address',
        title: 'Адрес и индекс',
        selected: false,
        partiallySelected: false,
    },

    emailFilter: {
        name: 'email',
        title: 'Email компании',
        selected: false,
        partiallySelected: false,
    },

    decisionMakerFilter: {
        name: 'decision_maker',
        title: 'Руководитель (по ЕГРЮЛ)',
        selected: false,
        partiallySelected: false,
    },

    ogrnSelectedFilter: {
        name: 'ogrn',
        title: 'ОГРН / КПП',
        selected: false,
        partiallySelected: false,
    },

    phone: ['prufen', 'privaten'],
    privaten: {},

    selectedBasicFilters: ['phone'],
    additionalFilters: [],

    cityDescription: 'Москва',

    visibleTree: false,

    visiblePaymentPopup: false,

    highlightGeoButton: false,
};

export default state;
