<template>
  <div class="okved-widget">
    <div
      class="okved-selector"
      @click="openOkvedTree"
    >
      <span class="okved-selector__text">
        <b>Коды ОКВЭД:</b>
      </span>
      <ButtonSelector
        :content="getDescription"
        is-important-selector
        :class="{'selector-nothing-selected': okvedIsEmpty & itIsTimeToShow}"
        width="200px"
        class="cityTrees"
        font-size="17px"
      />
    </div>
    <NewCompanyOkvedTree v-if="visibleTree" />
  </div>
</template>

<script>
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import ButtonSelector from 'button-selector';
import NewCompanyOkvedTree from '@/new_company/new_company_configurator/components/NewCompanyOkvedTree/NewCompanyOkvedTree.vue';

export default {
  name: 'OkvedWidget',
  components: {
    NewCompanyOkvedTree,
    ButtonSelector,
  },

  props: {
    itIsTimeToShow: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState('okvedTree', [
      'visibleTree',
      'tree',
    ]),

    ...mapGetters('okvedTree', [
      'getSelectedOkveds',
      'allOkvedsSelected',
      'okvedIsEmpty',
    ]),

    getDescription() {
      if (this.allOkvedsSelected) {
        return 'Все ОКВЭД';
      }
      const selectedOkvedsNumber = this.getSelectedOkveds.length;
      return selectedOkvedsNumber ? `${selectedOkvedsNumber} ОКВЭД` : 'Не выбрано';
    },
  },

  async created() {
    await this.setOkvedTree();
  },

  methods: {
    ...mapMutations('okvedTree', [
      'setVisibleTree',
    ]),

    ...mapActions('okvedTree', [
      'setOkvedTree',
    ]),

    openOkvedTree() {
      this.setVisibleTree(true);
    },
  },
};
</script>

<style scoped src="../../../../assets/css/element.css" />

<style scoped>
.okved-widget {
  width: max-content;
}
.okved-selector {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.okved-selector__text {
  font-size: 14px;
}
</style>
