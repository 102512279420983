import api from '../../api/index';

export default {
    namespaced: true,

    state: {
        cost: 0,
        count: 0,
        visibleDiscount: 0,
        relativeDiscountValue: 0,

        summaryType: 'newCompany',

        phoneSelected: true,
        emailSelected: false,
        legalFormDisabled: false,

        isSubscriptionMode: false,

        subscriptionPeriod: {
            week: {
                chosen: true,
            },
            month: {
                chosen: false,
            },
        },
        subscriptionDeposit: 1000,
        subscriptionStartDate: '',

        selectedConfiguratorFields: {
            basic_filters: ['phone'],
            legal_form: {
                types: ['entrepreneur', 'ooo'],
            },
            additional_filters: [],
        },

        newCompanyPrice: {
            legal_form: {
                entrepreneur: 0,
                ooo: 0,
                other: 0,
            },
            address: 0,
            base: 0,
            decision_maker: 0,
            email: 0,
            income_max: 0,
            income_sum: 0,
            inn: 0,
            msp: 0,
            phone: 0,
            trust_index: 0,
            ogrn: 0,
            kpp: 0,
        },

        newCompaniesSubscriptionAdditiveCost: 50,

        costOfOneFirm: 0,

        approximateCompCountPerSubscrPeriod: 0,

        subscriptionConfigData: {
            // todo: отделить данные о клиента от настроек подписки
            is_authorized: false,
            id_user: null,
            email: null,
            balance_for_showing: 0,
            balance_for_calculation: 0,

            min_date: '',
            // планируется ли пополнение баланса?
            deposit_refill: true,
            deposit_add_sum: 0,
        },
    },

    getters: {

        totalCost(state) {
            return state.cost;
        },

        totalCount(state) {
            return state.count;
        },

        getOneCompanyCost(state) {
            return state.costOfOneFirm;
        },

        isSubscriptionMode(state) {
            return state.isSubscriptionMode;
        },

        chosenSubscriptionPeriod(state) {
            for (const period in state.subscriptionPeriod) {
                if (state.subscriptionPeriod[period].chosen) {
                    return period;
                }
            }
        },

        subscriptionStartDateHumanReadable(state) {
            const dateArr = state.subscriptionStartDate.split('-');
            const year = dateArr[0];
            let month = dateArr[1];
            const day = dateArr[2];

            switch (month) {
            case '01':
                month = 'января';
                break;
            case '02':
                month = 'февраля';
                break;
            case '03':
                month = 'марта';
                break;
            case '04':
                month = 'апреля';
                break;
            case '05':
                month = 'мая';
                break;
            case '06':
                month = 'июня';
                break;
            case '07':
                month = 'июля';
                break;
            case '08':
                month = 'августа';
                break;
            case '09':
                month = 'сентября';
                break;
            case '10':
                month = 'октября';
                break;
            case '11':
                month = 'ноября';
                break;
            case '12':
                month = 'декабря';
                break;
            }

            return `${day} ${month} ${year}`;
        },

        subscriptionDeposit(state) {
            return state.subscriptionConfigData.deposit_add_sum;
        },

        subscriptionOneCompanyCostText(state) {
            let oneFirmCost;
            if (state.costOfOneFirm > 100) {
                oneFirmCost = (state.costOfOneFirm / 100).toFixed(2);
            } else if (state.costOfOneFirm === 0) {
                oneFirmCost = 0;
            } else if (state.costOfOneFirm <= 1 && state.costOfOneFirm > 0) {
                oneFirmCost = '< 1';
            } else {
                oneFirmCost = state.costOfOneFirm;
            }

            let costUnit;
            if (state.costOfOneFirm > 100) {
                costUnit = 'руб.';
            } else {
                costUnit = 'коп.';
            }

            return {
                oneFirmCost,
                costUnit,
            };
        },

        subscriptionStartDate(state) {
            return state.subscriptionStartDate;
        },

        subscriptionUserBalance(state) {
            return state.subscriptionConfigData.balance_for_calculation;
        },

        userId(state) {
            return state.subscriptionConfigData.id_user;
        },

        userEmail(state) {
            return state.subscriptionConfigData.email;
        },

        subscriptionDepositRefill(state) {
            return state.subscriptionConfigData.deposit_refill;
        },

        enoughForCountWithRefill(state) {
            if (state.costOfOneFirm === 0) {
                return 0;
            }

            return Math.floor(
                (state.subscriptionConfigData.deposit_add_sum * 100) / state.costOfOneFirm,
            );
        },

        enoughForCountOnlyBalance(state) {
            if (state.costOfOneFirm === 0) {
                return 0;
            }

            return Math.floor(
                (state.subscriptionConfigData.balance_for_calculation * 100) / state.costOfOneFirm,
            );
        },

        enoughForCount(state, getters) {
            return state.subscriptionConfigData.deposit_refill
                ? getters.enoughForCountWithRefill : getters.enoughForCountOnlyBalance;
        },
    },

    mutations: {
        setPhoneSelected(state, value) {
            state.phoneSelected = value;
        },

        setEmailSelected(state, value) {
            state.emailSelected = value;
        },

        setLegalFormDisabled(state, value) {
            state.legalFormDisabled = value;
        },

        setCost(state, value) {
            state.cost = value;
        },

        setCostOfOneFirm(state, value) {
            state.costOfOneFirm = value;
        },

        setCount(state, value) {
            state.count = value;
        },

        setRelativeDiscountValue(state, value) {
            state.relativeDiscountValue = value;
        },

        setVisibleDiscount(state, value) {
            state.visibleDiscount = Math.round(value);
        },

        updateOneCompanyCostAndCount(state) {
            if (state.isSubscriptionMode) {
                state.costOfOneFirm = state.newCompanyPrice.base + state.newCompaniesSubscriptionAdditiveCost;
            } else {
                state.costOfOneFirm = state.newCompanyPrice.base;
            }

            const basicFiltersCount = state.selectedConfiguratorFields.basic_filters.length;

            for (let i = 0; i < basicFiltersCount; i++) {
                state.costOfOneFirm += state.newCompanyPrice[state.selectedConfiguratorFields.basic_filters[i]];
            }

            const legalFormFiltersCount = state.selectedConfiguratorFields.legal_form.types.length;

            for (let i = 0; i < legalFormFiltersCount; i++) {
                state.costOfOneFirm += state.newCompanyPrice.legal_form[state.selectedConfiguratorFields.legal_form.types[i]];
            }

            const additionalFiltersCount = state.selectedConfiguratorFields.additional_filters.length;

            for (let i = 0; i < additionalFiltersCount; i++) {
                state.costOfOneFirm += state.newCompanyPrice[state.selectedConfiguratorFields.additional_filters[i]];
            }
        },

        setNewCompanyPrice(state, price) {
            state.newCompanyPrice = price;
        },

        setApproximateCompCountPerSubscrPeriod(state, count) {
            state.approximateCompCountPerSubscrPeriod = count;
        },

        setSubscriptionInitialData(state, data) {
            state.subscriptionConfigData = { ...state.subscriptionConfigData, ...data };
            state.subscriptionStartDate = state.subscriptionConfigData.min_date;
        },

        setDepositRefill(state, val) {
            state.subscriptionConfigData.deposit_refill = val;
        },

        setSelectedConfiguratorFieldsLegalForm(state, value) {
            state.selectedConfiguratorFields.legal_form = value;
        },

        setSelectedConfiguratorFieldsBasicFilters(state, value) {
            state.selectedConfiguratorFields.basic_filters = value;
        },

        setSelectedConfiguratorFieldsAdditionalFilters(state, value) {
            state.selectedConfiguratorFields.additional_filters = value;
        },

        selectSubscriptionPeriod(state, period) {
            state.subscriptionPeriod[period].chosen = true;

            for (const periodKey in state.subscriptionPeriod) {
                if (periodKey !== period) {
                    state.subscriptionPeriod[periodKey].chosen = false;
                }
            }
        },

        setDepositSum(state, sum) {
            state.subscriptionConfigData.deposit_add_sum = +sum;
        },

        setStartDate(state, date) {
            const curDate = new Date(date);
            const minDate = state.subscriptionConfigData.min_date;
            if (curDate.getTime() < (new Date(minDate)).getTime()) {
                var startDate = minDate;
            } else {
                startDate = date;
            }

            state.subscriptionStartDate = date;
        },

        toggleSubscriptionMode(state) {
            state.isSubscriptionMode = !state.isSubscriptionMode;

            if (window.location.pathname === '/new') {
                if (state.isSubscriptionMode) {
                    window.location.hash = '#subscription';
                    // блокировка ссылки на страницу подписок в меню слева
                    const leftMenuSubscriptionLink = document.querySelector('li.newCompanySubscriptionPageLeftMenuLink');
                    leftMenuSubscriptionLink.innerHTML = '<span class="_noaction">Подписка на новые компании</span>';
                } else {
                    window.location.hash = '#db';
                    // разблокировка ссылки на страницу подписок в меню слева
                    const leftMenuSubscriptionLink = document.querySelector('li.newCompanySubscriptionPageLeftMenuLink');
                    leftMenuSubscriptionLink.innerHTML = '<a class="a _menu_click" data-menu="new_companies_subscription" href="/new#subscription" target="_blank">Подписка на новые компании</a>';
                }
            }
        },

        setSubscriptionMode(state, isSubscriptionMode) {
            state.isSubscriptionMode = isSubscriptionMode;

            if (state.isSubscriptionMode) {
                // блокировка ссылки на страницу подписок в меню слева
                const leftMenuSubscriptionLink = document.querySelector('li.newCompanySubscriptionPageLeftMenuLink');
                leftMenuSubscriptionLink.innerHTML = '<span class="_noaction">Подписка на новые компании</span>';
            }
        },
    },

    actions: {
        setPrice(context) {
            api.getPrice().then((price) => {
                context.commit('setNewCompanyPrice', price);
            }).then(() => {
                context.commit('updateOneCompanyCostAndCount');
            });
        },

        setSelectedConfiguratorFields(context) {
            context.commit('setSelectedConfiguratorFieldsLegalForm', context.rootGetters.getSelectedLegalForms);
            context.commit('setSelectedConfiguratorFieldsBasicFilters', context.rootGetters.getSelectedBasicFilters);

            if (context.rootGetters.expandAdditionalFilters) {
                context.commit('setSelectedAdditionalFilters', null, { root: true });
                context.commit('setSelectedConfiguratorFieldsAdditionalFilters', context.rootGetters.getSelectedAdditionalFilters);
            }

            context.commit('updateOneCompanyCostAndCount');
        },

        setApproximateCompCountPerSubscrPeriod(context) {
            api.getApproximateCompCountPerSubscrPeriod().then((count) => {
                context.commit('setApproximateCompCountPerSubscrPeriod', +count);
            });
        },

        setSubscriptionInitialData(context) {
            api.getSubscriptionInitialData().then((data) => {
                context.commit('setSubscriptionInitialData', data);
            });
        },

        selectSubscriptionPeriod(context, period) {
            context.commit('selectSubscriptionPeriod', period);
            context.dispatch('setApproximateCompCountPerSubscrPeriod');
        },

        setDepositSum(context, sum) {
            context.commit('setDepositSum', sum);
        },

        setStartDate(context, date) {
            context.commit('setStartDate', date);
        },

        toggleSubscriptionMode(context) {
            context.commit('toggleSubscriptionMode');
            context.dispatch('calculateConfiguration', null, { root: true });
        },

        setSubscriptionMode(context, isSubscriptionMode) {
            context.commit('setSubscriptionMode', isSubscriptionMode);
        },
    },
};
