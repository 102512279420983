<template>
  <div class="description">
    <p class="description__title">
      Ваш заказ:
    </p>
    <div class="description-list">
      <RowCost
        :cost="cost"
        :deactivated="visibleDiscountPrice"
      />
      <transition name="fade">
        <RowCostWithDiscount
          v-show="visibleDiscountPrice"
          :discount="discount"
          :cost="costWithDiscount"
          :promocode-applied="promocodeApplied"
        />
      </transition>

      <RowBase
        :count-companies="countCompanies"
      />
      <slot />
      <RowDemo
        v-if="withDemoSection"
        :visible-demo-section="visibleDemoSection"
        @choose-demo-section="$emit('choose-demo-section')"
      />
      <RowSaveOrder
        v-if="withSaveSection"
        :visible-save-section="visibleSaveSection"
        @choose-save-section="$emit('choose-save-section')"
      />
      <RowPromocode
        v-if="withPromocode"
        class="row-promocode"
        :promocode="promocode"
        :email="email"
        :promocode-is-loading="promocodeIsLoading"
        :promocode-applied="promocodeApplied"
        @submittedPromo="$emit('submittedPromo')"
        @inputPromocode="(text) => $emit('inputPromocode', text)"
        @closePopup="$emit('closePopup')"
      />
    </div>
  </div>
</template>

<script>
import RowBase from '@/common_components/PayPopup/OrderDescription/RowBase.vue';
import RowDemo from '@/common_components/PayPopup/OrderDescription/RowDemo.vue';
import RowSaveOrder from '@/common_components/PayPopup/OrderDescription/RowSaveOrder.vue';
import RowCostWithDiscount from '@/common_components/PayPopup/OrderDescription/RowCostWithDiscount.vue';
import RowPromocode from '@/common_components/PayPopup/OrderDescription/RowPromocode.vue';
import RowCost from './RowCost.vue';

export default {
  name: 'OrderDescription',
  components: {
    RowPromocode,
    RowCostWithDiscount,
    RowDemo,
    RowBase,
    RowCost,
    RowSaveOrder,
  },

  props: {
    idOrder: {
      type: String,
      required: true,
    },

    cost: {
      type: Number,
      required: true,
    },

    costWithDiscount: {
      type: Number,
      required: true,
    },

    countCompanies: {
      type: Number,
      required: true,
    },

    withDemoSection: {
      type: Boolean,
      required: true,
    },

    withSaveSection: {
      type: Boolean,
      required: true,
    },

    visibleDemoSection: {
      type: Boolean,
      required: true,
    },

    visibleSaveSection: {
      type: Boolean,
      required: true,
    },

    chosenTab: {
      type: String,
      required: true,
    },

    withPromocode: {
      type: Boolean,
      required: false,
      default: false,
    },

    promocode: {
      type: String,
      required: false,
      default: '',
    },

    promocodeIsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    promocodeApplied: {
      type: Boolean,
      required: false,
      default: false,
    },

    discount: {
      type: Number,
      required: true,
    },

    email: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    visibleDiscountPrice() {
      return (this.discount !== 0) && this.chosenTab !== 'CashlessPaymentForm';
    },
  },
};
</script>

<style scoped src="./css/OrderDescription.css"></style>
