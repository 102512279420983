<template>
  <div
    v-if="getLoading"
    class="preloader_wrapper"
  >
    <SpinnerPreloader />
  </div>

  <div
    v-else
    data-nc-mode="0"
  >
    <div class="month_selector_buttons">
      <span
        class="select_all_month_button"
        @click="selectAllMonths"
      >
        Выбрать все месяцы
      </span>
      <span
        class="deselect_all_month_button background-color-grey"
        title="Очистить все"
        @click="deselectAllMonths"
      >
        Сброс
      </span>
    </div>

    <ul class="registration_month_list">
      <li
        v-for="(month, index) in limitedMonthSourceData"
        :key="index"
        class="month"
      >
        <div
          v-if="separatorNeeded(month.month_numb, index)"
          class="one_year_separator"
        >
          <hr> {{ month.year }} год
        </div>
        <RegistrationMonth
          :selected="month.selected"
          :month-name="month.month_name"
          :month="month.month_numb"
          :year="month.year"
          :total-with-phones="month.count_with_phones"
          :total-with-emails="month.count_with_emails"
          :start="month.start"
          :end="month.end"
          :month-genitive="month.month_genitive"
          :hot-mode="index < 3"
          :hot-mode-big="index === 0"
          :hot-mode-medium="index === 1"
          :hot-mode-small="index === 2"
          :index="index"
          :show-days-selector="showDaysSelector(index)"
          :open-day-selector="month.openDaySelector"
          :days="month.days"
          @toggle="toggleSelectedMonth(index)"
          @toggleSelector="toggleDaySelector(index)"
          @toggleDay="toggleDay"
          @toggleWholeMonth="toggleWholeMonth(index)"
        />
      </li>
    </ul>

    <span
      v-if="!showAllMonths"
      class="show_all_months"
      @click="toggleShowAllMonths"
    >
      Показать все месяцы
    </span>
    <span
      v-else
      class="egrul_link"
    >
      Выбрать любой другой месяц и год регистрации
      <br>
      можно в разделе
      <a
        href="/okved#filter_reg_date"
        target="_blank"
      >"База ЕГРЮЛ"
      </a>
    </span>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import RegistrationMonth from './components/RegistrationMonth/RegistrationMonth.vue';
import SpinnerPreloader from '../../common_components/SpinnerPreloader/SpinnerPreloader.vue';

export default {
  name: 'MonthSelector',
  components: { SpinnerPreloader, RegistrationMonth },

  computed: {
    ...mapGetters('monthSelector', [
      'getLoading',
      'showAllMonths',
      'monthSourceData',
      'limitedMonthSourceData',
      'getMonthDataForRequest',
      'selectedMonthsCount',
      'monthDescriptionIfOnlyOneMonthSelected',
      'isSelectorOpen',
    ]),
  },

  watch: {
    monthSourceData: {
      handler() {
        if (this.isSelectorOpen) {
          document.addEventListener('click', this.outsideEventListener);
        } else {
          document.removeEventListener('click', this.outsideEventListener);
        }
      },

      deep: true,
    },
  },

  async created() {
    try {
      await this.getInitialData();
    } catch (error) {
      console.error(error);
      window.echo(
        `Произошла ошибка при получении месяцев регистрации. <br>
              Пожалуйста, обновите страницу`,
        60000,
        'error',
      );
    }
  },

  methods: {
    ...mapActions('monthSelector', [
      'getInitialData',
    ]),

    ...mapMutations('monthSelector', [
      'toggleShowAllMonths',
      'toggleSelectedMonth',
      'toggleDaySelector',
      'toggleDay',
      'toggleWholeMonth',
      'selectAllMonths',
      'deselectAllMonths',
      'setShowDaysData',
      'closeSelector',
    ]),

    showDaysSelector(monthIndex) {
      return (monthIndex < 2);
    },

    separatorNeeded(monthIndex, index) {
      return (monthIndex === '12' && index !== 0);
    },

    outsideEventListener(event) {
      if (event.target.className.includes('day_selector_description')
          || event.target.className.includes('day_selector_item')) {
        return;
      }
      this.closeSelector();
    },
  },
};
</script>

<style scoped src="../../assets/css/color.css" />

<style scoped>
.preloader_wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
.month_selector_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.select_all_month_button, .deselect_all_month_button {
  display: inline-block;
  padding: 7px 10px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  text-align: center;
}
.select_all_month_button:hover, .deselect_all_month_button:hover {
  box-shadow: 0 0 2px #333;
}

.select_all_month_button {
  background: linear-gradient(#fe8502, #ff3d06);
}

.deselect_all_month_button {
  margin-left: 10px;
}

.one_year_separator hr {
  width: 80%;
  display: inline-block;
  vertical-align: middle;
  border-top: 1px dashed black;
  color: #fff;
  background-color: #fff;
}

.month {
  margin-bottom: 40px;
}
.month:last-child {
  margin-bottom: 0;
}

.show_all_months, .egrul_link {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px dashed;
  margin-top: 30px;
  margin-left: 70px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}
.show_all_months:hover {
  border-bottom: 1px dashed transparent;
}
.show_all_months:after {
  content: '';
  position: absolute;
  right: 0;
  transform: translateX(calc(100% + 7px));
  display: inline-block;
  width: 21px;
  height: 21px;
  background: url('/dist/css/images/arrow_for_index.png') center center no-repeat;
  background-size: contain;
}

.egrul_link {
  border-bottom: none;
  font-weight: normal;
  line-height: 1.5;
  cursor: default;
}
</style>
