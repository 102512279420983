const METHOD = '/api/component/cashless_payment_form/';

export default {
    getUserEmail() {
        const requestUrl = `${METHOD}user_email`;
        const requestOptions = {
            method: 'GET',
        };

        return fetch(requestUrl, requestOptions).then((response) => {
            if (response.ok) {
                return response.text();
            }
            return {};
        });
    },
    getUserCompanyDataByInn(inn) {
        const requestUrl = `${METHOD}company_data_by_inn/${inn}`;
        const requestOptions = {
            method: 'GET',
        };

        return fetch(requestUrl, requestOptions).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return {};
        });
    },
    getUserCompanyDataByEmail(email) {
        const requestUrl = `${METHOD}company_data_by_email/${email}`;
        const requestOptions = {
            method: 'GET',
        };

        return fetch(requestUrl, requestOptions).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return {};
        });
    },
};
