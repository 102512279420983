<template>
  <form class="payment-form_email">
    <div class="explanation">
      <div class="payment-form_email_header">
        Введите электронный адрес, на который хотите получать {{ periodAdjective }} данные о новых
        компаниях. Следуйте инструкции по оплате, и после этого ваша подписка будет активирована.
      </div>
      <div class="no-payment-form_email_header">
        Вы будете получать еженедельные данные о новых компаниях на указанный ящик.
      </div>
    </div>
    <div class="form-payment_action_pointer">
      <img
        class="form-payment_action_pointer__img"
        src="../../../assets/arrow.png"
        alt="form-payment_action_pointer__img"
      >
    </div>
    <table class="table_form">
      <tbody>
        <tr>
          <td class="first_td ftd_email">
            Ваш E-mail
          </td>
          <td
            class="td_input_email"
            :class="isValid ? 'td_input_email_correct' : 'td_input_email_incorrect'"
          >
            <input
              v-model="email"
              type="text"
              name="email"
              class="email_input"
              :class="isValid ?
                'form-payment_input_email_correct' : 'form-payment_input_email_incorrect'"
              placeholder="Введите эл. почту"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </form>
</template>

<script>
export default {
  name: 'EmailForm',
  props: {
    modelValue: {
      type: String,
      default: '',
      required: true,
    },

    isValid: {
      type: Boolean,
      default: false,
    },

    period: {
      type: String,
      default: 'week',
    },
  },

  emits: ['update:modelValue'],

  computed: {
    email: {
      get() {
        return this.modelValue;
      },

      set(value) {
        this.$emit('update:modelValue', value);
      },
    },

    periodAdjective() {
      switch (this.period) {
        case 'week': return 'еженедельные';
        case 'month': return 'ежемесячные';
      }
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped>
  .table_form {
    margin-left: 30px;
    border-collapse: separate;
    border-spacing: 0;
  }

  .first_td {
    vertical-align: baseline;
    padding: 3px;
    text-align: right;
    min-width: 130px;
  }

  .td_input_email {
    padding: 15px 60px 5px 3px;
    vertical-align: baseline;
  }

  .email_input {
    padding: 4px;
    width: 170px;
    background: none;
    margin-top: -10px;
    color: #999;
    outline: none
  }

  .td_input_email_correct {
    background: url('../../../assets/done.png') no-repeat 200px 10px;
  }

  .td_input_email_incorrect {
    line-height: 1;
    color: black;
    font-family: PTSansExportBase, sans-serif;
    border-collapse: separate;
    border-spacing: 0;
    margin: 0;
    border: 0;
    outline: 0;
    font-style: inherit;
    font-size: 100%;
    vertical-align: baseline;
    text-align: left;
    font-weight: normal;
    padding: 15px 60px 5px 3px;
    background: url('../../../assets/fail.png') no-repeat 200px 10px;
  }

  .form-payment_input_email_correct {
    border: 1px solid #B0D638;
    box-shadow: #B0D638 0 0 4px;
    padding: 4px;
    width: 170px;
    margin-top: -10px;
    color: #999;
  }

  .form-payment_input_email_incorrect {
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #D21C1C;
    box-shadow: #D21C1C 0 0 4px;
    padding: 4px;
    width: 170px;
    background: none;
    margin: -10px 0 0;
    color: #999;
  }

  .payment-form_email {
    text-align: center;
  }

  .payment-form_email_header {
    text-align: center;
    vertical-align: baseline;
  }

  .no-payment-form_email_header {
    display: none;
  }

  .form-payment_action_pointer {
    text-align: center;
  }

  .form-payment_action_pointer__img {
    position: relative;
    top: 5px;
    left: -5px;
  }
</style>
