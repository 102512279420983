const actions = {
    toggleLegalForm({ commit, getters, dispatch }, typeOfLegalForm) {
        commit('toggleLegalForm', typeOfLegalForm);
        commit('summary/setLegalFormDisabled', getters['summary/setLegalFormDisabled']);
        dispatch('calculateConfiguration');
    },

    toggleAffiliateSumIncome({ commit, dispatch }) {
        commit('toggleAffiliateSumIncome');
        dispatch('calculateConfiguration');
    },

    toggleAffiliateMaxIncome({ commit, dispatch }) {
        commit('toggleAffiliateMaxIncome');
        dispatch('calculateConfiguration');
    },

    togglePhoneStore({ state, commit, dispatch }, isPartiallySelectedChange = false) {
        commit('togglePhoneStore', isPartiallySelectedChange);
        commit('summary/setPhoneSelected', state.phoneFilter.selected);
        dispatch('calculateConfiguration');
    },

    toggleAddressStore({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleAddressStore', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },

    toggleEmailStore({ state, commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleEmailStore', isPartiallySelectedChange);
        commit('summary/setEmailSelected', state.emailFilter.selected);
        dispatch('calculateConfiguration');
    },

    toggleDecisionMakerStore({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleDecisionMakerStore', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },

    toggleOgrnStore({ commit, dispatch }, isPartiallySelectedChange = false) {
        commit('toggleOgrnStore', isPartiallySelectedChange);
        dispatch('calculateConfiguration');
    },

    calculateConfiguration({ getters, dispatch }) {
        dispatch('summary/setSelectedConfiguratorFields');

        if (!getters['summary/isSubscriptionMode']) {
            window.MNewCompaniesConfiguration.calculateConfiguration();
        } else {
            dispatch('summary/setApproximateCompCountPerSubscrPeriod');
        }
    },
};

export default actions;
