import { createApp } from 'vue';
import initSentry from '@/vue_sentry';
import store from '../store/store';
import NewCompanyConfigurator from './NewCompanyConfigurator';

const el = document.getElementById('vue_new_company_configurator');
if (el) {
    const app = createApp(NewCompanyConfigurator);
    initSentry(app);
    app.use(store);
    window.vue_new_company_configurator = app.mount('#vue_new_company_configurator');
}
