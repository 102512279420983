export default {
    sanitizeAffiliateIncome(val) {
        val = val.replace(/[^0-9.]/g, '');
        val = parseInt(val);
        if (isNaN(val)) {
            val = 0;
        }
        return val;
    },
};
