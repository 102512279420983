import api from './../../api/index';

export default {
    namespaced: true,

    state: {
        email: '',
        orderId: '',
        clientId: 0,
        clientBalance: 0,
        customerIsAuthorized: false,
        typePayment: '',
        oldBeznalData: {},
        visiblePayPopup: false,
        userDiscount: 0,
        initialSection: '',
        demoCreationIsRunning: false,
        demoCreatedSuccessfully: false,
        initialTab: '',
        showOrderPreloader: false,
    },
    getters: {
        getUrlForBill(state) {
            let url = window.location.origin + '/api/bill?email_for_beznal=' + state.oldBeznalData.email_for_beznal +
                '&umb_schet=' + state.oldBeznalData.umb_schet +
                '&count_for_beznal=' + state.oldBeznalData.count_for_beznal +
                '&cost_for_beznal=' + state.oldBeznalData.cost_for_beznal +
                '&inn=' + state.oldBeznalData.inn +
                '&nazv=' + state.oldBeznalData.nazv;

            if (state.oldBeznalData.urAdr.length !== 0) {
                url += '&urAdr=' + state.oldBeznalData.urAdr +
                    '&director=' + state.oldBeznalData.director;
            }
            return url;
        },

        getFilters(state, getters, rootState, rootGetters) {
            return {
                basic: rootGetters['getSelectedBasicFilters'],
                month: rootGetters['monthSelector/getMonthDataForRequest'],
                msp: [],
                phone: rootGetters['getPhoneSelected'] ? rootGetters['getPhone'] : [],
                income_sum: rootGetters['affiliateIncomeSumForCalculationRequest'],
                income_max: rootGetters['affiliateIncomeMaxForCalculationRequest'],
                legal_form: rootGetters['legalForm'],
                optional_filters: rootGetters['getOptionalsFilter'],
            };
        },

        getDataForDemoFile(state, getters, rootState, rootGetters) {
            return {
                ajax: true,
                cities: rootGetters['tree/selectedGeoUnits'],
                filters: getters.getFilters,
                okveds: rootGetters['okvedTree/getSelectedOkveds'],
                period: rootGetters['summary/chosenSubscriptionPeriod'],
                count: rootGetters['summary/totalCount'],
                email: '',
                format: '',
            };
        },
        getDataToCreateOrder(state, getters, rootState, rootGetters) {
            return {
                type: 'main',
                configuration: {
                    ajax: true,
                    cities: rootGetters['tree/selectedGeoUnits'],
                    filters: getters.getFilters,
                    okveds: rootGetters['okvedTree/getSelectedOkveds'],
                    period: rootGetters['summary/chosenSubscriptionPeriod'],
                    count: rootGetters['summary/totalCount'],
                },
                user: state.typePayment === '10' ? getters.getUserDataForUsualOrder : getters.getUserDataForBeznal,
            };
        },

        getUserDataForUsualOrder(state) {
            return {
                email: state.email,
                type: state.typePayment,
            };
        },

        getUserDataForBeznal(state) {
            return {
                email: state.oldBeznalData.email_for_beznal,
                type: state.typePayment,
                beznal: {
                    ...state.oldBeznalData,
                    bik: '',
                    schet: '',
                },
            }
        },
    },
    mutations: {
        setOrderId(state, value) {
            state.orderId = value;
        },
        setClientId(state, value) {
            state.clientId = value;
        },
        setEmail(state, value) {
            state.email = value;
        },
        setAuthStatus(state, value) {
            state.customerIsAuthorized = value;
        },
        setTypePayment(state, value) {
            state.typePayment = value;
        },
        setOldBeznalData(state, value) {
            state.oldBeznalData = value;
        },
        setUserBalance(state, balance) {
            state.clientBalance = balance;
        },
        setVisiblePayPopup(state, value) {
            state.visiblePayPopup = value;
        },
        setUserDiscount(state, value) {
            state.userDiscount = value;
        },
        setOptionOldBeznalData(state, {option, value}) {
            state.oldBeznalData[option] = value;
        },
        setInitialSection(state, value) {
            state.initialSection = value;
        },
        setDemoCreationIsRunning(state, value) {
            state.demoCreationIsRunning = value;
        },
        setDemoCreatedSuccessfully(state, value) {
            state.demoCreatedSuccessfully = value;
        },
        setInitialTab(state, value) {
            state.initialTab = value;
        },
        setShowOrderPreloader(state, value) {
            state.showOrderPreloader = value;
        },
    },
    actions: {
        async getAuthStatus({commit}) {
            const auth = await api.checkAuth();
            commit("setAuthStatus", Boolean(auth));
        },
        async getUserData({commit}) {
            const userData = await api.getUserData();
            commit('setEmail', userData.email);
            commit('setUserBalance', userData.balance);
            commit('setTypePayment', '10');
        },

        async getUserDiscount({commit}, email) {
            const discount = await api.getUserDiscount(email);
            commit('setUserDiscount', discount.disc);
        },

        getDemoFileHash({}, dataForDemo) {
            return api.getDemoFileHash(dataForDemo)
                .then(res => res.json());
        },

        createOrder({}, dataForOrder) {
            return api.createOrder(dataForOrder)
                .then(res => res.json());
        },
    },
}