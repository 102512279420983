<template>
  <div>
    <RadioInput
      title="<b>Отправить<br>закрывающие<br>документы</b>"
      name="documentManagement"
      :items="typesOfDocumentManagement"
      :chosen-item-value="documentManagement"
      :html-title="true"
      :mode="['tick']"
      @choose="choose"
    />
  </div>
</template>

<script>
import RadioInput from '../Form/RadioInput.vue';

export default {
  name: 'DocumentManagement',
  components: { RadioInput },
  props: {
    documentManagement: String,
  },

  emits: ['setDocumentManagement'],

  data() {
    return {
      typesOfDocumentManagement: [
        { value: 'electronic', title: 'ЭДО (оператор "Тензор")' },
        { value: 'post', title: 'Почтой (заказное письмо)' },
      ],
    };
  },

  methods: {
    isChecked(value) {
      return this.documentManagement === value;
    },

    choose(e) {
      this.$emit('setDocumentManagement', e.target.id);
    },
  },
};
</script>

<style scoped>
</style>
