<template>
  <div class="eb-block-like-cart">
    <div class="subscriptionFormTitle">
      Параметры подписки:
    </div>

    <div class="subscriptionFormItem">
      <div class="subscriptionFormItemTitle">
        <span>Начало подписки</span>
      </div>
      <div>
        <input
          type="date"
          class="newCompaniesSubscriptionDateInput"
          :min="subscriptionConfigData.min_date"
          :value="subscriptionStartDate"
          @change="setStartDate($event.target.value)"
        >
      </div>
    </div>

    <div class="subscriptionFormItem">
      <div class="subscriptionFormItemTitle">
        <span>Данные будут поступать</span>
      </div>
      <div>
        <div @click.stop="periodSelectorChange">
          <ButtonSelector
            :content="selectedPeriodDescription"
            width="145px"
            class="selectorPeriod"
          />
        </div>
        <div
          class="selectPeriod"
          :class="[{selectPeriodOpened: isSubscriptionPeriodSelectorShow}]"
        >
          <span
            class="subscriptionPeriodItem"
            :class="[{selectedSubscriptionPeriodItem: subscriptionPeriod.week.chosen}]"
            @click="selectSubscriptionPeriod('week')"
          >еженедельно</span>
          <span
            class="subscriptionPeriodItem"
            :class="[{selectedSubscriptionPeriodItem: subscriptionPeriod.month.chosen}]"
            @click="selectSubscriptionPeriod('month')"
          >ежемесячно</span>
        </div>
      </div>
    </div>

    <div
      v-if="isBalanceUseAllowed"
      class="balanceUseBlock"
    >
      <OptionSlot
        :first-active="subscriptionConfigData.deposit_refill"
        :second-active="!subscriptionConfigData.deposit_refill"
        @selectFirst="setDepositRefill(true)"
        @selectSecond="setDepositRefill(false)"
      >
        <template #first>
          <DepositRefillSum
            as-option
            :sum="subscriptionConfigData.deposit_add_sum"
            :active="subscriptionConfigData.deposit_refill"
            :enough-for-count="enoughForCountWithRefill"
            @change="setDepositSum"
          />
        </template>
        <template #second>
          <UseBalanceOption :active="!subscriptionConfigData.deposit_refill" />
        </template>
      </OptionSlot>

      <Balance
        use-balance-case
        :enough-for-count="enoughForCountOnlyBalance"
        :active="!subscriptionConfigData.deposit_refill"
        :balance="subscriptionConfigData.balance_for_showing"
        :is-low="isBalanceLow"
      />
    </div>
    <div v-else>
      <DepositRefillSum
        :sum="subscriptionConfigData.deposit_add_sum"
        :active="subscriptionConfigData.deposit_refill"
        :enough-for-count="enoughForCountWithRefill"
        @change="setDepositSum"
      />
      <Balance
        v-if="subscriptionConfigData.is_authorized"
        :balance="subscriptionConfigData.balance_for_showing"
        :is-low="isBalanceLow"
      />
    </div>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import ButtonSelector from 'button-selector';
import OptionSlot from '../../../../common_components/OptionSlot/OptionSlot.vue';
import DepositRefillSum from './components/DepositRefillSum/DepositRefillSum.vue';
import UseBalanceOption from './components/UseBalanceOption/UseBalanceOption.vue';
import Balance from './components/Balance/Balance.vue';

export default {
  name: 'Subscription',
  components: {
    ButtonSelector,
    OptionSlot,
    DepositRefillSum,
    UseBalanceOption,
    Balance,
  },

  data() {
    return {
      isSubscriptionPeriodSelectorShow: false,
    };
  },

  computed: {
    ...mapState('summary', [
      'subscriptionPeriod',
      'subscriptionConfigData',
      'subscriptionStartDate',
    ]),

    ...mapGetters('summary', [
      'chosenSubscriptionPeriod',
      'enoughForCountWithRefill',
      'enoughForCountOnlyBalance',
    ]),

    selectedPeriodDescription() {
      switch (this.chosenSubscriptionPeriod) {
        case 'week':
          return 'еженедельно';
        case 'month':
          return 'ежемесячно';
      }
    },

    isBalanceLow() {
      return (this.subscriptionConfigData.balance_for_calculation < 50);
    },

    isBalanceUseAllowed() {
      return (
        this.subscriptionConfigData.is_authorized
        && this.subscriptionConfigData.balance_for_calculation > 50
      );
    },
  },

  methods: {
    periodSelectorChange() {
      this.isSubscriptionPeriodSelectorShow = !this.isSubscriptionPeriodSelectorShow;
    },

    ...mapMutations('summary', [
      'setDepositRefill',
    ]),

    ...mapActions('summary', [
      'selectSubscriptionPeriod',
      'setDepositSum',
      'setStartDate',
    ]),
  },
};
</script>

<style scoped src="./Subscription.css"></style>

<style scoped src="../../NewCompanyConfigurator.css"></style>
