<template>
  <div class="field">
    <div class="filter__item_switch">
      <div class="filter__switch-row">
        <ToggleSwitch
          :id="filter.name"
          :title="filter.title"
          :chosen="filter.selected"
          :disabled="filter.disabled"
          :mode="['exportbase']"
          @set-chosen-item="toggleFilter"
        />
      </div>
    </div>

    <div
      class="filter__item_checkbox"
      :class="checkboxClass"
    >
      <CheckboxFillMode
        :filter="filter"
        @changeFilter="changeFilter"
      />
    </div>
  </div>
</template>

<script>
import ToggleSwitch from 'toggle-switch';
import CheckboxFillMode from '../CheckboxFillMode/CheckboxFillMode.vue';

export default {
  name: 'Field',
  components: {
    ToggleSwitch,
    CheckboxFillMode,
  },

  props: {
    filter: Object,
    hideCheckbox: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['changeFilter', 'toggleFilter'],

  computed: {
    checkboxClass() {
      return {
        filter__item_checkbox_hidden: this.hideCheckbox,
      };
    },
  },

  methods: {
    toggleFilter() {
      this.$emit('toggleFilter', this.filter.name);
    },

    changeFilter(filterName) {
      this.$emit('changeFilter', filterName);
    },
  },
};
</script>

<style scoped>
.field {
  display: flex;
}

.field :deep(.checkbox-button_disabled) {
  opacity: 0;
}

.filter__item_switch {
  display: block;
  width: 208px;
}

.filter__switch-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter__item_checkbox {
  display: block;
  text-align: left;
  width: 200px;
  margin-left: 7px;
}

.filter__item_checkbox_hidden {
  display: none;
}
</style>
