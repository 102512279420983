<template>
  <div class="pay-system_selection_wrapper">
    <div class="pay-system_choice">
      <div class="pay-system-type__header">
        Оплата с карты
      </div>
      <div class="pay-system-type_wrapper">
        <div
            class="pay-system-type_item_wrapper sbp"
            :class="isActive('sbp')"
            data-pay-system="yandex_kassa"
            @click="selectPaymentType('sbp')"
        >
          <div class="pay-system-type_item_content">
            СБП
          </div>
        </div>

        <div
          class="pay-system-type_item_wrapper visa_mastercard"
          :class="isActive('bank_card')"
          data-pay-system="yandex_kassa"
          @click="selectPaymentType('bank_card')"
        >
          <div class="pay-system-type_item_content">
            Банковские карты
          </div>
        </div>

        <div
          class="pay-system-type_item_wrapper sberpay"
          :class="isActive('sberbank')"
          data-pay-system="yandex_kassa"
          @click="selectPaymentType('sberbank')"
        >
          <div class="pay-system-type_item_content">
            SberPay
          </div>
        </div>
      </div>

      <div class="pay-system-type__header">
        Электронные деньги
      </div>
      <div class="pay-system-type_wrapper electronic_money">
        <div
          class="pay-system-type_item_wrapper  yandex_dengi"
          :class="isActive('yoo_money')"
          data-pay-system="yandex_kassa"
          @click="selectPaymentType('yoo_money')"
        >
          <div class="pay-system-type_item_content">
            ЮMoney
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaySystems',
  props: {
    paymentType: String,
  },

  data() {
    return {
      payTypeActive: false,
    };
  },

  computed: {
    isSafari() {
      return navigator.userAgent.indexOf('Version/') !== -1 && navigator.userAgent.indexOf('Safari/') !== -1;
    },
  },

  methods: {
    selectPaymentType(type) {
      this.$emit('selectPaymentType', type);
    },

    isActive(type) {
      return type === this.paymentType ? 'payTypeActive' : '';
    },
  },
};
</script>

<style scoped src="./PaySystems.css"></style>
