<template>
  <div class="row row_save">
    <p class="row__description row__description_save">
      Сохранить заказ:
    </p>
    <p
      class="row__link"
      :class="getClasses"
      @click="clickSave"
    >
      Сохранить заказ
    </p>
    <img
      alt="save"
      src="/dist/css/images/min_floppy.png"
      class="save-img"
    >
  </div>
</template>

<script>
export default {
  name: 'RowDemo',

  props: {
    visibleSaveSection: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    getClasses() {
      return {
        row__link_hide: this.visibleSaveSection,
      };
    },
  },

  methods: {
    clickSave() {
      this.$emit('choose-save-section');
    },
  },
};
</script>

<style scoped src="./css/Row.css"></style>
