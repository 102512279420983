<template>
  <div class="row-format">
    <p class="row-format__description">
      Формат:
    </p>
    <p class="row-format__value">
      Excel (xlsx), Для CRM (csv)
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowFormat',
}
</script>

<style>
.row-format {
  font-weight: bold;
  display: grid;
  grid-template-columns: 130px 1fr;
  place-items: center start;
}

.row-format__value {
  font-weight: normal;
}
</style>
