<template>
  <div class="payment-systems">
    <p class="payment-systems__description">
      После оплаты База доступна для скачивания в
      <a
        target="_blank"
        href="/auth#auth"
        class="payment-link"
      >Личном кабинете</a>.
      <br>
      На указанный E-mail также отправим ссылку для скачивания.
    </p>
    <img
      class="arrow_down"
      src="../../../assets/images/arrow_down.png"
      width="18"
      height="20"
      alt="arrow"
    >
    <label
      class="payment-systems__email"
      :class="classesForEmail"
    >
      Ваш E-mail
      <input
        :value="email"
        :disabled="customerIsAuthorized"
        name="email"
        type="text"
        class="payment-systems__input"
        placeholder="Введите эл. почту"
        @input="inputEmail($event.target.value)"
        @blur="checkEmail"
      >
      <label
        v-if="customerIsAuthorized"
        class="disabled_email_input"
        @click="handleDisabledEmail"
      />
    </label>

    <div class="payment-checkbox">
      <input
        id="payment-agreement"
        v-model="agreement"
        type="checkbox"
        name="agreement"
        class="payment-agreement"
      >
      <label for="payment-agreement">
        Даю согласие на обработку моего e-mail в соответствии с
        <a
          class="payment-link"
          href="/privacy_policy"
          target="_blank"
        >
          политикой конфиденциальности ExportBase
        </a>
      </label>
    </div>

    <div
      v-if="showCreateOrderButton"
      class="payment-popup-button"
      @click="createOrder"
    >
      Оплатить
    </div>
    <div v-else>
      <PaySystems
        class="payment-type"
        :payment-type="paymentType"
        @selectPaymentType="selectPaymentType"
      />

      <div class="payment-wrapper">
        <div
          v-if="showOrderPreloaderProps || showPreloader"
          class="payment-popup-button"
        >
          <svg
            class="preloader__spinner"
            viewBox="0 0 50 50"
          >
            <circle
              class="preloader__path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            />
          </svg>
        </div>
        <a
          v-else
          class="payment-popup-button"
          target="_blank"
          :href="getLink"
          @click="showNotification"
        >
          Оплатить
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import PaySystems from '@/common_components/PayPopup/paySystems/PaySystems.vue';
import api from '@/common_components/PayPopup/api';
import YandexForm from '../../YandexForm/YandexForm.vue';

export default {
  name: 'PaymentSystems',
  components: {
    YandexForm,
    PaySystems,
  },

  props: {
    email: String,
    customerIsAuthorized: Boolean,
    orderId: String,
    paymentTarget: String,
    paymentType: {
      type: String,
      required: true,
    },

    cost: Number,
    showOrderPreloaderProps: Boolean,
    discount: {
      type: Number,
      required: true,
    },
  },

  emits: ['createOrder'],

  data() {
    return {
      emailWasChanged: true,
      agreement: true,
      classesForEmail: {
        valid: this.customerIsAuthorized,
      },

      paymentLink: 'https://yoomoney.ru/checkout/payments/v2/failure?orderId=undefined',
      showPreloader: false,
      timerId: 0,
    };
  },

  computed: {
    isValidEmail() {
      const regExpEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
      return regExpEmail.test(this.email);
    },

    isAbleToPay() {
      return this.isValidEmail;
    },

    showCreateOrderButton() {
      return this.customerIsAuthorized
        ? false
        : this.emailWasChanged
          ? true
          : !this.isAbleToPay;
    },

    getLink() {
      return (this.agreement && this.paymentLink);
    },
  },

  watch: {
    orderId(newValue) {
      this.createPaymentUrl(this.paymentType, newValue, this.paymentTarget);
    },

    discount() {
      this.createPaymentUrl(this.paymentType, this.orderId, this.paymentTarget);
    },
  },

  unmounted() {
    clearTimeout(this.timerId);
  },

  created() {
    if (this.customerIsAuthorized) {
      this.$emit('createOrder');

      // если orderId известен с момента создания сразу создается ссылка для оплаты (см. в ЛК)
      if (this.orderId.length !== 0) {
        this.createPaymentUrl(this.paymentType, this.orderId, this.paymentTarget);
      }
    }
  },

  methods: {
    checkEmail() {
      this.classesForEmail = {
        valid: this.isValidEmail,
        invalid: !this.isValidEmail && this.email.length > 0,
      };
    },

    async createPaymentUrl(payMethod, idOrder, target) {
      if (idOrder.length === 0) return;

      this.showPreloader = true;
      try {
        this.paymentLink = await api.createPaymentUrl(payMethod, idOrder, target);
      } catch (e) {
        echo(
          'Произошла ошибка! Просим вас связаться с тех. поддержкой по тел. 8 800 775-29-12',
          6000,
          'error',
        );
      }

      this.lateStartCreatePayment();

      this.showPreloader = false;
    },

    // Вызываем повторное создание платежа через 30 минут, для обновления ссылки.
    // Так как ссылка через 30 минут становится не рабочей
    lateStartCreatePayment() {
      const countDown = 30 * 60 * 1000;

      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        alert('Ссылка на оплату обновлена');
        this.createPaymentUrl(this.paymentType, this.orderId, this.paymentTarget);
      }, countDown);
    },

    inputEmail(email) {
      this.emailWasChanged = true;
      this.$emit('inputEmail', email.trim());
    },

    selectPaymentType(paymentType) {
      this.createPaymentUrl(paymentType, this.orderId, this.paymentTarget);
      this.$emit('selectPaymentType', paymentType);
    },

    createOrder() {
      if (!this.isValidEmail) {
        return setTimeout(() => window.echo(
          'Введите корректный эл. адрес',
          3000,
        ));
      }

      if (!this.agreement) {
        return setTimeout(() => window.echo(
          'Подтвердите согласие на обработку Email',
          3000,
        ));
      }

      if (this.showOrderPreloaderProps) return;

      window.MFuncs.yandexReachGoalWithTarget('pay');

      this.emailWasChanged = false;
      this.$emit('createOrder');
    },

    handleDisabledEmail() {
      setTimeout(() => window.echo(
        'Чтобы изменить эл. адрес, необходимо выйти из личного кабинета',
        5000,
      ));
    },

    showNotification() {
      window.MFuncs.goYandexKassaWithParams(this.orderId, this.cost);
      this.$emit('switchToPayment');
      return (!this.agreement && echo('Необходимо дать согласие на обработку вашего E-mail'));
    },
  },
};
</script>

<style scoped src="./PaymentSystems.css"></style>
