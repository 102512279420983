<template>
  <div class="m__okved_popup_payment subscription_popup">
    <img
      src="../assets/popup_close.png"
      class="popup_close"
      alt="Закрыть окно"
      @click="close"
    >
    <div class="popup_wrapper">
      <OrderDescription />

      <div class="confirm">
        <Payment v-if="subscriptionDepositRefill" />
        <SubscriptionFromBalance
          v-else
          :email="userEmail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import OrderDescription from './OrderDescription.vue';
import Payment from './Payment/Payment.vue';
import SubscriptionFromBalance from './SubscriptionFromBalance.vue';

export default {
  name: 'SubscriptionPopUp',
  components: {
    Payment,
    OrderDescription,
    SubscriptionFromBalance,
  },

  computed: {
    ...mapGetters('summary', [
      'subscriptionDepositRefill',
      'userEmail',
    ]),
  },

  methods: {
    ...mapMutations([
      'setVisiblePaymentPopup',
    ]),

    close() {
      this.setVisiblePaymentPopup(false);
    },
  },
};
</script>

<style scoped>
.subscription_popup {
  position: absolute;
  z-index: 400;
  top: 12vh;
  transform: translateX(-50%);
  line-height: 1;
  color: black;
  font-family: PTSansExportBase, sans-serif;
  vertical-align: baseline;
  background-color: #fff;
  border: 1px solid #333;
  box-shadow: 0 0 10px #333;
  border-radius: 20px;
}

@media (width <= 800px) {
  .subscription_popup {
    transform: scale(0.85) translateX(-44%);
  }
}

.popup_close {
  color: black;
  position: absolute;
  cursor: pointer;
  top: -16px;
  right: -16px;
  z-index: 1;
}

.popup_wrapper {
  position: relative;
  padding: 15px 22px 30px 15px ;
  font-size: 14px;
  background-color: #fff;
  border: 3px solid #FFD800;
  border-radius: 10px;
  display: flex;
}

.confirm {
  padding-left: 20px;
  margin-top: 10px;
  width: 535px;
}
</style>
