const getters = {
    affiliateSumIncome(state) {
        return state.affiliateSumIncome;
    },

    affiliateMaxIncome(state) {
        return state.affiliateMaxIncome;
    },

    expandAdditionalFilters(state) {
        return state.expandAdditionalFilters;
    },

    additionalFiltersChosen(state) {
        return (state.affiliateSumIncome.chosen || state.affiliateMaxIncome.chosen);
    },

    affiliateSumIncomeAndMaxIncomeDisabled(state) {
        return state.affiliateSumIncomeAndMaxIncomeDisabled;
    },

    entrepreneur(state) {
        return state.entrepreneur;
    },

    ooo(state) {
        return state.ooo;
    },

    other(state) {
        return state.other;
    },

    legalForm(state) {
        const types = [];

        if (state.entrepreneur.chosen) {
            types.push(state.entrepreneur.name);
        }

        if (state.ooo.chosen) {
            types.push(state.ooo.name);
        }

        if (state.other.chosen) {
            types.push(state.other.name);
        }

        state.legalForm.types = types;

        return state.legalForm.types;
    },

    legalFormDisabled(state) {
        return !state.entrepreneur.chosen && !state.ooo.chosen && !state.other.chosen;
    },

    /**
   * @param state
   * @returns {object} - диапазон выручки в тыс. руб.
   * используется для расчета по БД, где выручка в тыс. руб.
   */
    affiliateIncomeSumForCalculationRequest(state) {
        return {
            ...state.affiliateSumIncome,
            min: state.affiliateSumIncome.min * 1000,
            max: state.affiliateSumIncome.max * 1000,
        };
    },

    /**
   * @param state
   * @returns {object} - диапазон выручки в тыс. руб.
   * используется для расчета по БД, где выручка в тыс. руб.
   */
    affiliateIncomeMaxForCalculationRequest(state) {
        return {
            ...state.affiliateMaxIncome,
            min: state.affiliateMaxIncome.min * 1000,
            max: state.affiliateMaxIncome.max * 1000,
        };
    },

    getSelectedBasicFilters(state) {
        return state.selectedBasicFilters;
    },

    getPhoneSelected(state) {
        return state.phoneFilter.selected;
    },

    getAddressSelected(state) {
        return state.addressFilter.selected;
    },

    getEmailSelected(state) {
        return state.emailFilter.selected;
    },

    getDecisionMakerSelected(state) {
        return state.decisionMakerFilter.selected;
    },

    getOgrnSelected(state) {
        return state.ogrnSelectedFilter.selected;
    },

    getPhone(state) {
        return state.phone;
    },

    getSelectedLegalForms(state) {
        return state.legalForm;
    },

    getSelectedAdditionalFilters(state) {
        return state.additionalFilters;
    },

    getOptionalsFilter(state) {
        const optionalsFilter = [];

        if (state.phoneFilter.partiallySelected) {
            optionalsFilter.push('phone');
        }

        if (state.addressFilter.partiallySelected) {
            optionalsFilter.push('address');
        }

        if (state.emailFilter.partiallySelected) {
            optionalsFilter.push('email');
        }

        if (state.decisionMakerFilter.partiallySelected) {
            optionalsFilter.push('decision_maker');
        }

        if (state.ogrnSelectedFilter.partiallySelected) {
            optionalsFilter.push('ogrn');
        }

        return optionalsFilter;
    },

    getFilters(state) {
        return [
            state.nameFilter,
            state.phoneFilter,
            state.addressFilter,
            state.emailFilter,
            state.decisionMakerFilter,
            state.ogrnSelectedFilter,
        ];
    },
};

export default getters;
