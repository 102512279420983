<template>
  <div
    v-show="hasChildren"
    id="notificationBlock"
    ref="notificationBlock"
    class="notification"
    :class="[
      resultClass,
      {
        'highlight-notification': isHighlighted,
        'green-highlight-notification': isGreenHighlighted
      }
    ]"
    @click="notificationClicking"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: 'NotificationComponent',

  props: {
    flipperPosition: { // Возможные значения: flipper-left, flipper-right, without-flipper
      type: String,
      required: false,
      default: '', // По умолчанию треугольник смотрит вверх
    },

    displayMode: { // Возможные значения: overlay
      type: String, // По умолчанию уведомление встраивается в элемент
      required: false,
      default: '',
    },

    cursor: { // Возможные значения: pointer
      type: String, // По умолчанию обычный курсор
      required: false,
      default: '',
    },

    isHighlighted: {
      type: Boolean,
      required: false,
      default: false,
    },

    isGreenHighlighted: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['clicked'],

  data() {
    return {
      hasChildren: false,
    };
  },

  computed: {
    resultClass() {
      return `${this.flipperPosition} ${this.displayMode} ${this.cursor}`;
    },
  },

  mounted() {
    this.observeNotificationChildList();
  },

  methods: {
    notificationClicking() {
      this.$emit('clicked');
    },

    observeNotificationChildList() {
      const { notificationBlock } = this.$refs;
      if (notificationBlock) {
        // Начинаем отслеживать изменения в DOM
        const observer = new MutationObserver(() => {
          this.countChildren(notificationBlock);
        });

        // Настройка observer для отслеживания изменений в дочерних элементах
        const config = { childList: true };
        observer.observe(notificationBlock, config);

        // Вызовем countChildren сразу после монтирования
        this.countChildren(notificationBlock);
      } else {
        setTimeout(this.observeNotificationChildList, 1000);
      }
    },

    countChildren(notificationBlock) {
      this.hasChildren = !!notificationBlock.children.length;
    },
  },
};

</script>

<style scoped>
.notification {
  position: relative;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 4px;
  margin: 20px 0 10px;
  color: #353535;
  background: var(--notification-color);
  font-size: 13px;
  line-height: 1.2;
}

.notification.overlay {
  position: absolute;
}

.notification.pointer {
  cursor: pointer;
}

.notification::before {
  position: absolute;
  content: '';
  background: var(--notification-color);
  left: 15px; top: -11px;
  width: 20px; height: 20px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  transform: rotate(45deg); /* Поворачиваем треугольник вверх */
}

.notification.flipper-left,
.notification.flipper-right,
.notification.without-flipper {
  margin-top: 15px;
}

.notification.flipper-left::before {
  left: -9px; top: 12px;
  width: 15px; height: 15px;
  transform: rotate(-45deg); /* Поворачиваем треугольник влево */
}

.notification.flipper-right::before {
  left: auto;
  right: -11px; top: 12px;
  width: 20px; height: 20px;
  transform: rotate(135deg); /* Поворачиваем треугольник вправо */
}

.notification.without-flipper::before {
  content: none;
  display: none;
}

.highlight-notification {
  transition: all 0.4s ease 0s;
  border: 1px solid #FFA600;
}

.highlight-notification::before {
  border-top: 1px solid #FFA600;
  border-left: 1px solid #FFA600;
}

.green-highlight-notification {
  transition: all 0.4s ease 0s;
  border: 1px solid var(--color-green-prime);
}

.green-highlight-notification::before {
  border-top: 1px solid var(--color-green-prime);
  border-left: 1px solid var(--color-green-prime);
}

</style>
