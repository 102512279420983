<template>
  <div class="description-list">
    <RowFeature />
    <RowFields />
    <RowFormat />
    <RowRelevant />
  </div>
</template>

<script>
import RowFeature from '@/new_company/new_company_configurator/components/Description/components/RowFeature.vue';
import RowFields from '@/new_company/new_company_configurator/components/Description/components/RowFields.vue';
import RowFormat from '@/new_company/new_company_configurator/components/Description/components/RowFormat.vue';
import RowRelevant from '@/new_company/new_company_configurator/components/Description/components/RowRelevant.vue';

export default {
  name: 'Description',
  components: {
    RowRelevant,
    RowFormat,
    RowFields,
    RowFeature,
  },
};
</script>

<style scoped>
  .description-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
    color: black;
  }
</style>
