<template>
  <div class="pay-system_selection_wrapper">
    <div class="form_payment_action_pointer">
      <img
        class="form_payment_action_pointer__img"
        src="./arrow.png"
        alt=""
      >
    </div>

    <div class="pay-system_choice">
      <div class="pay-system-type__header">
        Оплата с карты
      </div>
      <div class="pay-system-type_wrapper">
        <div
          class="pay-system-type_item_wrapper sbp"
          :class="isActive('sbp')"
          data-pay-system="yandex_kassa"
          @click="selectPaymentType('sbp')"
        >
          <div class="pay-system-type_item_content">
            СБП
          </div>
        </div>
        <div
          class="pay-system-type_item_wrapper visa_mastercard"
          :class="isActive('bank_card')"
          data-pay-system="yandex_kassa"
          @click="selectPaymentType('bank_card')"
        >
          <div class="pay-system-type_item_content">
            Банковские карты
          </div>
        </div>
        <div
          class="pay-system-type_item_wrapper sberpay"
          :class="isActive('sberbank')"
          data-pay-system="yandex_kassa"
          @click="selectPaymentType('sberbank')"
        >
          <div class="pay-system-type_item_content">
            SberPay
          </div>
        </div>
      </div>

      <div class="pay-system-type__header">
        Электронные деньги
      </div>
      <div class="pay-system-type_wrapper electronic_money">
        <div
          class="pay-system-type_item_wrapper  yandex_dengi"
          :class="isActive('yoo_money')"
          data-pay-system="yandex_kassa"
          @click="selectPaymentType('yoo_money')"
        >
          <div class="pay-system-type_item_content">
            ЮMoney
          </div>
        </div>
      </div>
    </div>

    <div class="form_payment_action_pointer">
      <img
        class="form_payment_action_pointer__img"
        src="./arrow.png"
        alt=""
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaySystems',
  props: {
    paymentType: String,
  },

  emits: ['selectPaymentType'],

  data() {
    return {
      payTypeActive: false,
      otherTypes: false,
    };
  },

  computed: {
    isSafari() {
      return navigator.userAgent.indexOf('Version/') !== -1 && navigator.userAgent.indexOf('Safari/') !== -1;
    },
  },

  mounted() {
    this.$emit('selectPaymentType', 'sbp');
  },

  methods: {
    selectPaymentType(type) {
      this.$emit('selectPaymentType', type);
    },

    isActive(type) {
      return type === this.paymentType ? 'payTypeActive' : '';
    },
  },
};
</script>

<style scoped>

  /* todo: сделать адаптивность в способах оплаты. */

  .pay-system_choice {
    min-width: 460px;
    margin-top: 15px;
  }

  .pay-system-type__header {
    display: inline-block;
    margin-bottom: 10px;
  }

  .other_pay-system-type__header span {
    border-bottom: 1px dashed;
    cursor: pointer;
  }

  .pay-system-type_wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 20px;
    gap: 17px;
  }

  .pay-system-type_wrapper .visa_mastercard {
    line-height: 1;
    width: auto;
  }

  .electronic_money {
    display: flex;
    justify-content: left;
  }

  .pay-system-type_item_wrapper {
    width: auto;
    border-radius: 10px;
    border: 1px solid #DDD;
    font-weight: 700;
    cursor: pointer;
  }

  .pay-system-type_wrapper.electronic_money .pay-system-type_item_wrapper {
    width: 29%;
  }

  .pay-system-type_wrapper.other_pay-system-type .pay-system-type_item_wrapper {
    width: 29%;
  }

  .visa_mastercard .pay-system-type_item_content {
    padding-left: 48px;
    background-image: url("./yandex_kassa_visa_master_card.svg");
    background-size: 26px;
    background-position: 15px 10px;
  }

  .sbp .pay-system-type_item_content {
    padding-left: 48px;
    background-image: url("./sbp.png");
    background-size: 26px 26px;
  }

  .sberpay .pay-system-type_item_content {
    padding-left: 40px;
    background-image: url("./yandex_kassa_sberpay.png");
    background-size: 23px;
  }

  .yandex_kassa_webmoney .pay-system-type_item_content {
    background-image: url("./yandex_kassa_webmoney.png");
  }

  .qiwi .pay-system-type_item_content {
    background-image: url("./yandex_kassa_qiwi.png");
    background-size: 22px;
  }

  .yandex_dengi  .pay-system-type_item_content {
    background-image: url("./yandex_kassa_yandex_dengi.png");
    background-size: 22px;
  }

  .alfa_bank .pay-system-type_item_content {
    background-image: url("./yandex_kassa_alfa_bank.png");
    background-size: 18px 23px;
  }

  .pay-system-type_item_content {
    position: relative;
    background-repeat: no-repeat;
    background-position: 10px 6px;
    padding: 12px 10px 12px 40px;
  }

  .form_payment_action_pointer {
    display: flex;
  }

  .form_payment_action_pointer__img {
    position: relative;
    top: 5px;
    margin: 0 auto;
  }

  .payTypeActive {
    scale: 1.1;
    line-height: 1;
    vertical-align: baseline;
    border-radius: 10px;
    border: 2px solid var(--color-button-prime);
    cursor: default;
    font-weight: bold;
  }

</style>
