<template>
  <div class="free-section">
    <transition
      name="transition-obtaining"
      mode="out-in"
    >
      <div
        v-if="beforeFreeRequest"
        key="before"
        class="before-free-obtaining"
      >
        <p class="free-section__description">
          Укажите ваш электронный адрес и нажмите <b>"Скачать бесплатно"</b>,
          чтобы получить <b>бесплатную</b> выгрузку.
        </p>
        <form
          class="free-form"
          @submit.prevent="getFree"
        >
          <img
            class="free-form__arrow"
            src="/dist/css/images/arrow.png"
            alt="arrow"
          >
          <TextInput
            title="Ваш e-mail"
            placeholder=""
            :value="getEmail"
            :valid="isValidEmail"
            :need-validation="needValidationEmail"
            :disabled="customerIsAuthorized"
            @handle-input="setEmail"
            @click="clickEmail"
          />
          <label class="free-agreement">
            <input
              v-model="agree"
              type="checkbox"
              class="free-agreement__checkbox"
            >
            <span class="free-agreement__text">
              Даю согласие на обработку моего e-mail в соответствии с
              <a
                class="free-agreement__link"
                href="https://export-base.ru/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                политикой конфиденциальности ExportBase
              </a>
            </span>
          </label>
          <img
            class="free-form__arrow"
            src="/dist/css/images/arrow.png"
            alt="arrow"
          >
          <button
            class="free-download-button"
            type="submit"
          >
            <span>Скачать бесплатно</span>
          </button>
        </form>
      </div>

      <div
        v-else
        key="after"
        class="after-free-obtaining"
      >
        <span
          class="info"
          :class="{ warning: !freeFileDownloadedSuccessfully }"
        >{{ infoText }}</span>
        <button
          type="button"
          class="obtain-again"
          @click="handleBeforeFreeRequest"
        >
          Скачать ещё раз
        </button>
        <div class="feedback">
          <div class="text">
            <span class="fb-title">Ищете базы данных?</span>
            <span class="fb-help">
              Поможем в конфигурации базы, подберем настройки, дадим консультацию по покупке
            </span>
            <span class="fb-phone">
              Телефон: <a
                class="fb-phone-link"
                href="tel:+78007752912"
              >8 800 775-29-12</a>
            </span>
            <span class="fb-email">
              E-mail: <a
                class="fb-email-link"
                href="mailto:info@export-base.ru"
              >info@export-base.ru</a>
            </span>
          </div>
          <img
            src="../DemoSection/manager_Nikita.png"
            alt=""
            class="manager"
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TextInput from '@/common_components/PayPopup/cashlessForm/Form/TextInput.vue';

export default {
  name: 'FreeSection',
  components: {
    TextInput,
  },

  props: {
    freeFileDownloadedSuccessfully: {
      type: Boolean,
      required: true,
      default: false,
    },

    propEmail: {
      type: String,
      required: true,
    },

    customerIsAuthorized: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      beforeFreeRequest: true,
      email: '',
      needValidationEmail: false,
      agree: true,
    };
  },

  computed: {
    isValidEmail() {
      const regExpEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regExpEmail.test(this.getEmail);
    },

    getEmail() {
      return this.customerIsAuthorized ? this.propEmail : this.email;
    },

    infoText() {
      if (this.freeFileDownloadedSuccessfully) {
        return 'Бесплатная выгрузка скачивается на ваше устройство.';
      }
      return 'Выгрузка формируется. Пожалуйста, подождите...';
    },
  },

  methods: {
    setEmail(e) {
      this.needValidationEmail = true;
      this.email = e.target.value;
    },

    handleBeforeFreeRequest() {
      this.beforeFreeRequest = true;
    },

    async getFree() {
      if (!this.isValidEmail) {
        return echo('Введите корректный E-mail', 6000, 'warning');
      }
      if (!this.agree) {
        return echo('Подтвердите согласие с политикой конфиденциальности!');
      }

      this.$emit('download-free', {
        email: this.getEmail,
      });
      this.beforeFreeRequest = false;
    },

    clickEmail() {
      if (this.customerIsAuthorized) {
        echo('Чтобы изменить эл. адрес, необходимо выйти из личного кабинета', 6000, 'warning');
      }
    },
  },
};
</script>

<style scoped src="./FreeSection.css"></style>
