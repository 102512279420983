<template>
<div class="optionSlot">
  <span class="bridge"></span>

  <div
    class="option first"
    :class="{'active': firstActive}"
    @click="selectFirst()"
  >
    <span class="tick"></span>
    <slot name="first"></slot>
  </div>

  <div
    class="option"
    :class="{'active': secondActive}"
    @click="selectSecond()"
  >
    <span class="tick"></span>
    <slot name="second"></slot>
  </div>
</div>
</template>

<script>
export default {
  name: "RadioSlot",
  props: {
    firstActive: {
      type: Boolean,
      default: false,
    },
    secondActive: {
      type: Boolean,
      default: false,
    },
    ignoreClickOnActiveOption: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    selectFirst() {
      if (this.firstActive && this.ignoreClickOnActiveOption) {
        return;
      }
      this.$emit('selectFirst');
    },

    selectSecond() {
      if (this.secondActive && this.ignoreClickOnActiveOption) {
        return;
      }
      this.$emit('selectSecond');
    },
  },
}
</script>

<style scoped>
.optionSlot {
  position: relative;
}

.bridge {
  position: absolute;
  left: 7px;
  top: 12%;
  width: 2px;
  height: 80%;
  background: #b9b9b9;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.option.active {
  cursor: default;
}
.option.first {
  margin-bottom: 15px;
}

.tick {
  flex-shrink: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 16px;
  margin-right: 5px;
  background: #b9b9b9;
}
.active .tick {
  background: #8fa634;
}
</style>
