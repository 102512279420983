<template>
  <label
    class="cashless_payment_form__input_item__container"
    @click="$emit('click')"
  >
    <span
      v-if="htmlTitle"
      class="cashless_payment_form__input_item_label"
      v-html="title"
    />
    <span
      v-if="!htmlTitle"
      class="cashless_payment_form__input_item_label"
    >{{ title }}</span>
    <input
      type="text"
      :placeholder="placeholder"
      class="cashless_payment_form__input_item_input"
      name="email"
      :value="getValue"
      :required="required"
      :disabled="disabled"
      :class="[getValidationClass, getModeClasses]"
      @input="handleInput"
    >
  </label>
</template>

<script>
export default {
  name: 'TextInput',
  props: {
    title: String,
    value: [String, Number],
    placeholder: String,
    mode: {
      type: Array,
      default: () => [],
      required: false,
    },

    required: {
      type: Boolean,
      default: false,
      required: false,
    },

    valid: {
      type: Boolean,
      default: false,
      required: false,
    },

    needValidation: {
      type: Boolean,
      default: true,
      required: false,
    },

    noValuePresentation: {
      type: Boolean,
      default: false,
      required: false,
    },

    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },

    htmlTitle: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    getValue() {
      if (this.noValuePresentation) {
        return '';
      }

      return this.value;
    },

    getValidationClass() {
      return {
        valid: (this.needValidation && this.valid) || (this.valid && this.disabled),
        invalid: this.needValidation && !this.valid,
      };
    },

    getModeClasses() {
      return {
        short_text_input: this.mode.indexOf('short') !== -1,
      };
    },
  },

  methods: {
    handleInput(e) {
      this.$emit('handle-input', e);
    },
  },
};
</script>

<style scoped>
    .cashless_payment_form__input_item__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
    }

    .cashless_payment_form__input_item_label {
      text-align: end;
      text-align-last: end;
      width: 85px;
    }

    .cashless_payment_form__input_item_input {
        width: 202px;
        padding: 8px 6px;
        box-sizing: border-box;
        border: 1px solid #ccc;
    }

    .valid {
        border: 1px solid #B0D638 !important;
        box-shadow: #B0D638 0 0 4px !important;
    }

    .invalid {
        border: 1px solid #D21C1C !important;
        box-shadow: #D21C1C 0 0 4px !important;
    }

    .short_text_input {
        width: 75px !important;
        margin-right: 127px;
    }
</style>
