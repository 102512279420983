<template>
  <div class="payment-by-balance-block__container force_text_size_adjust">
    <span class="payment-by-balance-block__content_attention">
      Сейчас вы находитесь на сайте под аккаунтом <b>{{ clientEmail }}</b>, следуйте инструкциям ниже для оплаты
      вашей услуги.
    </span>

    <div class="payment-by-balance-block__statistics__container">
      <div class="payment-by-balance-block__content_row">
        <b class="payment-by-balance-block__title">Ваш баланс:</b>
        <span style="width: 15px; text-align: center;">
          <span style="display: block; margin-top: 10px;">
            _
          </span>
        </span>
        <span class="payment-by-balance-block__balance_value">
          {{ formattedBalance }} руб.
        </span>
      </div>
      <div class="payment-by-balance-block__content_row payment-by-balance-block__content_row__wrapper">
        <b class="payment-by-balance-block__title">Сумма списывания:</b>
        <span class="payment-by-balance-block__value_wrapper payment-by-balance-block__cost_value_wrapper">
          {{ formattedCost }} руб.
        </span>
      </div>
      <div style="display: flex; flex-direction: row; height: 14px">
        <div style="display: block; width: 50%" />
        <div style="display: block; margin-left: 15px; width: 50px; border-top: 1px solid #888" />
      </div>
      <div class="payment-by-balance-block__content_row">
        <b
          v-if="!balanceIsEnough"
          class="payment-by-balance-block__title"
        >Необходимо доплатить:</b>
        <b
          v-else
          class="payment-by-balance-block__title"
        >Ожидаемый остаток:</b>
        <b
          class="payment-by-balance-block__value_wrapper"
          :class="[{'payment-by-balance-block__negative_remainder_value': !balanceIsEnough}]"
        >
          {{ formattedPositiveRemainder }} руб.
        </b>
        <img
          v-if="balanceIsEnough"
          src="/dist/css/images/done.png"
          style="margin-left: 20px"
          height="20"
          alt="Ожидаемый остаток"
        >
      </div>
    </div>

    <span
      v-if="!balanceIsEnough"
      class="payment-by-balance-block__content_refill_remainder"
    >
      Для оплаты заказа необходимо пополнить баланс не менее, чем на {{ formattedPositiveRemainder }} руб.
    </span>
  </div>
</template>

<script>
import formatThousand from '../../../../helpers/formatThousand';

export default {
  name: 'PaymentByBalanceBlock',
  props: {
    orderCost: {
      type: Number,
      default: 0,
    },

    balance: {
      type: Number,
      default: 0,
    },

    clientEmail: {
      type: String,
      default: '',
    },

    agreeWithPrivacyPolicy: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    remainder() {
      return this.balance - this.orderCost;
    },

    positiveRemainder() {
      return Math.abs(this.remainder);
    },

    balanceIsEnough() {
      return this.remainder >= 0;
    },

    formattedCost() {
      return formatThousand(this.orderCost);
    },

    formattedBalance() {
      return formatThousand(this.balance);
    },

    formattedPositiveRemainder() {
      return formatThousand(this.positiveRemainder);
    },
  },

  methods: {
    handlePrivacyPolicy() {
      this.$emit('changeAgreeWithPrivacyPolicy', !this.agreeWithPrivacyPolicy);
    },
  },
};
</script>

<style scoped>
.payment-by-balance-block__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-by-balance-block__content_attention {
  margin-top: 20px;
  text-align: center;
  width: 450px;
}

.payment-by-balance-block__content_refill_remainder {
  text-align: center;
  font-style: italic;
  margin: 20px 0 10px 0;
  max-width: 500px;
}

.payment-by-balance-block__content_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
}

.payment-by-balance-block__content_row__wrapper {
  margin: 10px 0;
}

.payment-by-balance-block__value_wrapper {
  margin-left: 15px;
}

.payment-by-balance-block__cost_value_wrapper {
  font-weight: bold;
  background-color: #c6cc9c;
  padding: 4px 4px;
  border-radius: 5px;
}

.payment-by-balance-block__statistics__container {
  margin-top: 20px;
  width: 100%;
}

.payment-by-balance-block__title {
  text-align: right;
  width: 50%;
}

.payment-by-balance-block__negative_remainder_value {
  display: block;
  background: #f3bb1b;
  color: black;
  padding: 6px 5px;
  border-radius: 5px;
}
</style>
