<template>
  <div
    class="subscriptionFormItem"
    :class="{'depositRefillSumOption': asOption, 'active': active}"
  >
    <div class="subscriptionFormItemTitle">
      Сумма пополнения
    </div>

    <div class="inputWrapper">
      <input
        type="number"
        class="newCompaniesSubscriptionInput"
        min="500"
        max="10000"
        step="100"
        :value="sum"
        :disabled="!active"
        @change="change($event.target.value)"
      >
      <span>руб</span>
      <span class="newCompaniesSubscriptionInputSubText">хватит на {{ enoughForCount }} компаний</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DepositRefillSum',
  props: {
    asOption: {
      type: Boolean,
      default: false,
    },

    sum: {
      type: Number,
    },

    active: {
      type: Boolean,
    },

    enoughForCount: {
      type: Number,
    },
  },

  emits: ['change'],

  methods: {
    change(sum) {
      this.$emit('change', sum);
    },
  },
};
</script>

<style scoped src="../../Subscription.css"></style>

<style scoped>
.inputWrapper {
  flex-shrink: 0;
  display: flex;
  gap: 3px;
  align-items: center;
}
</style>
