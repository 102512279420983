<template>
  <label class="cashless_payment_form__radio_item">
    <span
      v-if="htmlTitle"
      class="cashless_payment_form__radio_item_label"
      v-html="title"
    />
    <span
      v-if="!htmlTitle"
      class="cashless_payment_form__radio_item_label"
    >{{ title }}</span>
    <label class="cashless_payment_form__radio_column_container">
      <label
        v-for="(item, index) in items"
        :key="index + item.value"
        class="cashless_payment_form__radio_column_item"
      >
        <span class="cashless_payment_form__radio_item__container">
          <span class="radio-container">
            <input
              :id="item.value"
              type="radio"
              :name="name"
              :checked="isChecked(item.value)"
              @change="choose"
            >
            <label
              :for="item.value"
              class="cashless_payment_form__radio_item__title"
            >
              {{ item.title }}
            </label>
            <span
              v-if="hasTick && isChecked(item.value)"
              class="cashless_payment_form__radio_item__tick"
            />
          </span>
          <label
            v-if="hasSubTextInput(item.value) && isChecked(item.value)"
            class="cashless_payment_form__radio_sub_input_item"
          >
            <TextInput
              title="№"
              :value="subTextInputValue"
              placeholder="123456"
              :mode="['short']"
              :need-validation="false"
              @handle-input="setSubTextInputValue"
            />
          </label>
        </span>
      </label>
    </label>
  </label>
</template>

<script>
import TextInput from './TextInput.vue';

export default {
  name: 'RadioInput',
  components: { TextInput },
  props: {
    items: Array,
    title: String,
    name: {
      type: String,
      required: true,
    },

    chosenItemValue: String,
    htmlTitle: {
      type: Boolean,
      default: false,
      required: false,
    },

    subTextInputs: {
      type: Array,
      default: () => [],
      required: false,
    },

    subTextInputValue: {
      type: [String, Number],
      default: '',
      required: false,
    },

    mode: {
      type: Array,
      default: () => [],
      required: false,
    },
  },

  computed: {
    hasTick() {
      return this.mode.indexOf('tick') !== -1;
    },
  },

  methods: {
    choose(e) {
      this.$emit('choose', e);
    },

    setSubTextInputValue(e) {
      this.$emit('set-sub-text-input', e.target.value);
    },

    isChecked(value) {
      return this.chosenItemValue === value;
    },

    hasSubTextInput(value) {
      return this.subTextInputs.indexOf(value) !== -1;
    },
  },
};
</script>

<style scoped>
    .cashless_payment_form__radio_item__container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .radio-container {
      display: flex;
      gap: 5px;
    }

    .cashless_payment_form__radio_item {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .cashless_payment_form__radio_item_label {
        text-align: end;
        text-align-last: end;
        width: 85px;
    }

    .cashless_payment_form__radio_column_container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 13px;
        width: 202px;
    }

    .cashless_payment_form__radio_column_item {
        margin-bottom: 10px;
    }

    .cashless_payment_form__radio_sub_input_item {
        margin-top: 5px;
        position: relative;
        right: 68px;
    }

    .cashless_payment_form__radio_item__tick {
        display: block;
        background: url('/dist/css/images/tick_small.png') no-repeat;
        width: 13px;
        height: 13px;
        margin-left: 5px;
    }

    .cashless_payment_form__radio_item__title {
        margin-left: 3px;
    }
</style>
