<template>
  <teleport to="body">
    <div id="app">
      <div class="background" />
      <SubscriptionPopUp />
    </div>
  </teleport>
</template>

<script>
import SubscriptionPopUp from './components/SubscriptionPopUp.vue';

export default {
  name: 'PaymentPopup',
  components: {
    SubscriptionPopUp,
  },
};
</script>

<style>
  #app {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 400;
  }

  .background {
    position: fixed;
    z-index: 300;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background-color: black;
  }
</style>
