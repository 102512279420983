class ValidateValueHelper {
    static validateInn(value) {
        if (ValidateValueHelper.isNumeric(value)) {
            value = value.toString();
            return value.length === 10 || value.length === 12;
        }

        return false;
    }

    static validateOgrn(value) {
        if (ValidateValueHelper.isNumeric(value)) {
            value = value.toString();
            return value.length === 13 || value.length === 15;
        }

        return false;
    }

    static innRefersToIndividualEntrepreneur(value) {
        if (ValidateValueHelper.validateInn(value)) {
            value = value.toString();
            return value.length === 12;
        }

        return false;
    }

    static ogrnRefersToIndividualEntrepreneur(value) {
        if (ValidateValueHelper.validateOgrn(value)) {
            value = value.toString();
            return value.length === 15;
        }

        return false;
    }

    static validateKpp(value) {
        if (ValidateValueHelper.isNumeric(value)) {
            value = value.toString();
            return value.length === 9;
        }

        return false;
    }

    static validatePostIndex(value) {
        if (ValidateValueHelper.isNumeric(value)) {
            value = value.toString();
            return value.length === 6;
        }

        return false;
    }

    static validateEmail(value) {
        return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/.test(value);
    }

    static notEmpty(value) {
        value = value.toString();
        return value.length !== 0;
    }

    static isNumeric(value) {
        if (typeof value === 'number') {
            value = value.toString();
        }

        if (typeof value === 'string') {
            return /^\d+$/.test(value);
        }

        return false;
    }
}

export default ValidateValueHelper;
