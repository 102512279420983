<template>
  <div
    class="eb-spinner-preloader"
  >
    <img
      width="24"
      height="24"
      class="eb-spinner-preloader-spinner"
      src="/dist/css/images/preloaders/preloaderAstra.gif"
      alt=""
    >
    <span class="eb-spinner-preloader-text">
      Загружаем месяцы регистрации...
    </span>
  </div>
</template>

<script>
export default {
  name: 'SpinnerPreloader',
};
</script>
