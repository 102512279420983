<template>
  <div class="newCompaniesConfigurator">
    <div
      class="newCompanyConfiguratorBody"
    >
      <main class="mainConfigurator">
        <section class="regionAndOkvedAndLegalFormConfigurator">
          <div
            class="geoAndOkved"
            @click="showNotification = false"
          >
            <div
              class="regionConfigurator"
              @click="showTree"
            >
              <span><b>География выборки:</b></span>
              <ButtonSelector
                :content="getCityDescription"
                is-important-selector
                :class="{'selector-nothing-selected': cityIsEmpty & itIsTimeToShow}"
                width="200px"
                class="_nc_action cityTrees"
                font-size="17px"
              />
            </div>

            <GeoTree v-if="visibleTree" />

            <OkvedWidget
              :it-is-time-to-show="itIsTimeToShow"
            />

            <NotificationComponent
              v-if="showNotification && itIsTimeToShow"
              class="configurator-block-notification"
              display-mode="overlay"
            >
              <p>
                Сначала выберите нужные <b>города и коды ОКВЭД</b>,
                затем настройте другие фильтры справа
              </p>
            </NotificationComponent>
          </div>
          <div class="legalFormConfigurator">
            <p><b>Правовая форма:</b></p>
            <div @click.stop="selectorChange">
              <ButtonSelector
                id="buttonSelectorLegalFormConfigurator"
                :content="selectedLegalForm"
                is-important-selector
                :class="{'selector-nothing-selected': legalFormIsEmpty}"
                width="200px"
              />
            </div>
            <div
              v-show="isSelectorShow"
              class="select"
            >
              <span
                class="legalForm"
                :class="[{selectedLegalForm: entrepreneur.chosen}]"
                @click="toggleLegalForm('entrepreneur')"
              >ИП</span>
              <span
                class="legalForm"
                :class="[{selectedLegalForm: ooo.chosen}]"
                @click="toggleLegalForm('ooo')"
              >ООО</span>
              <span
                class="legalForm"
                :class="[{selectedLegalForm: other.chosen}]"
                @click="toggleLegalForm('other')"
              >АО, ПАО и другие</span>
            </div>
          </div>
        </section>

        <section class="basicFiltersConfigurator">
          <div
            class="disabled-filter"
            @click="toggleName"
          >
            <Field
              :filter="nameFilter"
            />
          </div>
          <FieldPhone
            :class="{selected: phoneFilter.selected}"
            :filter="phoneFilter"
            class="fieldPhone"
            @toggleFilter="togglePhoneStore()"
            @changeFilter="togglePhoneStore(true)"
          />

          <Field
            :filter="addressFilter"
            @toggleFilter="toggleAddressStore()"
            @changeFilter="toggleAddressStore(true)"
          />

          <Field
            :filter="emailFilter"
            @toggleFilter="toggleEmailStore()"
            @changeFilter="toggleEmailStore(true)"
          />

          <Field
            :filter="decisionMakerFilter"
            @toggleFilter="toggleDecisionMakerStore()"
            @changeFilter="toggleDecisionMakerStore(true)"
          />

          <Field
            :filter="ogrnSelectedFilter"
            hide-checkbox
            @toggleFilter="toggleOgrnStore()"
            @changeFilter="toggleOgrnStore(true)"
          />

          <NotificationComponent
            v-if="isVisible && !hideAdditionalNotification"
            class="notificationBalloon"
            display-mode="overlay"
            cursor="pointer"
            flipper-position="flipper-left"
            @clicked="onNotificationClick"
          >
            Настройте нужные поля, а затем <br>
            перейдите к выбору месяцев регистрации и корзине
          </NotificationComponent>
        </section>
      </main>

      <main class="additionalFilterConfigurator">
        <NewCompanyButtonFilters />
        <AffiliateSumIncomeAndMaxIncome v-if="expandAdditionalFilters" />
      </main>

      <p
        class="excelOrUpdatingHead"
        :class="[excelOrUpdatingClassObject]"
      >
        <span>Как вы хотите получать новые компании?</span>
      </p>

      <main class="excelOrUpdatingBody">
        <section class="excelOrUpdatingPicker">
          <div class="aditional_options_panel aditional_options_panel_nc-switch">
            <div
              class="option_with_balloon additional_option additional_option_inline nc_mode_switcher fileOrSubscribeSelector"
              :class="[{'_mod-active': !isSubscriptionMode}]"
              @click="toggleSubscriptionMode"
            >
              <input
                v-model="isSubscriptionMode"
                :value="false"
                class="radioButtonCustom eb-input-radio"
                type="radio"
                name="fileOrSubscribe"
              >
              <span class="inner_text">Скачать в виде Excel-файла</span>
              <div class="balloon_description_wrapper">
                <div class="balloon_description_arrow" />
                <div class="balloon_description">
                  <div class="text">
                    Вы получите новые компании, имеющиеся в нашей базе, по выбранным вами параметрам
                    в формате Excel
                  </div>
                </div>
              </div>
            </div>
            <div
              class="additional_option option_with_balloon option_with_balloon_inline nc_mode_switcher fileOrSubscribeSelector"
              :class="[{'_mod-active': isSubscriptionMode}]"
              @click="toggleSubscriptionMode"
            >
              <input
                v-model="isSubscriptionMode"
                :value="true"
                class="radioButtonCustom eb-input-radio"
                type="radio"
                name="fileOrSubscribe"
              >
              <span class="inner_text">Оформить подписку, получать обновления</span>
              <span class="inner_text_inline">{{ periodAdverb }}</span>
              <div class="balloon_description_wrapper">
                <div class="balloon_description_arrow" />
                <div class="balloon_description">
                  <div class="text">
                    Вы получите данные о компаниях первыми!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="excelOrUpdatingInfo">
          <img
            src="./../../assets/images/info-icon.svg"
            alt="Информация"
          >
          <div class="subscription_ads">
            Возможность дозвониться первыми. <br> Очень быстрое поступление <br> компаний.
          </div>
        </section>
      </main>
      <Banner />
    </div>
    <main
      id="result_for_balloon_NC_hint_scrolling"
      class="treeMonthAndSummary"
    >
      <section class="descriptionAndMonth">
        <OrderDescription />
        <MonthSelector v-show="!isSubscriptionMode" />
        <Subscription v-show="isSubscriptionMode" />
      </section>
      <section
        id="vue_new_companies_summary"
        class="summary"
      >
        <div class="load_process _load_process load_process_new_companies">
          <div class="for_preloader" />
          <div class="preloader">
            <div>
              Идет пересчет<br><img
                src="/preloader.gif"
                alt=""
              >
            </div>
          </div>
        </div>
        <NCSummary class="newCompanySummaryComponent" />
      </section>
    </main>
    <PaymentPopup v-if="visiblePaymentPopup" />
  </div>
</template>

<script>
import ButtonSelector from 'button-selector';
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import plural from 'plural-ru';
import Banner from '@/new_company/new_company_configurator/components/Banner/Banner.vue';
import OkvedWidget from '@/new_company/new_company_configurator/components/OkvedWidget/OkvedWidget.vue';
import OrderDescription from '@/new_company/new_company_configurator/components/OrderDescription/OrderDescription.vue';
import NotificationComponent from '@/common_components/NotificationComponent/NotificationComponent.vue';
import Field from './components/Field/Field.vue';
import FieldPhone from './components/FieldPhone/FieldPhone.vue';
import NewCompanyButtonFilters from './components/NewCompanyButtonFilters/NewCompanyButtonFilters.vue';
import AffiliateSumIncomeAndMaxIncome from './components/AffiliateSumIncomeAndMaxIncome/AffiliateSumIncomeAndMaxIncome.vue';
import MonthSelector from '../month_selector/MonthSelector.vue';
import NCSummary from '../summary/NCSummary.vue';
import Subscription from './components/Subscription/Subscription.vue';
import GeoTree from './components/GeoTree/GeoTree.vue';
import PaymentPopup from './components/PaymentPopup/PaymentPopup.vue';

export default {
  name: 'NewCompanyConfigurator',
  components: {
    NotificationComponent,
    OrderDescription,
    OkvedWidget,
    Banner,
    PaymentPopup,
    ButtonSelector,
    NewCompanyButtonFilters,
    Field,
    FieldPhone,
    AffiliateSumIncomeAndMaxIncome,
    MonthSelector,
    NCSummary,
    Subscription,
    GeoTree,
  },

  data() {
    return {
      isSelectorShow: false,
      listOfLegalForms: [],
      showNotification: true,
      itIsTimeToShow: false,
      hideAdditionalNotification: false,
      visibilityCounter: 0,
      isVisible: false,
    };
  },

  computed: {
    periodAdverb() {
      switch (this.chosenSubscriptionPeriod) {
        case 'week':
          return 'Еженедельно';
        case 'month':
          return 'Ежемесячно';
        default:
          return '';
      }
    },

    excelOrUpdatingClassObject() {
      return {
        excelOrUpdatingHeadAdditionalFilterOpen: this.expandAdditionalFilters,
      };
    },

    getCityDescription() {
      if (this.selectedCities.length === 1) {
        return this.selectedCities[0].value.obj.name;
      }

      if (this.selectedCities.length > 1 && Array.isArray(this.selectedCities)) {
        return `${this.selectedCities.length} ${plural(this.selectedCities.length, 'город', 'города', 'городов')}`;
      }

      return 'Города не выбраны';
    },

    ...mapGetters([
      'expandAdditionalFilters',
      'getSelectedBasicFilters',
    ]),

    ...mapGetters('summary', [
      'chosenSubscriptionPeriod',
    ]),

    ...mapState([
      'entrepreneur',
      'ooo',
      'other',
      'legalFormNameForUser',
      'nameFilter',
      'phoneFilter',
      'addressFilter',
      'emailFilter',
      'decisionMakerFilter',
      'ogrnSelectedFilter',
      'visibleTree',
      'visiblePaymentPopup',
    ]),

    ...mapState('summary', [
      'isSubscriptionMode',
    ]),

    ...mapGetters('tree', ['selectedCities', 'cityIsEmpty']),

    selectedLegalForm() {
      if (this.legalFormNameForUser.length === 0) {
        return 'Не выбрано';
      }
      if (this.legalFormNameForUser.length === 3) {
        return 'Все формы';
      }
      return this.legalFormNameForUser.join(', ');
    },

    legalFormIsEmpty() {
      return this.legalFormNameForUser.length === 0;
    },
  },

  watch: {
    getSelectedBasicFilters: {
      handler() {
        this.hideAdditionalNotification = true;
      },

      deep: true,
    },

    isSelectorShow() {
      if (this.isSelectorShow) {
        document.addEventListener('click', this.outsideEventListener);
      } else {
        document.removeEventListener('click', this.outsideEventListener);
      }
    },
  },

  beforeMount() {
    if (window.location.hash === '#subscription') {
      this.setSubscriptionMode(true);

      const top = document.querySelector('.m__main_export_block').getBoundingClientRect().top
          - document.querySelector('.header_top.m__header_line').clientHeight - 5 + window.pageYOffset;

      window.scrollTo({
        top,
        behavior: 'smooth',
      });
    }
  },

  mounted() {
    if (window.vue_header_personal_cabinet._isMounted) {
      this.showHintBalloon();
    } else {
      window.addEventListener('load', () => this.showHintBalloon());
    }
  },

  async created() {
    window.addEventListener('hashchange', () => this.showHintBalloon());
    await this.setGeoTree();

    setTimeout(() => {
      this.itIsTimeToShow = true;
    }, 3000);
  },

  methods: {
    selectorChange() {
      this.isSelectorShow = !this.isSelectorShow;
    },

    showTree() {
      this.setVisibleTree(true);
    },

    outsideEventListener(event) {
      const buttonSelector = document.getElementById('buttonSelectorLegalFormConfigurator');
      if (event.target === buttonSelector || event.target.className.includes('legalForm')) {
        return;
      }
      this.isSelectorShow = false;
    },

    toggleName() {
      window.MNewCompanies.clickDisabled();
    },

    onNotificationClick() {
      window.MFuncs.yandexReachGoalWithTarget('help_box');
      const siteHeaderHeight = document.querySelector('body .header_top').offsetHeight;
      const elem = document.querySelector('#result_for_balloon_NC_hint_scrolling');
      window.scrollBy({
        top: (elem.getBoundingClientRect().top - siteHeaderHeight),
        behavior: 'smooth',
      });
    },

    ...mapMutations(['setVisibleTree']),

    ...mapActions([
      'toggleLegalForm',
      'togglePhoneStore',
      'toggleAddressStore',
      'toggleEmailStore',
      'toggleDecisionMakerStore',
      'toggleOgrnStore',
    ]),

    ...mapActions('tree', ['setGeoTree']),

    ...mapActions('summary', [
      'toggleSubscriptionMode',
      'setSubscriptionMode',
    ]),

    showHintBalloon() {
      this.isVisible = false;
      const isUserAuthorized = window.vue_header_personal_cabinet.authorized;

      if (this.visibilityCounter < 2 && !isUserAuthorized) {
        setTimeout(
          () => {
            this.isVisible = true;
            this.visibilityCounter += 1;
          },
          5000,
        );
      }
    },
  },
};
</script>

<style scoped src="./NewCompanyConfigurator.css"></style>

<style scoped>
.notificationBalloon {
  bottom: 20px;
  right: -42px;
  width: 190px;
}
</style>
