import axios from 'axios';
import { stringify } from 'querystring';

export default {
    createPaymentUrl(payMethod, idOrder, target) {
        return axios.post(
            '/api/money_yandex/create_payment',
            stringify({
                payMethod,
                idOrder,
                paymentTarget: target,
            }),
        ).then((res) => res.data);
    },

    /* запись в таблицу применных промокодов после создания заказа */
    async applyPromocode(idOrder, promocode) {
        return (await axios.post('/api/main_base/apply_promocode', {
            idOrder,
            promocode,
        })).data;
    },

    /* получение discount  промокода, если промокод существует и проходит проверки */
    async checkPromocode(promocode, cost, email) {
        return (await axios.post('/api/main_base/check_promocode', {
            promocode,
            cost,
            email,
        })).data;
    },
};
