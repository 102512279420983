<template>
  <div class="order_description_info">
    <span class="header">Ваш заказ:</span>
    <div class="deposit-payment_info">
      <table class="order_description_info_params">
        <tbody>
          <tr>
            <td>
              <b>{{ costOrBalanceTitle }}:</b>
            </td>
            <td class="cost">
              <span class="cost_wrapper _order_cost_wrapper">
                <b>
                  <span
                    class="orderCost _order_cost"
                    :data-cost_cash="subscriptionDeposit"
                    data-user_discount="0"
                  >
                    {{ costOrBalanceValue }} руб.
                  </span>
                </b>
              </span>
            </td>
          </tr>

          <tr>
            <td>
              <b>Стоимость 1 шт.:</b>
            </td>
            <td style="color:#999">
              {{ getOneCompanyPriceText }}
            </td>
          </tr>

          <tr>
            <td>
              <b>Поля:</b>
            </td>
            <td
              class="td_filters"
              @click="showNote"
            >
              <span class="selectedFilter"> Название / ИНН / ОКВЭД</span>
              <span :class="isActive('phone')"> Телефон</span>
              <span :class="isActive('address')"> Адрес и индекс</span>
              <span :class="isActive('email')"> E-mail аффилирован.</span>
              <span :class="isActive('decision_maker')"> Руководитель (по ЕГРЮЛ)</span>
              <span :class="isActive('ogrn')"> ОГРН / КПП</span>
            </td>
          </tr>

          <tr>
            <td class="td_upload_format">
              <b>Формат:</b>
            </td>
            <td class="td_upload_format">
              .xlsx (Excel)
            </td>
          </tr>

          <tr title="Периодичность выгрузки">
            <td>
              <b>Начало подписки:</b>
            </td>
            <td>
              <span v-text="getformatTime(subscriptionStartDate)" />
            </td>
          </tr>

          <tr title="Периодичность выгрузки">
            <td>
              <b>Получение компаний:</b>
            </td>
            <td>
              <span
                class="td__span_frequency"
                v-text="translateToRussian(chosenSubscriptionPeriod)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

export default {
  name: 'OrderDescription',

  computed: {
    ...mapGetters('summary', [
      'subscriptionDepositRefill',
      'subscriptionDeposit',
      'subscriptionUserBalance',
      'subscriptionOneCompanyCostText',
      'subscriptionStartDate',
      'chosenSubscriptionPeriod',
    ]),

    ...mapGetters([
      'getSelectedBasicFilters',
    ]),

    costOrBalanceTitle() {
      return this.subscriptionDepositRefill ? 'Cумма пополнения' : 'Ваш баланс';
    },

    costOrBalanceValue() {
      return this.subscriptionDepositRefill
        ? this.subscriptionDeposit.toLocaleString('ru')
        : this.subscriptionUserBalance.toLocaleString('ru');
    },

    getOneCompanyPriceText() {
      return `${this.subscriptionOneCompanyCostText.oneFirmCost} ${this.subscriptionOneCompanyCostText.costUnit}`;
    },
  },

  methods: {
    translateToRussian(word) {
      switch (word) {
        case 'month':
          return 'ежемесячно';
        default:
          return 'еженедельно';
      }
    },

    isActive(filter) {
      return this.getSelectedBasicFilters.indexOf(filter) !== -1
        ? 'selectedFilter' : 'notSelectedFilter';
    },

    getformatTime(date) {
      dayjs.locale('ru');
      return dayjs(date).format('DD MMMM YYYY');
    },

    showNote() {
      window.echo('Изменить фильтры вы можете в конфигурации базы на главной странице.');
    },
  },
};
</script>

<style scoped src="../assets/css/style.css"></style>

<style scoped>
.header {
  margin-bottom: 17px;
}

.order_description_info {
  width: 320px;
  height: 50%;
  border-right: 1px dotted #ccc;
  padding-bottom: 0;
  margin: 10px;
}

.order_description_info_params {
  line-height: 1;
  color: black;
  font-family: PTSansExportBase, sans-serif;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  vertical-align: baseline;
  border-spacing: 0;
  border-collapse: collapse;
}

tbody {
  border-spacing: 0;
  border-collapse: collapse;
}

tr {
  width: 150px;
}

td {
  vertical-align: baseline;
  text-align: left;
  padding: 3px;
}

.orderCost {
  display: inline-block;
  padding: 3px 6px;
  border-radius: 5px;
  background: #c6cc9c;
  font-weight: bold;
  font-size: 18px
}

.selectedFilter {
  display: block;
  background: url(../assets/tick_small.png) 2px 0 no-repeat;
  padding-left: 18px;
  margin-top: 3px;
}

.notSelectedFilter {
  display: block;
  color: #999;
  background: url(../assets/bullet_minus.png) 0 0 no-repeat;
  padding-left: 18px;
  margin-top: 3px;
}

.td_upload_format {
  padding-top: 10px;
}

.td__span_frequency {
  color:#01A508
}
</style>
