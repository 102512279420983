<template>
  <div class="cashless_payment_form__bill_container">
    <div
      class="cashless_payment_form__bill_container__item"
      :class="{'active': billOnly}"
      @click="justBill"
    >
      <span>Счет на оплату</span>
    </div>
    <div
      class="cashless_payment_form__bill_container__item"
      :class="{'active': !billOnly}"
      @click="billAndContract"
    >
      <span>Счет + Договор</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bill',
  props: {
    billOnly: Boolean,
  },

  emits: ['setBillOnlyState'],

  methods: {
    justBill() {
      this.$emit('setBillOnlyState', true);
    },

    billAndContract() {
      this.$emit('setBillOnlyState', false);
    },
  },
};
</script>

<style scoped>
    .cashless_payment_form__bill_container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 15px;
    }

    .cashless_payment_form__bill_container__item {
      background: transparent;
      cursor: pointer;
      border: 3px solid #AAA;
      width: 180px;
      height: 35px;
      border-radius: 10px;
      font-size: 15px;
      color: #aaa;
      font-weight: 700;
      text-align: center;
      align-content: center;
    }

    .active {
      color: var(--color-button-prime);
      border: 3px solid var(--color-button-prime);
    }
</style>
