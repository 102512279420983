<template>
  <form
    class="promocode"
    novalidate
    @submit.prevent="submitForm"
  >
    <input
      id="promocode"
      :value="promocode"
      :readonly="!email"
      :disabled="promocodeApplied"
      :class="{'promocode__input_active': email || promocodeApplied}"
      placeholder="Промокод"
      name="promocode"
      type="text"
      class="promocode__input"
      required
      @input="changeInput"
      @click="clickInput"
    >
    <button
      type="submit"
      class="promocode__button eb-button-prime"
      :class="{'promocode__button_disabled': promocodeIsLoading || promocodeApplied}"
    >
      {{ promocodeButtonText }}
    </button>
  </form>
</template>

<script>
export default {
  name: 'RowPromocode',

  props: {
    email: {
      type: String,
      required: false,
      default: '',
    },

    promocode: {
      type: String,
      required: false,
      default: '',
    },

    promocodeIsLoading: {
      type: Boolean,
      required: false,
      default: false,
    },

    promocodeApplied: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  emits: ['inputPromocode', 'submittedPromo'],

  computed: {
    promocodeButtonText() {
      return this.promocodeApplied ? 'Успешно' : 'Применить';
    },

    promocodeIsEmpty() {
      return this.promocode.length === 0;
    },

    promocodeIsValid() {
      return /^[a-zA-Z0-9]+$/.test(this.promocode);
    },
  },

  methods: {
    clickInput() {
      if (!this.email) {
        window.echo('Пожалуйста, сначала укажите ваш E-mail');
      }
    },

    changeInput(e) {
      this.$emit('inputPromocode', e.target.value.trim());
    },

    submitForm() {
      if (!this.email) {
        window.echo('Пожалуйста, сначала укажите ваш E-mail');
        return;
      }

      if (this.promocodeIsLoading || this.promocodeApplied) return;

      if (this.promocodeIsEmpty) {
        echo(
          'Промокод не должен быть пустым',
          6000,
          'warning',
        );
        return;
      }

      if (!this.promocodeIsValid) {
        echo(
          'Промокод не прошел проверку',
          6000,
          'warning',
        );
        return;
      }

      this.$emit('submittedPromo');
    },
  },
};
</script>

<style scoped src="../../../../css/elements.css"></style>

<style scoped>
  .promocode {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 220px;
  }

  .promocode__input {
    max-width: 100px;
    padding: 5px;
    border: 1px solid #aaa;
    outline: none;
  }

  .promocode__button {
    border: none;
    border-radius: .7em;
    font-size: 14px;
    margin-left: 18px;
  }

  .promocode__button_disabled {
    background: lightgrey;
  }

  .promocode__button_disabled:hover {
    background: lightgrey;
  }
</style>
