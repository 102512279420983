<template>
  <form
    class="cashless_payment_form__container"
    @submit.prevent="createBill"
  >
    <div class="cashless_payment_form__input_item">
      <TextInput
        title="<b>Ваш E-mail</b>"
        :html-title="true"
        placeholder="Ваш эл. адрес"
        :value="getUserEmail"
        :valid="validEmail(getUserEmail)"
        :need-validation="!emailIsDisabled && getUserEmail.length !== 0"
        :disabled="emailIsDisabled"
        @handle-input="setEmail"
      />
    </div>

    <div class="cashless_payment_form__input_item">
      <TextInput
        title="ИНН"
        placeholder="1234XXXXX5678"
        :value="clientData.inn"
        :valid="validInn(clientData.inn)"
        :need-validation="clientData.inn.length !== 0"
        @handle-input="setInn"
      />
    </div>

    <div class="cashless_payment_form__input_item">
      <TextInput
        title="ОГРН"
        placeholder="1234XXXXX5678"
        :value="clientData.ogrn"
        :valid="validOgrn(clientData.ogrn)"
        :need-validation="clientData.ogrn.length !== 0"
        @handle-input="setOgrn"
      />
    </div>

    <div class="cashless_payment_form__input_item">
      <TextInput
        title="КПП"
        placeholder="1234XXXXX5678"
        :value="clientData.kpp"
        :valid="validKpp(clientData.kpp)"
        :need-validation="kppNeedValidation"
        :disabled="!kppIsNecessary"
        @handle-input="setKpp"
      />
    </div>

    <div class="cashless_payment_form__input_item">
      <TextInput
        title="Название фирмы"
        placeholder="Overpin"
        :value="clientData.companyName"
        :valid="notEmpty(clientData.companyName)"
        :need-validation="clientData.companyName.length !== 0"
        @handle-input="setCompanyName"
      />
    </div>

    <div class="cashless_payment_form__input_item">
      <TextInput
        title="Юридический<br>адрес"
        :html-title="true"
        placeholder="150000, г. Ярославль, Свободы 2-517"
        :value="clientData.address"
        :valid="notEmpty(clientData.address)"
        :need-validation="clientData.address.length !== 0"
        @handle-input="setAddress"
      />
    </div>

    <transition name="fade">
      <!--       если выбран счет+договор -->
      <div
        v-if="!billOnly"
        class="cashless_payment_form__just_column_container"
        :class="clientData.chosenActionDocument === 'regulation' ?
          'bill_and_contract' : 'bill_and_contract_with_number'"
      >
        <div class="cashless_payment_form__input_item">
          <TextInput
            :title="payerPositionTitle"
            placeholder="Иванов Иван Иванович"
            :value="clientData.payerName"
            :valid="notEmpty(clientData.payerName)"
            :need-validation="clientData.payerName.length !== 0"
            @handle-input="setPayerName"
          />
        </div>

        <!--          выбор должности и вида документа -->
        <div class="cashless_payment_form_item__detach">
          <RadioInput
            title="Должность"
            name="position"
            :items="positions"
            :chosen-item-value="clientData.chosenPosition"
            @choose="setPayerPosition"
          />
        </div>

        <div class="cashless_payment_form_item__detach">
          <RadioInput
            title="Действует на основании"
            name="actionDocument"
            :items="actionDocuments"
            :chosen-item-value="clientData.chosenActionDocument"
            :sub-text-inputs="['certificate', 'power_of_attorney']"
            :sub-text-input-value="clientData.actionDocumentNumber"
            @choose="setActionDocument"
            @set-sub-text-input="setActionDocumentNumber"
          />
        </div>
      </div>
    </transition>

    <div class="cashless_payment_form__margin_top_wrapper">
      <DocumentManagement
        :document-management="documentManagement"
        @setDocumentManagement="setDocumentManagement"
      />
    </div>

    <transition name="fade">
      <!--        если выбрана отправка документов по почте -->
      <div
        v-if="clientData.documentManagement === 'post'"
        class="cashless_payment_form__just_column_container cashless_payment_form_item__detach post_address"
      >
        <div class="cashless_payment_form__post_address_attention__container">
          <span class="cashless_payment_form__post_address_attention__text">Внимание!
            По указанному вами ниже почтовому адресу мы направим оригиналы закрывающих документов.
            Просим внимательно заполнять поля.</span>
        </div>

        <div class="cashless_payment_form__input_item">
          <TextInput
            title="Почтовый индекс"
            placeholder="150000"
            :value="clientData.postIndex"
            :valid="validPostIndex(clientData.postIndex)"
            :need-validation="clientData.postIndex.length !== 0"
            @handle-input="setPostIndex"
          />
        </div>

        <div class="cashless_payment_form__input_item">
          <TextInput
            title="Населенный пункт"
            placeholder="г. Ярославль"
            :value="clientData.locality"
            :valid="notEmpty(clientData.locality)"
            :need-validation="clientData.locality.length !== 0"
            @handle-input="setLocality"
          />
        </div>

        <div class="cashless_payment_form__input_item">
          <TextInput
            title="Улица"
            placeholder="Свободы"
            :value="clientData.street"
            :valid="notEmpty(clientData.street)"
            :need-validation="clientData.street.length !== 0"
            @handle-input="setStreet"
          />
        </div>

        <div class="cashless_payment_form__input_item">
          <TextInput
            title="Дом"
            placeholder="2"
            :value="clientData.houseNumber"
            :mode="['short']"
            :valid="notEmpty(clientData.houseNumber)"
            :need-validation="clientData.houseNumber.length !== 0"
            @handle-input="setHouseNumber"
          />
        </div>

        <div class="cashless_payment_form__input_item">
          <TextInput
            title="Офис/кв."
            placeholder="1"
            :value="clientData.office"
            :mode="['short']"
            :valid="notEmpty(clientData.office)"
            :need-validation="clientData.office.length !== 0"
            @handle-input="setOffice"
          />
        </div>
      </div>
    </transition>

    <div class="cashless_payment_form__margin_top_wrapper">
      <label class="cashless_payment_form__privacy_policy__container">
        <input
          id="privacy_policy"
          type="checkbox"
          :checked="clientData.agreeWithPrivacyPolicy"
          @change="handlePrivacyPolicy"
        >
        <span class="cashless_payment_form__privacy_policy__text">
          Даю согласие на обработку моего e-mail в соответствии с
          <a
            class="blue_link"
            href="/privacy_policy"
            target="_blank"
          >
            политикой конфиденциальности ExportBase
          </a>
        </span>
      </label>
    </div>

    <div
      v-if="billButton"
      class="cashless_payment_form__margin_top_wrapper"
    >
      <BillButton />
    </div>
  </form>
</template>

<script>
import ValidateValueHelper from '../helper/ValidateValueHelper';
import TextInput from './TextInput';
import RadioInput from './RadioInput';
import BillButton from './BillButton';
import DocumentManagement from '../Switch/DocumentManagement';

export default {
  name: 'Form',
  components: {
    DocumentManagement, BillButton, RadioInput, TextInput,
  },

  props: {
    billOnly: Boolean,
    emailIsDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },

    userEmail: {
      type: String,
      default: '',
      required: false,
    },

    billButton: {
      type: Boolean,
      default: false,
      required: false,
    },

    clientData: Object,
    documentManagement: String,
  },

  emits: ['setDocumentManagement', 'setOffice', 'setHouseNumber', 'setStreet', 'setLocality'],

  data() {
    return {
      positions: [
        { value: 'director', title: 'Директор' },
        { value: 'ceo', title: 'Генеральный директор' },
        { value: 'leader', title: 'Руководитель' },
      ],

      actionDocuments: [
        { value: 'regulation', title: 'Устав' },
        { value: 'certificate', title: 'Свидетельство' },
        { value: 'power_of_attorney', title: 'Доверенность' },
      ],
    };
  },

  computed: {
    getUserEmail() {
      let email;

      if (this.userEmail.length === 0) {
        email = this.clientData.email;
      } else {
        email = this.userEmail;
      }

      return email;
    },

    kppIsNecessary() {
      return !ValidateValueHelper.innRefersToIndividualEntrepreneur(this.clientData.inn);
    },

    kppNeedValidation() {
      return !ValidateValueHelper.innRefersToIndividualEntrepreneur(this.clientData.inn)
                    && this.clientData.kpp.length !== 0;
    },

    payerPositionTitle() {
      const position = 'ФИО ';

      switch (this.clientData.chosenPosition) {
        case 'director':
          return `${position}дир.`;
        case 'ceo':
          return `${position}ген. дир.`;
        case 'leader':
          return `${position}рук.`;
        default:
          return `${position}дир.`;
      }
    },
  },

  methods: {
    handlePrivacyPolicy(e) {
      this.$emit('handle-privacy-policy', e.target.checked);
    },

    notEmpty(value) {
      return ValidateValueHelper.notEmpty(value);
    },

    validEmail(value) {
      return ValidateValueHelper.validateEmail(value);
    },

    validInn(value) {
      return ValidateValueHelper.validateInn(value);
    },

    validOgrn(value) {
      return ValidateValueHelper.validateOgrn(value);
    },

    validKpp(value) {
      return ValidateValueHelper.validateKpp(value);
    },

    validPostIndex(value) {
      return ValidateValueHelper.validatePostIndex(value);
    },

    createBill() {
      if (this.billButton) {
        this.$emit('create-bill');
      }
    },

    setEmail(e) {
      this.$emit('set-email', e);
    },

    setInn(e) {
      this.$emit('set-inn', e);
    },

    setOgrn(e) {
      this.$emit('set-ogrn', e);
    },

    setKpp(e) {
      this.$emit('set-kpp', e);
    },

    setCompanyName(e) {
      this.$emit('set-company-name', e);
    },

    setAddress(e) {
      this.$emit('set-address', e);
    },

    setPayerName(e) {
      this.$emit('set-payer-name', e);
    },

    setPayerPosition(e) {
      this.$emit('set-payer-position', e);
    },

    setActionDocument(e) {
      this.$emit('set-action-document', e);
    },

    setActionDocumentNumber(value) {
      this.$emit('set-action-document-number', value);
    },

    setPostIndex(e) {
      this.$emit('set-post-index', e);
    },

    setLocality(e) {
      this.$emit('setLocality', e);
    },

    setStreet(e) {
      this.$emit('setStreet', e);
    },

    setHouseNumber(e) {
      this.$emit('setHouseNumber', e);
    },

    setOffice(e) {
      this.$emit('setOffice', e);
    },

    setDocumentManagement(value) {
      this.$emit('setDocumentManagement', value);
    },
  },
};
</script>

<style scoped>
    .cashless_payment_form__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
    }

    .cashless_payment_form__just_column_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    .bill_and_contract {
        height: 213px;
    }

    .bill_and_contract_with_number {
        height: 245px;
    }

    .post_address {
        height: 237px;
    }

    .cashless_payment_form_item__detach {
        margin: 10px 0;
    }

    .cashless_payment_form__input_item {
        margin-bottom: 10px;
    }

    .cashless_payment_form__privacy_policy__container {
        display: flex;
        flex-direction: row;
        gap: 10px;
        width: 320px;
    }

    .cashless_payment_form__privacy_policy__text {
        text-align: center;
    }

    .cashless_payment_form__margin_top_wrapper {
        margin-top: 20px;
    }

    .cashless_payment_form__margin_bottom_wrapper {
        margin-bottom: 10px;
    }

    .cashless_payment_form__disagree_checkbox {
        box-shadow: #D21C1C 0 0 4px !important;
    }

    .cashless_payment_form__post_address_attention__container {
        display: flex;
        flex-direction: row;
        margin: 0 0 10px 20px;
    }

    .cashless_payment_form__post_address_attention__text {
        color: #999;
        font-size: 13px;
        font-style: italic;
        font-weight: bold;
        text-align-last: start;
        text-align: start;
    }

    .fade-enter-active, .fade-leave-active {
        transition-property: opacity, height;
        transition-duration: 0.6s, 0.6s;
    }

    .fade-enter, .fade-leave-to {
        height: 0;
        opacity: 0;
    }
</style>
