<template>
  <div class="pay">
    <div class="tab-buttons">
      <button
        v-for="tab in tabs"
        :key="tab.slot"
        class="tab-button"
        :class="[{ 'tab-button_active': currentTab === tab.slot }]"
        @click="switchTab(tab.slot)"
      >
        <img
          alt="logo"
          :src="tab.img"
          class="tab-button__img"
        >
        <span class="tab-button__text">
          {{ tab.title }}
        </span>
      </button>
    </div>

    <div class="tab">
      <div v-show="currentTab === 'BalanceForm'">
        <slot name="BalanceForm" />
      </div>
      <div v-show="currentTab === 'CashlessPaymentForm'">
        <slot name="CashlessPaymentForm" />
      </div>
      <div v-show="currentTab === 'PaymentSystems'">
        <slot name="PaymentSystems" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PayForm',
  props: {
    customerIsAuthorized: {
      type: Boolean,
      required: true,
    },

    cost: {
      type: Number,
      required: true,
    },

    initialTab: {
      type: String,
      required: false,
    },

    withoutCashless: {
      type: Boolean,
      required: false,
      default: false,
    },

    withoutBalance: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      currentTab: 'PaymentSystems',
      tabs: [
        {
          slot: 'PaymentSystems',
          title: 'Платежные системы',
          img: '/dist/css/images/payment_payment_system_new.png',
        },
        {
          slot: 'CashlessPaymentForm',
          title: 'Оплата от организации',
          img: '/dist/css/images/payment_cashless.png',
        },
        {
          slot: 'BalanceForm',
          title: 'Оплата с баланса',
          img: '/images/paym_safe.png',
        },
      ],
    };
  },

  created() {
    if (!this.initialTab) return;
    this.currentTab = this.initialTab;
  },

  methods: {
    switchTab(slot) {
      if (slot === 'CashlessPaymentForm' && this.withoutCashless) {
        window.echo(
          `Для оплаты от организации в данном разделе обратитесь <br>
                    к менеджеру в чате на сайте или по тел. 8 800 775-29-12.
                    <br>
                  Наш E-mail: <a href="mailto:info@export-base.ru" target="_blank" style="color: inherit;">info@export-base.ru</a>`,
          20000,
        );
        this.$emit('cashless-attempt');
        return;
      }

      if (slot === 'BalanceForm' && this.withoutBalance) {
        window.echo(
          `Для оплата с баланса в данном разделе обратитесь <br>
                    к менеджеру в чате на сайте или по тел. 8 800 775-29-12.
                    <br>
                    Наш E-mail: <a href="mailto:info@export-base.ru" target="_blank" style="color: inherit;">info@export-base.ru</a>`,
          20000,
        );
        return;
      }

      if (!this.allowSwitchTab(slot)) return;
      this.$emit('change-tab', slot);
      this.currentTab = slot;
    },

    allowSwitchTab(slot) {
      const forms = {
        PaymentSystems: () => true,
        CashlessPaymentForm: this.cashlessAccessHandler,
        BalanceForm: this.balanceAccessHandler,
      };

      return forms[slot]();
    },

    balanceAccessHandler() {
      if (this.customerIsAuthorized) {
        return true;
      }
      this.$emit('init-login');
      return false;
    },

    cashlessAccessHandler() {
      return (
        (this.cost < 500)
          ? confirm('Минимальный заказ по безналу 500 руб. Продолжить выставление счёта на оплату?')
          : true
      );
    },
  },
};
</script>

<style scoped src="./PayForm.css"></style>
