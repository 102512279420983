<template>
  <div class="payment_by_balance_component__container">
    <PaymentByBalanceBlock
      :balance="getBalance"
      :order-cost="getCost"
      :client-email="getEmail"
    />
    <PaySystems
      v-if="!balanceIsEnough && showPaySystems"
      class="payment-type"
      :payment-type="paymentType"
      @selectPaymentType="setPaymentType"
    />
    <a
      v-if="!balanceIsEnough"
      :href="urlForPay"
      target="_blank"
      rel="noopener noreferrer"
      class="payment_by_balance_component__payment_button__wrapper"
      @click="showPaymentSystems"
    >
      <span class="payment_by_balance_component__payment_button">
        <span>
          Пополнить
        </span>
      </span>
    </a>
    <div
      v-if="balanceIsEnough"
      class="payment_by_balance_component__payment_button__wrapper"
    >
      <span
        class="payment_by_balance_component__payment_button"
        @click="handlePayment"
      >
        <span>
          Оплатить
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import PaymentByBalanceBlock from '@/common_components/PayPopup/paymentByBalance/components/PaymentByBalanceBlock.vue';
import PaySystems from '@/common_components/PayPopup/paySystems/PaySystems.vue';
import api from './api/api';
import popupApi from '../api';

export default {
  name: 'App',
  components: { PaySystems, PaymentByBalanceBlock },
  props: {
    propOrderId: {
      type: [Number, String],
      required: false,
      default: '',
    },

    propClientId: {
      type: [Number, String],
      required: false,
      default: '',
    },

    propEmail: {
      type: String,
      required: false,
      default: '',
    },

    propCost: {
      type: Number,
      required: false,
      default: -1,
    },

    propBalance: {
      type: Number,
      required: false,
      default: -1,
    },

    propService: {
      type: String,
      required: false,
      default: '',
    },

    customerIsAuthorized: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      service: '',
      orderId: -1,
      clientId: -1,
      clientBalance: -1,
      orderCost: -1,
      clientEmail: '',
      agreeWithPrivacyPolicy: true,

      reloadAfterPayment: false,
      redirectToPersonalCabinet: true,
      redirectGetParameter: 'action=PaymentSuccess&orderNumber=',

      paymentType: 'sbp',
      paymentTarget: 'deposit_refill',
      hashBalanceOrder: '',
      urlForPay: '',
      showPaySystems: false,
    };
  },

  computed: {
    clientBalanceIsEnough() {
      return this.getBalance >= this.getCost;
    },

    getOrderId() {
      if (typeof this.propOrderId === 'string') {
        if (this.propOrderId.length !== 0) {
          return this.propOrderId;
        }
      } else if (typeof this.propOrderId === 'number') {
        if (this.propOrderId > 0) {
          return this.propOrderId;
        }
      }
      return this.orderId;
    },

    getClientId() {
      if (typeof this.propClientId === 'string') {
        if (this.propClientId.length !== 0) {
          return this.propClientId;
        }
      } else if (typeof this.propClientId === 'number') {
        if (this.propClientId > 0) {
          return this.propClientId;
        }
      }
      return this.clientId;
    },

    getBalance() {
      if (this.propBalance >= 0) {
        return this.propBalance;
      }
      return this.clientBalance;
    },

    getCost() {
      if (this.propCost >= 0) {
        return this.propCost;
      }
      return this.orderCost;
    },

    getEmail() {
      if (this.propEmail.length !== 0) {
        return this.propEmail;
      }
      return this.clientEmail;
    },

    getService() {
      if (this.propService.length !== 0) {
        return this.propService;
      }
      return this.service;
    },

    balanceIsEnough() {
      return this.getBalance >= this.getCost;
    },

    buttonText() {
      return this.balanceIsEnough ? 'Оплатить' : 'Пополнить баланс';
    },

    sumForPay() {
      return this.propCost - this.propBalance;
    },
  },

  methods: {
    setService(value) {
      this.service = value;
    },

    setOrderId(value) {
      this.orderId = value;
    },

    setClientId(value) {
      this.clientId = value;
    },

    setClientBalance(value) {
      this.clientBalance = value;
    },

    setOrderCost(value) {
      this.orderCost = value;
    },

    setClientEmail(value) {
      this.clientEmail = value;
    },

    setReloadAfterPayment(value) {
      this.reloadAfterPayment = value;
    },

    setRedirectToPersonalCabinet(value) {
      this.redirectToPersonalCabinet = value;
    },

    setRedirectGetParameter(value) {
      this.redirectGetParameter = value;
    },

    reloadPage(delay) {
      setTimeout(() => location.reload(), delay);
    },

    redirectClientToPersonalCabinet(delay) {
      setTimeout(() => location.href = `${location.origin}/auth?${this.redirectGetParameter}${this.propOrderId}`, delay);
    },

    setPaymentType(type) {
      this.paymentType = type;
      this.setUrlForPayment();
    },

    async handlePayment() {
      window.MFuncs.yandexReachGoalWithTarget('pay');
      if (this.balanceIsEnough === true) {
        const result = await api.payByBalance(this.getOrderId, this.getClientId, this.getService);
        if (result === false) {
          window.echo(
            'Произошла ошибка при оплате заказа. Просим вас связаться с тех. поддержкой по тел. 8 800 775-29-12',
            6000,
            'error',
          );
        } else if (result.success !== undefined && result.message !== undefined) {
          window.echo(result.message, 6000, 'success');
          if (result.success === true) {
            await window.vue_header_personal_cabinet.$store.dispatch('updateBalance');
            this.handleAfterPayment();
          }
        }
      }
    },

    closeModal() {
      window.jQuery('._popup_close').click();
      window.jQuery('.popup_window .close').click();
      if (window.vue_company_search) {
        document.dispatchEvent(new Event('closeYandexPayment'));
      }
    },

    handleAfterPayment() {
      this.closeModal();

      if (this.reloadAfterPayment === true) {
        this.reloadPage(1500);
      }

      if (this.redirectToPersonalCabinet === true) {
        this.redirectClientToPersonalCabinet(1500);
      }
    },

    createBalanceOrder() {
      return api.refillBalance({
        email: this.propEmail,
        cost: this.sumForPay,
      });
    },

    async showPaymentSystems(e) {
      if (!this.showPaySystems) {
        e.preventDefault();
      }
      if (this.showPaySystems) {
        if (window.MFuncs.yandexReachGoalWithTarget) {
          window.MFuncs.yandexReachGoalWithTarget('balance');
        }
      }
      const res = await this.createBalanceOrder();
      this.hashBalanceOrder = res.hash;
      if (!this.balanceIsEnough) {
        await this.setUrlForPayment();
      }
      this.showPaySystems = true;
    },

    async setUrlForPayment() {
      try {
        this.urlForPay = await popupApi.createPaymentUrl(this.paymentType, this.hashBalanceOrder, this.paymentTarget);
      } catch (e) {
        echo(
          'Произошла ошибка! Просим вас связаться с тех. поддержкой по тел. 8 800 775-29-12',
          6000,
          'error',
        );
      }
    },
  },
};
</script>

<style scoped src="./App.css"></style>
