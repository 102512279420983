<template>
  <TwoBlocksPopup @close-popup="hideGeoTree">
    <template #popup-left-block>
      <div>
        <span class="change-cities__title">Выберите нужные города:</span>
        <div
          class="notSelectedCitiesAttention"
          :class="{'selectedCities' : !tooltipVisible}"
        >
          <img
            src="/images/icons/simple_shapes/triangle-attention.svg"
            alt="Внимание! Города не выбраны."
            width="28px"
            height="28px"
          >
          <span class="notSelectedCitiesText">
            Города не выбраны. Настройте свою выборку.
          </span>
        </div>
      </div>
      <SearchBar
        v-model="searchStringModel"
        class="searchBar"
        width="97%"
        placeholder="Поиск города/региона"
        @reset="resetSearch"
      />
      <div
        v-if="!isSearchMode"
        class="tree-component-wrapper scrollable-Y"
      >
        <img
          class="flag"
          src="/images/country1.png"
          alt="flag"
        >
        <TreeComponent
          :node="getGeoTree.getRoot()"
          with-important-nodes
          :extra-node-mode="['tree-city-center']"
          :important-node-mode="['tree-other-cities']"
          :additional-node-mode="['tree-city']"
          :view-line-node-mode="['ml-15', 'mv-15']"
          :second-node-mode="['ml-15']"
          :checkbox-node-mode="{'text-bold': 2}"
          :ul-node-mode="{'clean-tree-ul': 2}"
          :chosen-node-mode="['mark']"
          class="geo-tree"
          @expand-node="expandGeoNode"
          @choose-node="chooseNode"
        />
      </div>
      <div
        v-if="isSearchMode"
        class="search-tree scrollable-Y"
      >
        <span
          v-if="emptySearchResult"
          class="emptySearch"
        >Ничего не найдено</span>
        <TreeComponent
          v-else
          :node="getGeoSearchTree.getRoot()"
          :search-text="searchStringModel"
          :ul-node-mode="{'tree-ul-pa-left-20': 0}"
          :expandable="false"
          class="geo-tree"
          @expand-node="expandGeoSearchNode"
          @choose-node="chooseSearchNode"
        />
      </div>
    </template>
    <template #popup-right-block>
      <p class="city-tree-description__title">
        Как провести конфигурацию городов?
      </p>
      <p class="city-tree-description__text">
        1. Выберите один или несколько городов для начала конфигурации.
      </p>
      <p class="city-tree-description__text">
        2. Вы можете воспользоваться поиском, чтобы найти нужный город.
      </p>
      <div class="search-block__wrapper-btns">
        <div
          class="eb-button-prime small-button"
          :class="{'disabled': !hasSelectedCities}"
          @click="chooseCities"
        >
          Выбрать
        </div>
        <div class="eb-button-secondary small-button" @click="resetChanges">
          Сброс
        </div>
      </div>
    </template>
  </TwoBlocksPopup>
</template>

<script>
import {
  mapGetters, mapMutations, mapActions, mapState,
} from 'vuex';
import { TreeComponent } from 'tree';
import SearchBar from 'searchbar';
import TwoBlocksPopup from '@/common_components/TwoBlocksPopup/TwoBlocksPopup.vue';

export default {
  name: 'GeoTree',
  components: {
    TwoBlocksPopup,
    TreeComponent,
    SearchBar,
  },

  computed: {
    ...mapState('tree', [
      'searchString',
    ]),

    ...mapGetters('tree', [
      'getGeoTree',
      'getGeoSearchTree',
      'isSearchMode',
      'emptySearchResult',
      'tooltipVisible',
      'selectedGeoUnits',
      'hasSelectedCities',
    ]),

    searchStringModel: {
      get() {
        return this.searchString;
      },

      set(value) {
        this.updateSearch(value);
      },
    },
  },

  watch: {
    hasSelectedCities(newVal) {
      if (newVal) {
        this.setTooltipVisible(null);
      }
    },

    selectedGeoUnits: {
      handler() {
        this.calculateConfiguration();
      },

      deep: true,
    },
  },

  mounted() {
    if (this.tooltipVisible !== null) {
      this.setTooltipVisible(true);
    }
  },

  methods: {
    ...mapMutations('tree', [
      'expandGeoNode',
      'expandGeoSearchNode',
      'resetSearch',
      'chooseOrCancelTree',
      'chooseOrCancelSearchTree',
      'setTooltipVisible',
    ]),

    ...mapActions('tree', [
      'chooseGeoSearchNode',
      'updateSearch',
      'chooseGeoNode',
    ]),

    ...mapActions(['calculateConfiguration']),
    ...mapMutations(['setVisibleTree']),

    chooseSearchNode(nodeDesc) {
      this.chooseGeoSearchNode(nodeDesc);
    },

    chooseNode(nodeDesc) {
      this.chooseGeoNode(nodeDesc);
    },

    chooseCities() {
      if (this.hasSelectedCities) {
        this.hideGeoTree();
      }
    },

    resetChanges() {
      this.chooseOrCancelTree(false);
      this.chooseOrCancelSearchTree(false);
    },

    hideGeoTree() {
      this.resetSearch();
      this.setVisibleTree(false);
    },
  },
};
</script>

<style scoped>
.geo-tree {
  padding-left: 30px;
  margin-bottom: 30px;
}

.notSelectedCitiesAttention {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 97%;
  border: var(--color-warning-light) 1px solid;
  border-radius: 5px;
  box-sizing: border-box;
  transition: .5s;
  opacity: 1;
  height: 30px;
  margin-top: 15px;
}

.notSelectedCitiesText {
  color: var(--color-warning-light);
  font-size: 14px;
  font-weight: bold;
}

.selectedCities {
  opacity: 0;
  height: 0;
  margin-top: 0;
}

.change-cities__title {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: bold;
}

.flag {
  position: absolute;
  left: 5px;
  width: 20px;
}

.search-block__wrapper-btns {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  gap: 10px;
}

.city-tree-description__title {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
  margin-top: 70px;
}

.city-tree-description__text {
  margin-bottom: 12px;
}
</style>
