<template>
  <div>
    <div class="header">
      Оплата подписки:
    </div>

    <div class="pay_methods">
      <div class="pay_method active">
        <span class="pay_img_wrapper">
          <img
            class="pay_img"
            src="@/assets/images/payment_popup/payment_payment_system.png"
            width="178"
            height="80"
            alt=""
          >
        </span>
        <p class="pay_name">
          Платежные системы
        </p>
      </div>
      <div
        class="pay_method"
        @click="makeBill"
      >
        <span class="pay_img_wrapper">
          <img
            class="pay_img"
            src="@/assets/images/payment_popup/payment_cashless.png"
            width="119"
            height="80"
            alt=""
          >
        </span>
        <p class="pay_name">
          Безнал. расчет (Без НДС)
        </p>
      </div>
    </div>

    <EmailForm
      v-model="email"
      :is-valid="isValidEmail"
      :period="chosenSubscriptionPeriod"
    />
    <div class="email_form_break" />

    <div v-show="!showSpanSubmit">
      <PaySystems
        class="subscription-pay-systems"
        :payment-type="paymentType"
        @selectPaymentType="selectPaymentType"
      />
      <a
        :href="payLink"
        target="_blank"
        class="payment-href"
      >
        <ButtonMakePayment v-show="!showSpanSubmit" />
      </a>
    </div>

    <ButtonMakePayment
      v-show="showSpanSubmit"
      @click="handleClick"
    />
  </div>
</template>

<script>
import formurlencoded from 'form-urlencoded';
import { mapGetters } from 'vuex';
import axios from 'axios';
import { stringify } from 'querystring';
import PaySystems from '@/common_components/PaySystems/PaySystems.vue';
import EmailForm from './components/EmailForm.vue';
import ButtonMakePayment from '../ButtonMakePayment.vue';

export default {
  name: 'Payment',
  components: {
    EmailForm,
    PaySystems,
    ButtonMakePayment,
  },

  data() {
    return {
      email: '',
      paymentType: 'bank_card',
      customerNumber: 0,
      orderNumber: 0,
      showSpanSubmit: true,
      enableSpanSubmit: true,
      orderType: 'subscription',
      payLink: '',
    };
  },

  computed: {
    ...mapGetters('summary', [
      'userEmail',
      'chosenSubscriptionPeriod',
      'subscriptionDeposit',
    ]),

    isValidEmail() {
      const regExpEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return !!regExpEmail.test(this.email);
    },

    sum() {
      return +this.subscriptionDeposit;
    },
  },

  mounted() {
    if (this.userEmail) {
      this.email = this.userEmail;
    }
  },

  methods: {
    async selectPaymentType(paymentType) {
      this.paymentType = paymentType;
      if (!this.orderNumber) return;
      await this.createPaymentUrl();
    },

    inputEmail(email) {
      this.email = email;
      this.showSpanSubmit = true;
      this.enableSpanSubmit = true;
    },

    createPaymentUrl() {
      return axios.post(
        '/api/money_yandex/create_payment',
        stringify({
          payMethod: this.paymentType,
          idOrder: this.orderNumber,
          paymentTarget: 'subscription',
        }),
      ).then((res) => this.payLink = res.data);
    },

    handleClick() {
      if (this.isValidEmail && this.enableSpanSubmit) {
        this.enableSpanSubmit = false;
        this.makeFetchRequests();
      }
    },

    makeFetchRequests() {
      fetch('/handlers/new_companies_subscription_request.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        body: formurlencoded(window.MNewCompaniesSubscription.getFormData(this.email)),
      })
        .then((response) => response.json())
        .then(
          (response) => {
            this.customerNumber = response.client;
            this.orderNumber = response.transaction;
            this.showSpanSubmit = false;
          },
        )
        .then(() => this.createPaymentUrl())
        .catch(() => {
          alert('К сожалению, произошла ошибка. Пожалуйста, сообщите о ней по телефону 8 800 775-29-12');
        });
    },

    makeBill() {
      window.echo(
        'Для выставления счета на подписку по реквизитам организации обратитесь в тех. поддержку: '
        + '<a href="tel:+78007752912" target="_blank" style="color: white;"><b>8 800 775-29-12</b></a>',
        10000,
      );
    },
  },
};
</script>

<style scoped src="../../assets/css/style.css"></style>

<style scoped>
.pay_methods {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.pay_method {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.pay_method.active {
  cursor: default;
}

.pay_img_wrapper {
  align-self: stretch;
  padding: 4px;
  border-radius: 5px;
  text-align: center;
}

.pay_img_wrapper:hover {
  background-color: #f8d555;
}

.pay_method.active .pay_img_wrapper {
  background-color: #f8d555;
}

.pay_name {
  margin-top: 6px;
  border-bottom: 1px dashed;
  font-weight: 700;
  color: #089fda;
  cursor: pointer;
}

.pay_method.active .pay_name {
  border-bottom: none;
  cursor: default;
}

.email_form_break {
 padding: 15px;
}

.subscription-pay-systems {
  margin-bottom: 20px;
}

.payment-href {
    text-decoration: none;
}
</style>
