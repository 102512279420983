<!--
Компонент-обертка над Checkbox.
Представляет настройку заполненности поля.
-->

<template>
  <div @click="showFilterAlert(filter)">
    <CheckBox
      :id="filter.name"
      :chosen="filter.selected && !filter.partiallySelected"
      :disabled="!filter.selected || filter.disabled"
      :partially-fill="filter.selected && filter.partiallySelected"
      :title="getFieldText"
      :mode="getCheckBoxMode"
      @change="changeFilter"
    />
  </div>
</template>

<script>
import CheckBox from 'checkbox';

export default {
  name: 'CheckboxFillMode',
  components: {
    CheckBox,
  },

  props: {
    filter: {
      type: Object,
      required: true,
    },
  },

  emits: ['changeFilter'],

  computed: {
    getFieldText() {
      if (this.filter.selected) {
        if (this.filter.partiallySelected) {
          return 'Заполнено там, где есть';
        }
        return 'Обязательное поле';
      }
      return 'Поле не выбрано';
    },

    getCheckBoxMode() {
      return this.filter.selected && !this.filter.disabled ? ['dark'] : ['dark', 'disabled'];
    },
  },

  methods: {
    changeFilter() {
      this.$emit('changeFilter', this.filter.name);
    },

    showFilterAlert(filter) {
      if (!filter.selected) {
        window.echo('Данный фильтр не выбран');
      }
    },
  },
};
</script>
