<template>
  <div class="row">
    <p class="row__description">
      Итого:
    </p>
    <div class="row-wrapper">
      <p class="row__price">
        {{ cost.toLocaleString('ru') }} руб.
      </p>
      <p class="row__discount">
        ({{ getTextForDiscount }} {{ discount }}%)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RowCostWithDiscount',
  props: {
    cost: {
      type: Number,
      required: true,
    },

    discount: {
      type: Number,
      required: true,
    },

    promocodeApplied: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    getTextForDiscount() {
      return this.promocodeApplied ? 'скидка' : 'накопительная скидка';
    },
  },
};
</script>

<style scoped src="./css/Row.css"></style>
