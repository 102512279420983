// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/tick_small.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/bullet_minus.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.header[data-v-5bb4da63] {\n  margin-bottom: 17px;\n}\n.order_description_info[data-v-5bb4da63] {\n  width: 320px;\n  height: 50%;\n  border-right: 1px dotted #ccc;\n  padding-bottom: 0;\n  margin: 10px;\n}\n.order_description_info_params[data-v-5bb4da63] {\n  line-height: 1;\n  color: black;\n  font-family: PTSansExportBase, sans-serif;\n  margin: 0;\n  padding: 0;\n  border: 0;\n  outline: 0;\n  font-weight: inherit;\n  font-style: inherit;\n  font-size: 100%;\n  vertical-align: baseline;\n  border-spacing: 0;\n  border-collapse: collapse;\n}\ntbody[data-v-5bb4da63] {\n  border-spacing: 0;\n  border-collapse: collapse;\n}\ntr[data-v-5bb4da63] {\n  width: 150px;\n}\ntd[data-v-5bb4da63] {\n  vertical-align: baseline;\n  text-align: left;\n  padding: 3px;\n}\n.orderCost[data-v-5bb4da63] {\n  display: inline-block;\n  padding: 3px 6px;\n  border-radius: 5px;\n  background: #c6cc9c;\n  font-weight: bold;\n  font-size: 18px\n}\n.selectedFilter[data-v-5bb4da63] {\n  display: block;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") 2px 0 no-repeat;\n  padding-left: 18px;\n  margin-top: 3px;\n}\n.notSelectedFilter[data-v-5bb4da63] {\n  display: block;\n  color: #999;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") 0 0 no-repeat;\n  padding-left: 18px;\n  margin-top: 3px;\n}\n.td_upload_format[data-v-5bb4da63] {\n  padding-top: 10px;\n}\n.td__span_frequency[data-v-5bb4da63] {\n  color:#01A508\n}\n", ""]);
// Exports
module.exports = exports;
