<template>
  <Teleport to="body">
    <div
      class="popup__background"
      @click="closePopup"
    />
    <div
      ref="modal"
      class="two-blocks-popup"
    >
      <img
        src="/images/icons/simple_shapes/cross.svg"
        alt="close"
        class="popup__close"
        @click="closePopup"
      >
      <div id="popup-wrapper" class="popup-wrapper">
        <div class="popup-left-block scrollable-Y">
          <slot name="popup-left-block" />
        </div>
        <div class="popup-right-block scrollable-Y">
          <slot name="popup-right-block" />
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: 'TwoBlocksPopup',
  emits: ['closePopup'],

  mounted() {
    this.$refs.modal.style.top = `calc(15vh + ${window.scrollY}px)`;
    this.onResize();
    window.addEventListener('resize', this.onResize);
    document.body.style.overflow = 'hidden';
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize);
    document.body.style.overflow = 'initial';
  },

  methods: {
    closePopup() {
      this.$emit('closePopup');
    },

    onResize() {
      document.getElementById('popup-wrapper').style.height = `${window.innerHeight * 0.8}px`;
    },
  },
};
</script>

<style scoped>
.two-blocks-popup {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 400;
}

.popup__close {
  width: 30px;
  height: 30px;
  position: absolute;
  z-index: 400;
  top: 20px;
  right: 25px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #ddd;
  background: #fff;
}

.popup-wrapper {
  background: #fff;
  display: grid;
  grid-template-columns: 500px 320px;
  border-radius: 15px;
}

.popup-left-block {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 10px 0 25px;
}

.popup-right-block {
  top: 0;
  right: 0;
  max-width: 300px;
  padding: 0 20px;
  box-shadow: -4px 0 4px -4px rgba(0, 0, 0, 0.25);
}

.popup__background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0);
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: 202;
  display: block;
}
</style>
