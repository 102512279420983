<template>
  <div class="row">
    <p class="row__description">
      Демо-файл:
    </p>
    <p
      class="row__link"
      :class="getClasses"
      @click="clickDemo"
    >
      Скачать демо
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowDemo',

  props: {
    visibleDemoSection: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    getClasses() {
      return {
        row__link_hide: this.visibleDemoSection,
      };
    },
  },

  methods: {
    clickDemo() {
      this.$emit('choose-demo-section');
    },
  },
};
</script>

<style scoped src="./css/Row.css"></style>
