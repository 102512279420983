<template>
  <label class="payment-popup-button">
    <input
      id="cashless_payment_form__bill_button_id"
      type="submit"
      style="display: none"
    >
    <label
      for="cashless_payment_form__bill_button_id"
      class="cashless_payment_form__bill_button_text"
    >
      Распечатать счет
    </label>
  </label>
</template>

<script>
export default {
  name: 'BillButton',
};
</script>

<style scoped src="../../../../assets/css/element.css" />

<style scoped>
.cashless_payment_form__bill_button_text {
  cursor: pointer;
}
</style>
