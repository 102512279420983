const METHOD = '/api/component/balance';

export default {
    async payByBalance(orderId, clientId, service) {
        const requestUrl = `${METHOD}/payment?order_id=${orderId}&client_id=${clientId}&service=${service}`;
        const requestOptions = { method: 'GET' };

        const response = await fetch(requestUrl, requestOptions);
        if (response.ok === true) {
            return await response.json();
        }
        return false;
    },
    refillBalance({ email, cost }) {
        return fetch(`${METHOD}/log_refill`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                cost,
            }),
        })
            .then((res) => res.json())
            .catch(() => {
                alert('Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775-29-12 (доб. 1)');
            });
    },
};
