<!-- компонент реализуется уникально для каждого радела -->
<template>
  <div>
    <PayPopup
      v-if="visiblePayPopup"
      :visible-pay-popup="visiblePayPopup"
      :email="email"
      :order-id="orderId"
      :client-id="clientId"
      :customer-is-authorized="customerIsAuthorized"
      :client-balance="clientBalance"
      :cost="cost"
      :count-companies="count"
      :initial-tab="initialTab"
      :user-discount="userDiscount"
      :with-save-section="false"
      with-demo-section
      :initial-section="initialSection"
      :demo-creation-is-running="demoCreationIsRunning"
      :demo-created-successfully="demoCreatedSuccessfully"
      :show-order-preloader="showOrderPreloader"
      with-promocode
      payment-target="database"
      @close-popup="closePayPopup"
      @input-email="changeEmail"
      @create-order="createOrder"
      @create-bill="createBill"
      @init-login="initLogin"
      @download-demo="downloadDemoFile"
    >
      <template v-slot:description>
        <Description />
      </template>
    </PayPopup>
    <div class="_result_new_company new_company_summary_fixed_wrapper">
      <Card
        :total-cost="cost"
        :total-count="countForSummary"
        :relative-discount="relativeDiscountValue"
        :visible-discount="visibleDiscount"
        :balance-bonus-notice="false"
        :is-subscription-mode="isSubscriptionMode"
        :subscription-deposit="subscriptionConfigData.deposit_add_sum"
        :is-subscription-mode-balance-only="!subscriptionConfigData.deposit_refill"
        :show-one-cost-firm="isSubscriptionMode"
        :balance="subscriptionConfigData.balance_for_calculation"
        @demoClick="demoClick"
        @downloadButtonClick="downloadButtonClick"
        @subscriptionButtonClick="subscriptionButtonClick"
      >
        <template v-slot:notifications>
          <NewCompanyNotificationList
            :company-cost="cost"
            :phone-selected="phoneSelected"
            :email-selected="emailSelected"
            :selected-legal-forms="getSelectedLegalForms"
            :selected-city-count="selectedCityCount"
            :selected-months-count="selectedMonthsCount"
            :selected-okved-count="getSelectedOkveds.length"
            :legal-form-disabled="legalFormDisabled"
            :all-okved-selected="allOkvedsSelected"
            :is-subscription-mode="isSubscriptionMode"
            :subscription-count-is-low="compCountPerSubscrPeriodIsLow"
            :subscription-period-text="periodAdjective"
            :subscription-prev-period-text="previousPeriodText"
            :subscription-start-date="subscriptionStartDateHumanReadable"
            @openCityTree="openCityTree"
            @openCashlessPopup="openCashlessPopup"
          />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import Description from '@/new_company/new_company_configurator/components/Description/Description.vue';
import Card from '../../common_components/Card/Card.vue';
import NewCompanyNotificationList from './components/NewCompanyNotificationList/NewCompanyNotificationList.vue';
import PayPopup from '../../common_components/PayPopup/PayPopup.vue';

export default {
  name: 'NCSummary',

  components: {
    Description,
    PayPopup,
    Card,
    NewCompanyNotificationList,
  },

  computed: {

    compCountPerSubscrPeriodIsLow() {
      return (this.approximateCompCountPerSubscrPeriod === 1);
    },

    periodAdjective() {
      switch (this.chosenSubscriptionPeriod) {
        case 'week':
          return 'еженедельной';
        case 'month':
          return 'ежемесячной';
      }
    },

    previousPeriodText() {
      switch (this.chosenSubscriptionPeriod) {
        case 'week':
          return 'ую неделю';
        case 'month':
          return 'ий месяц';
      }
    },

    ...mapState('summary', [
      'cost',
      'count',
      'visibleDiscount',
      'relativeDiscountValue',
      'phoneSelected',
      'emailSelected',
      'isSubscriptionMode',
      'approximateCompCountPerSubscrPeriod',
      'subscriptionConfigData',
    ]),

    ...mapState('payPopup', [
      'email',
      'orderId',
      'clientId',
      'clientBalance',
      'customerIsAuthorized',
      'visiblePayPopup',
      'userDiscount',
      'initialSection',
      'demoCreationIsRunning',
      'demoCreatedSuccessfully',
      'initialTab',
      'showOrderPreloader',
    ]),

    ...mapGetters([
      'legalFormDisabled',
      'getSelectedLegalForms',
    ]),

    ...mapGetters('tree', ['selectedCities', 'hasSelectedCities']),

    ...mapGetters('monthSelector', [
      'selectedMonthsCount',
    ]),

    ...mapGetters('okvedTree', [
      'hasSelectedOkveds',
      'getSelectedOkveds',
      'allOkvedsSelected',
    ]),

    ...mapGetters('payPopup', [
      'getDataForDemoFile',
      'getDataToCreateOrder',
      'getUrlForBill',
    ]),

    ...mapGetters('summary', [
      'chosenSubscriptionPeriod',
      'subscriptionStartDateHumanReadable',
      'enoughForCount',
    ]),

    countForSummary() {
      if (!this.isSubscriptionMode) {
        return this.count;
      }
      return this.enoughForCount;
    },

    selectedCityCount() {
      return this.selectedCities.length;
    },
  },

  mounted() {
    this.setPrice();
    this.setSubscriptionInitialData();
  },

  methods: {
    ...mapMutations(['setVisibleTree', 'setVisiblePaymentPopup']),
    ...mapActions('summary', [
      'setPrice',
      'setSubscriptionInitialData',
    ]),

    ...mapMutations('payPopup', [
      'setTypePayment',
      'setOldBeznalData',
      'setVisiblePayPopup',
      'setEmail',
      'setOrderId',
      'setClientId',
      'setOptionOldBeznalData',
      'setInitialSection',
      'setDemoCreationIsRunning',
      'setDemoCreatedSuccessfully',
      'setInitialTab',
      'setShowOrderPreloader',
    ]),

    ...mapActions('payPopup', [
      'getUserData',
      'getUserDiscount',
      'getDemoFileHash',
      'getAuthStatus',
    ]),

    ...mapActions('payPopup', {
      createOrderStore: 'createOrder',
    }),

    async downloadDemoFile({ format, option, email }) {
      this.setDemoCreationIsRunning(true);

      let dataForDemo = this.getDataForDemoFile;
      dataForDemo.format = format;
      dataForDemo.email = email;
      const optionText = option ? '&options=hyperlinks_phone' : '';
      const { message, error } = await this.getDemoFileHash(dataForDemo);

      this.setDemoCreationIsRunning(false);
      if (error) {
        this.setDemoCreatedSuccessfully(false);
        return echo(message, 6000, 'warning');
      }
      this.setDemoCreatedSuccessfully(true);

      location.href = '/handlers/DEMO_new_companies_tab_handler.php?code='
                + message + '&format=' + format + optionText;

      window.echo(
        'Демо-выгрузка сформирована и скачивается. Ссылка отправлена на ваш E-mail',
        6000,
        'success',
      );
    },

    changeEmail(email) {
      this.setEmail(email);
      this.getUserDiscount(email);
    },

    async createOrder() {
      this.setShowOrderPreloader(true);
      this.setTypePayment('10');
      await this.getUserDiscount(this.email);

      const res = await this.createOrderStore(this.getDataToCreateOrder);

      this.setOrderId(String(res.id_plan));
      this.setClientId(Number(res.customer_number));

      this.setShowOrderPreloader(false);
      echo(
        'Заказ успешно оформлен. Вы можете перейти к оплате, нажав на кнопку «Оплатить»',
        6000,
        'success',
      );
      window.openWebSocketChannel(res.id_plan, res.hash);
    },

    addEventForBalanceBlock() {
      document.body.addEventListener('open-balance-block', async () => {
        await this.getAuthStatus();
        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setInitialTab('BalanceForm');
        await this.setVisiblePayPopup(true);
        this.setInitialTab('');
      });
    },

    initLogin() {
      window.open_balance_block = true;
      this.addEventForBalanceBlock();
      this.setVisiblePayPopup(false);
      document.dispatchEvent(new Event('openAuthWrapper'));
    },

    async createBill(oldBeznalData) {
      this.setTypePayment('11');
      this.setOldBeznalData(oldBeznalData);

      const res = await this.createOrderStore(this.getDataToCreateOrder);

      this.setOptionOldBeznalData({
        option: 'umb_schet',
        value: res.id_plan,
      });
      this.setOptionOldBeznalData({
        option: 'count_for_beznal',
        value: res.count_for_beznal,
      });
      this.setOptionOldBeznalData({
        option: 'cost_for_beznal',
        value: res.cost_for_beznal,
      });

      location.href = this.getUrlForBill;
    },

    async demoClick() {
      if (this.cost > 0) {
        await this.getAuthStatus();

        if (this.customerIsAuthorized) {
          await this.getUserData();
        }
        this.setInitialSection('demo');
        this.setVisiblePayPopup(true);
      } else {
        await window.vue_demo_popup.$store.dispatch('openPopup');
      }
    },

    openCityTree() {
      this.setVisibleTree(true);
    },

    openCashlessPopup() {
      this.setInitialTab('CashlessPaymentForm');
      this.setVisiblePayPopup(true);
    },

    async downloadButtonClick() {
      if (this.cost === 0) {
        return echo('Выберите больше фирм', 6000, 'warning');
      }

      window.MFuncs.reachYandexGoal('config');

      await this.getAuthStatus();

      if (this.customerIsAuthorized) {
        await this.getUserData();
      }

      this.setVisiblePayPopup(true);
    },

    subscriptionButtonClick() {
      window.yandexReachGoalConfig();

      if (this.legalFormDisabled) {
        return window.echo('Выберите тип компании.');
      }
      if (!this.hasSelectedCities) {
        return window.echo('Выберите города.');
      }
      if (!this.hasSelectedOkveds) {
        return window.echo('Выберите ОКВЭД.');
      }

      this.openPaymentPopup();
    },

    openPaymentPopup() {
      this.setVisiblePaymentPopup(true);

      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    closePayPopup() {
      this.setVisiblePayPopup(false);
      this.setInitialSection('');
      this.setInitialTab('');
    },
  },
};
</script>
