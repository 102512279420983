import axios from 'axios';

const api = {
    async getInitialData() {
        return (await axios.get('/api/new_company/count_month_data')).data;
    },

    getPrice() {
        return fetch('/api/new_company/price', { method: 'GET' })
           .then(price => price.json());
    },

    getSubscriptionInitialData() {
        return fetch('api/new_company/subscription_initial_data', { method: 'GET' })
            .then(data => data.json())
    },

    getApproximateCompCountPerSubscrPeriod() {
        const requestUrl = '/api/new_company/approximate_comp_count_per_subscr_period';
        // todo: пока деревья городов и ОКВЭДов не переведены на vue-компоненты, придется так делать
        const configData = window.MNewCompaniesConfiguration.getConfigurationData();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(configData)
        };

        return fetch(requestUrl, requestOptions)
            .then(response => {
                if (response.ok) {
                    return response.text();
                }
            })
            .catch(() => {
                alert(`Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)`);
            });
    },

    getGeoUnits() {
          return fetch('/api/new_company/cities', {method: 'GET'})
            .then(res => res.json());
    },

    getUserData() {
        return fetch('/api/authorized_client_data', {method: 'GET'})
            .then(res => res.json());
    },

    checkAuth() {
        return fetch('api/client_is_authenticated', {method: 'GET'})
            .then(res => res.json());
    },

    getUserDiscount(email) {
        return fetch('/handlers/email_discount.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
                email: email,
            }),
        })
            .then(res => res.json())
            .catch(() => {
                echo(
                    `Произошла ошибка! Пожалуйста, обратитесь в тех. поддержку: 8 (800) 775 - 29 - 12 (доб. 1)`,
                    6000,
                    'error',
                );
            })
    },

    getDemoFileHash(dataForDemo) {
        return fetch('/handlers/demo_new_companies.php', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify({
                ajax: true,
                data: dataForDemo,
            }),
        });
    },

    async getOkveds() {
        return (await axios.get('/api/new_company/okveds')).data;
    },

    createOrder(dataForOrder) {
        return fetch('/handlers/new_companies_payment_ajax.php', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(dataForOrder),
        });
    },

};

export default api;
