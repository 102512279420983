<template>
  <form
    class="save"
    @submit.prevent="saveOrder"
  >
    <div class="save-content">
      <p class="save-content__text">
        Если вы <b>не готовы оплатить</b> выгрузку сейчас, мы сохраним вашу конфигурацию выбранной базы данных!
        <br>
        <br>
        Вы сможете оплатить её в любое удобное для вас время в личном кабинете.
      </p>
      <img
        alt="save"
        src="/dist/css/images/floppy.png"
        class="save-content__img"
      >
      <p class="save-content__description">
        Укажите свой <b>e-mail</b> и нажмите <b>"Сохранить"</b>, на указанный адрес мы отправим данные для
        оплаты выгрузки в дальнейшем.
        <br>
        После оплаты вы сможете получить выгрузку. Конфигурация будет сохранена и доступна в личном кабинете.
      </p>
    </div>
    <img
      class="save__arrow"
      src="/dist/css/images/arrow.png"
      alt="arrow"
    >
    <TextInput
      title="Ваш E-mail"
      placeholder=""
      :value="getEmail"
      :valid="isValidEmail"
      :disabled="customerIsAuthorized"
      :need-validation="needValidationEmail"
      @handle-input="setEmail"
      @click="clickEmail"
    />
    <label class="save-agreement">
      <input
        v-model="agree"
        type="checkbox"
        class="save-agreement__checkbox"
      >
      <span class="save-agreement__text">
        Даю согласие на обработку моего e-mail в соответствии с
        <a
          class="save-agreement__link"
          href="https://export-base.ru/privacy_policy"
          target="_blank"
        >
          политикой конфиденциальности ExportBase
        </a>
      </span>
    </label>
    <img
      class="save__arrow"
      src="/dist/css/images/arrow.png"
      alt="arrow"
    >
    <button class="save-button">
      Сохранить
    </button>
    <button
      class="prev-section"
      @click.prevent="prevSection"
    >
      Вернуться назад, к оплате
    </button>
  </form>
</template>

<script>
import TextInput from '@/common_components/PayPopup/cashlessForm/Form/TextInput.vue';

export default {
  name: 'SaveSection',
  components: {
    TextInput,
  },

  props: {
    propEmail: {
      type: String,
      required: true,
    },

    customerIsAuthorized: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      email: '',
      needValidationEmail: false,
      agree: true,
    };
  },

  computed: {
    isValidEmail() {
      const regExpEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
      return regExpEmail.test(this.getEmail);
    },

    getEmail() {
      return this.customerIsAuthorized ? this.propEmail : this.email;
    },
  },

  methods: {
    setEmail(e) {
      this.needValidationEmail = true;
      this.email = e.target.value;
    },

    clickEmail() {
      if (this.customerIsAuthorized) {
        echo('Чтобы изменить эл. адрес, необходимо выйти из личного кабинета', 6000, 'warning');
      }
    },

    saveOrder() {
      if (!this.agree) {
        return echo('Подвердите согласие с политикой конфиденциальности!');
      }
      this.$emit('save-order', this.getEmail);
    },

    prevSection() {
      this.$emit('choose-main-section');
    },
  },
};
</script>

<style scoped src="./SaveSection.css"></style>
