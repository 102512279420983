<template>
  <div class="row">
    <p class="row__description">
      Стоимость:
    </p>
    <div class="row-wrapper">
      <p
        class="row__price"
        :class="getClassesForCost"
      >
        {{ cost.toLocaleString('ru') }} руб.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RowCost',
  props: {
    cost: {
      type: Number,
      required: true,
    },

    deactivated: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    getClassesForCost() {
      return {
        row__price_deactivated: this.deactivated,
      };
    },
  },
};
</script>

<style scoped src="./css/Row.css"></style>
