import { createStore } from 'vuex';
import okvedTree from '@/new_company/store/modules/okvedTree';
import actions from './actions';
import mutations from './mutations';
import state from './state';
import getters from './getters';
import monthSelector from './modules/monthSelector';
import summary from './modules/summary';
import tree from './modules/tree';
import payPopup from './modules/payPopup';

const store = createStore({
    state,
    getters,
    mutations,
    actions,
    modules: {
        monthSelector,
        summary,
        tree,
        payPopup,
        okvedTree,
    },
});

export default store;
