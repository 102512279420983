<template>
  <span class="promocode" title="Скопировать" @click="copyPromocode">{{code}}</span>
</template>

<script>
export default {
  name: "Promocode",
  props: {
    code: {
      type: String,
      required: true,
    },
  },

  methods: {
    copyPromocode() {
      navigator.clipboard.writeText(this.code);
      window.echo('Промокод скопирован', 6000, 'success');
    },
  },
}
</script>

<style scoped>
.promocode {
  padding: 5px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: yellow;
}
</style>
