<template>
  <div class="field_phone">
    <div class="filter__item_switch">
      <div class="filter__switch-row">
        <ToggleSwitch
          :id="filter.name"
          :title="filter.title"
          :chosen="filter.selected"
          :mode="['exportbase']"
          @set-chosen-item="toggleFilter"
        />
      </div>
    </div>

    <div class="filter__item_checkbox">
      <CheckboxFillMode
        :filter="filter"
        @changeFilter="changeFilter"
      />
    </div>
  </div>
</template>

<script>
import ToggleSwitch from 'toggle-switch';
import CheckboxFillMode from '../CheckboxFillMode/CheckboxFillMode.vue';

export default {
  name: 'FieldPhone',
  components: {
    ToggleSwitch,
    CheckboxFillMode,
  },

  props: {
    filter: Object,
  },

  emits: ['changeFilter', 'toggleFilter'],

  methods: {
    toggleFilter() {
      this.$emit('toggleFilter', this.filter.name);
    },

    changeFilter(filterName) {
      this.$emit('changeFilter', filterName);
    },
  },
};
</script>

<style scoped>
.field_phone {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.filter__item_switch {
  display: block;
  width: 208px;
}

.filter__switch-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.filter__item_checkbox {
  display: block;
  text-align: left;
  width: 200px;
  margin-left: 7px;
}
</style>
