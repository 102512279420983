<template>
  <div class="row">
    <p class="row__description">
      Выгрузка:
    </p>
    <p class="row__companies">
      {{ countCompanies.toLocaleString('ru') }} компании
    </p>
  </div>
</template>

<script>
export default {
  name: 'RowBase',
  props: {
    countCompanies: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped src="./css/Row.css"></style>
