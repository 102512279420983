<template>
  <div
    class="bottom_filters"
  >
    <AdditionalFilters
      class="additional-filters"
      @toggleExpandFilters="toggleExpandAdditionalFilters"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import AdditionalFilters from '../AdditionalFilters/AdditionalFilters.vue';
import AffiliateSumIncomeAndMaxIncome from '../AffiliateSumIncomeAndMaxIncome/AffiliateSumIncomeAndMaxIncome.vue';

export default {
  name: 'NewCompanyButtonFilters',
  components: {
    AdditionalFilters,
    AffiliateSumIncomeAndMaxIncome,
  },

  computed: {
    ...mapGetters([
      'expandAdditionalFilters',
      'getPhoneSelected',
    ]),
  },

  methods: {
    ...mapMutations([
      'toggleExpandAdditionalFilters',
    ]),
  },
};
</script>

<style scoped>
.bottom_filters {
  display: inline-flex;
  margin-top: 5.2%;
  flex-direction: column;
  position: relative;
}

.additional-filters {
  margin-top: auto;
}
</style>
