<template>
  <div
    class="useBalanceOption"
    :class="{'active': active}"
  >
    Оформить без пополнения счета
  </div>
</template>

<script>
export default {
  name: 'UseBalanceOption',
  props: {
    active: {
      active: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped src="../../Subscription.css"></style>
