<template>
  <div>
    <Bill
      v-if="!(noChoice || propBillOnlyWithNoChoice)"
      :bill-only="billOnly"
      @setBillOnlyState="setBillOnlyState"
    />
    <div class="cashless_payment_form__detach">
      <Form
        :bill-only="billOnly"
        :user-email="userEmail"
        :email-is-disabled="getEmailIsDisabled"
        :bill-button="billButton"
        :client-data="clientData"
        :document-management="documentManagement"

        @set-email="setEmail"
        @set-inn="setInn"
        @set-ogrn="setOgrn"
        @set-kpp="setKpp"
        @set-company-name="setCompanyName"
        @set-address="setAddress"
        @set-payer-name="setPayerName"
        @set-payer-position="setPayerPosition"
        @set-action-document="setActionDocument"
        @set-action-document-number="setActionDocumentNumber"
        @set-post-index="setPostIndex"
        @set-locality="setLocality"
        @setStreet="setStreet"
        @setHouseNumber="setHouseNumber"
        @setOffice="setOffice"
        @handle-privacy-policy="setAgreeWithPrivacyPolicy"
        @setDocumentManagement="setDocumentManagement"
        @create-bill="createBill"
      />
    </div>
  </div>
</template>

<script>
import Form from './Form/Form.vue';
import Bill from './Switch/Bill.vue';
import DocumentManagement from './Switch/DocumentManagement.vue';
import ValidateValueHelper from './helper/ValidateValueHelper';
import api from './api/api';

export default {
  name: 'CashlessPaymentForm',
  components: { DocumentManagement, Form, Bill },
  props: {
    userEmail: {
      type: String,
      default: '',
      required: false,
    },

    userOrderId: {
      type: String,
      default: '',
      required: false,
    },

    userCount: {
      type: Number,
      default: -1,
      required: false,
    },

    userCost: {
      type: Number,
      default: -1,
      required: false,
    },

    emailIsDisabled: {
      type: Boolean,
      default: false,
      required: false,
    },

    billButton: {
      type: Boolean,
      default: true,
      required: false,
    },

    propBillOnlyWithNoChoice: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {
      agreeWithPrivacyPolicy: true,

      email: '',
      inn: '',
      ogrn: '',
      kpp: '',
      companyName: '',
      address: '',
      payerName: '',
      chosenPosition: 'director',
      chosenActionDocument: 'regulation',
      actionDocumentNumber: '',
      postIndex: '',
      locality: '',
      street: '',
      houseNumber: '',
      office: '',

      noChoice: false,
      billOnly: true,
      // values: electronic or post
      documentManagement: 'electronic',
      typesOfDocumentManagement: ['electronic', 'post'],

      // этому полю можно присвоить значение из легаси
      dataEmailIsDisabled: false,

      orderId: '',
      count: '',
      cost: '',

      watchEmail: true,
      watchInn: true,
    };
  },

  computed: {
    getEmailIsDisabled() {
      return this.emailIsDisabled || this.dataEmailIsDisabled;
    },

    oldFormatChosenPosition() {
      switch (this.chosenPosition) {
        case 'director':
          return 'position_director';
        case 'ceo':
          return 'position_gendirector';
        case 'leader':
          return 'position_chief';
      }

      return '';
    },

    oldFormatChosenActionDocument() {
      const actionDocNumber = this.actionDocumentNumber.trim().length === 0
        ? ''
        : this.actionDocumentNumber.trim().match(/\d+/g).join('');

      switch (this.chosenActionDocument) {
        case 'regulation':
          return 'ustav_ustav';
        case 'certificate':
          return `ustav_svid_${actionDocNumber}`;
        case 'power_of_attorney':
          return `ustav_dover_${actionDocNumber}`;
      }

      return '';
    },

    clientDataIsValid() {
      let valid = true;

      valid = valid && ValidateValueHelper.validateEmail(this.email);
      valid = valid && ValidateValueHelper.validateInn(this.inn);
      valid = valid && ValidateValueHelper.validateOgrn(this.ogrn);

      if (!ValidateValueHelper.innRefersToIndividualEntrepreneur(this.inn)) {
        valid = valid && ValidateValueHelper.validateKpp(this.kpp);
      }

      valid = valid && ValidateValueHelper.notEmpty(this.companyName);
      valid = valid && ValidateValueHelper.notEmpty(this.address);

      if (!this.billOnly) {
        valid = valid && ValidateValueHelper.notEmpty(this.payerName);
      }

      if (this.documentManagement === 'post') {
        valid = valid && ValidateValueHelper.validatePostIndex(this.postIndex);
        valid = valid && ValidateValueHelper.notEmpty(this.locality);
        valid = valid && ValidateValueHelper.notEmpty(this.street);
        valid = valid && ValidateValueHelper.notEmpty(this.houseNumber);
        valid = valid && ValidateValueHelper.notEmpty(this.office);
      }

      valid = valid && this.agreeWithPrivacyPolicy;

      return valid;
    },

    clientData() {
      return {
        agreeWithPrivacyPolicy: this.agreeWithPrivacyPolicy,
        email: this.email,
        inn: this.inn,
        ogrn: this.ogrn,
        kpp: this.kpp,
        companyName: this.companyName,
        address: this.address,
        payerName: this.payerName,
        chosenPosition: this.chosenPosition,
        chosenActionDocument: this.chosenActionDocument,
        actionDocumentNumber: this.actionDocumentNumber,
        postIndex: this.postIndex,
        locality: this.locality,
        street: this.street,
        houseNumber: this.houseNumber,
        office: this.office,
        documentManagement: this.documentManagement,
      };
    },

    orderData() {
      return {
        orderId: this.getOrderId,
        count: this.getCount,
        cost: this.getCost,
      };
    },

    billOnlyWithNoChoice() {
      return this.noChoice || this.propBillOnlyWithNoChoice;
    },

    // данные для серверной части, представленные в старом формате
    oldFormatBeznalData() {
      return {
        email_for_beznal: this.getEmail,
        umb_schet: this.getOrderId,
        count_for_beznal: this.getCount,
        cost_for_beznal: this.getCost,
        nazv: this.companyName,
        urAdr: this.address,
        inn: this.inn,
        ogrn: this.ogrn,
        kpp: this.kpp,
        director: this.valueDependsOnBillOnly(this.payerName),
        position: this.valueDependsOnBillOnly(this.oldFormatChosenPosition),
        ustav: this.valueDependsOnBillOnly(this.oldFormatChosenActionDocument),
        postIndex: this.valueDependsOnDocumentManagement(this.postIndex),
        town: this.valueDependsOnDocumentManagement(this.locality),
        street: this.valueDependsOnDocumentManagement(this.street),
        house: this.valueDependsOnDocumentManagement(this.houseNumber),
        office: this.valueDependsOnDocumentManagement(this.office),
        document_management: this.documentManagement,
      };
    },

    getEmail() {
      return this.userEmail.length === 0 ? this.email : this.userEmail;
    },

    getOrderId() {
      return this.userOrderId === '' ? this.orderId : this.userOrderId;
    },

    getCount() {
      return this.userCount === -1 ? this.count : this.userCount;
    },

    getCost() {
      return this.userCost === -1 ? this.cost : this.userCost;
    },
  },

  created() {
    api.getUserEmail().then((data) => {
      this.setValueEmail(data);
    });
  },

  methods: {
    createBill() {
      if (this.clientDataIsValid) {
        this.$emit(
          'refillCashless',
          this.oldFormatBeznalData,
        );
      } else {
        echo('Укажите реквизиты компании и ваш E-mail!');
      }
    },

    valueDependsOnBillOnly(value) {
      return this.billOnly ? '' : value;
    },

    valueDependsOnDocumentManagement(value) {
      return this.documentManagement === 'electronic' ? '' : value;
    },

    setAgreeWithPrivacyPolicy(value) {
      this.agreeWithPrivacyPolicy = value;
    },

    setEmailIsDisabled(value) {
      this.dataEmailIsDisabled = value;
    },

    /*
            value типа bool
             */
    setBillOnlyState(value) {
      this.billOnly = value;
    },

    setNoChoice(value) {
      this.noChoice = value;
    },

    setDocumentManagement(value) {
      this.documentManagement = value;
    },

    setEmail(e) {
      this.email = e.target.value;
      const componentData = this;

      if (componentData.watchEmail) {
        componentData.watchEmail = false;
        setTimeout(
          () => {
            if (ValidateValueHelper.validateEmail(componentData.email)) {
              api.getUserCompanyDataByEmail(componentData.email)
                .then((data) => {
                  if (Object.keys(data).length !== 0) {
                    componentData.setCompanyDataByEmail(data);
                  }
                });
            }
            componentData.watchEmail = true;
          },
          800,
        );
      }
    },

    setInn(e) {
      this.inn = e.target.value;
      const componentData = this;

      if (componentData.watchInn) {
        componentData.watchInn = false;
        setTimeout(
          () => {
            if (ValidateValueHelper.validateInn(componentData.inn)) {
              api.getUserCompanyDataByInn(componentData.inn).then((data) => {
                if (Object.keys(data).length !== 0) {
                  componentData.setCompanyDataByInn(data);
                }
              });
            }
            componentData.watchInn = true;
          },
          800,
        );
      }
    },

    setValueEmail(value) {
      this.email = value;

      if (ValidateValueHelper.validateEmail(this.email)) {
        api.getUserCompanyDataByEmail(this.email)
          .then((data) => {
            if (Object.keys(data).length !== 0) {
              this.setCompanyDataByEmail(data);
            }
          });
      }
    },

    setOgrn(e) {
      this.ogrn = e.target.value;
    },

    setKpp(e) {
      this.kpp = e.target.value;
    },

    setCompanyName(e) {
      this.companyName = e.target.value;
    },

    setAddress(e) {
      this.address = e.target.value;
    },

    setPayerName(e) {
      this.payerName = e.target.value;
    },

    setPayerPosition(e) {
      this.chosenPosition = e.target.id;
    },

    setActionDocument(e) {
      this.chosenActionDocument = e.target.id;
      this.actionDocumentNumber = '';
    },

    setActionDocumentNumber(value) {
      this.actionDocumentNumber = value;
    },

    setPostIndex(e) {
      this.postIndex = e.target.value;
    },

    setLocality(e) {
      this.locality = e.target.value;
    },

    setStreet(e) {
      this.street = e.target.value;
    },

    setHouseNumber(e) {
      this.houseNumber = e.target.value;
    },

    setOffice(e) {
      this.office = e.target.value;
    },

    setCompanyDataByEmail(data) {
      this.inn = this.setValueFromServer(this.inn, data.inn);
      this.ogrn = this.setValueFromServer(this.ogrn, data.ogrn);
      this.kpp = ValidateValueHelper.innRefersToIndividualEntrepreneur(this.inn)
        ? '' : this.setValueFromServer(this.kpp, data.kpp);
      this.companyName = this.setValueFromServer(this.companyName, data.companyName);
      this.address = this.setValueFromServer(this.address, data.address);
      this.payerName = this.setValueFromServer(this.payerName, data.payerName);
      this.chosenPosition = this.setValueFromServer(
        this.chosenPosition,
        this.parsePayerPosition(data.chosenPosition),
      );

      // из метода parseActionDocument вернется не строка и не число,
      // а объект {document: <вид документа>, documentNumber: <номер документа>}!!!
      const result = this.parseActionDocument(data.chosenActionDocument);

      this.chosenActionDocument = this.setValueFromServer(this.chosenActionDocument, result.document);
      this.actionDocumentNumber = this.setValueFromServer(this.actionDocumentNumber, result.documentNumber);
      this.postIndex = this.setValueFromServer(this.postIndex, data.postIndex);
      this.locality = this.setValueFromServer(this.locality, data.locality);
      this.street = this.setValueFromServer(this.street, data.street);
      this.houseNumber = this.setValueFromServer(this.houseNumber, data.houseNumber);
      this.office = this.setValueFromServer(this.office, data.office);
      this.documentManagement = this.setValueFromServer(
        this.documentManagement,
        this.parseDocumentManagement(data.documentManagement),
      );

      if (!this.billOnlyWithNoChoice) {
        this.parseBillOnly(this.payerName);
      }
    },

    setCompanyDataByInn(data) {
      this.ogrn = this.setValueFromServer(this.ogrn, data.ogrn);
      this.companyName = this.setValueFromServer(this.companyName, data.companyName);
      this.payerName = this.setValueFromServer(this.payerName, data.payerName);

      let { address } = data;
      const { postIndex } = data;
      if (address && postIndex) {
        address = `${postIndex}, ${address}`;
      }
      this.postIndex = this.setValueFromServer(this.postIndex, postIndex);
      this.address = this.setValueFromServer(this.address, address);
      this.kpp = ValidateValueHelper.innRefersToIndividualEntrepreneur(this.inn)
        ? '' : data.kpp;
    },

    setValueFromServer(frontValue, serverValue) {
      if (serverValue === undefined) {
        return frontValue;
      }

      if (!(typeof serverValue === 'string' || typeof serverValue === 'number')) {
        return frontValue;
      }

      return serverValue.length === 0 ? frontValue : serverValue;
    },

    parsePayerPosition(position) {
      switch (position) {
        case 'position_director':
          return 'director';
        case 'position_gendirector':
          return 'ceo';
        case 'position_chief':
          return 'leader';
        default:
          return 'director';
      }
    },

    parseActionDocument(actionDocument) {
      let document = '';
      let documentNumber = '';

      if (actionDocument.length === 0) {
        return {
          document: 'regulation',
          documentNumber: '',
        };
      }
      const parts = actionDocument.split('_');

      switch (parts[1]) {
        case 'ustav':
          document = 'regulation';
          break;
        case 'svid':
          document = 'certificate';
          break;
        case 'dover':
          document = 'power_of_attorney';
          break;
        default:
          document = 'regulation';
      }

      if (parts.length === 3) {
        documentNumber = parts[2];
      }

      return {
        document,
        documentNumber,
      };
    },

    parseDocumentManagement(documentManagement) {
      return this.typesOfDocumentManagement.indexOf(documentManagement) === -1
        ? this.typesOfDocumentManagement[0] : documentManagement;
    },

    parseBillOnly(payerName) {
      if (payerName.length !== 0) {
        this.billOnly = false;
      }
    },
  },
};
</script>

<style scoped>
    .cashless_payment_form__detach {
        margin: 30px 0;
    }
</style>
