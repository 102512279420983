<template>
  <div class="make_payment_wrapper">
    <div
      class="payment-popup-button"
      :class="{'disabled': disabled}"
      @click="handleClick"
    >
      <slot>Оплатить</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonMakePayment',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click'],

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped src="./../../../../../assets/css/element.css" />

<style scoped>
.make_payment_wrapper {
  position: relative;
  text-align: center;
  margin-left: -15px;
}

.payment-popup-button.disabled {
  cursor: default;
  opacity: .8;
  box-shadow: none;
}

.payment-popup-button.disabled:hover {
  box-shadow: none;
}
</style>
