<template>
  <div class="subscription_from_balance">
    <div class="header">
      Оформление подписки:
    </div>

    <p class="explanation">
      Ниже приведен ваш электронный адрес.
      На этот адрес вы будете получать данные о новых компаниях.
      Чтобы оформить подписку, осталось нажать на кнопку "Оформить".
    </p>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="subscription-arrow"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
      />
    </svg>

    <span class="email">Ваш Email: <span class="email_value">{{ email }}</span></span>

    <ButtonMakePayment
      v-if="!created"
      :disabled="pending"
      class="button"
      @click="createSubscription"
    >
      Оформить
    </ButtonMakePayment>
    <div
      v-else
      class="success"
    >
      Подписка № {{ idSubscription }} оформлена! <br>
      Управлять подписками вы можете в
      <a
        target="_blank"
        href="/auth/subscription"
        class="color-blue"
      ><b>личном кабинете</b></a>
    </div>
  </div>
</template>

<script>
import formurlencoded from 'form-urlencoded';
import ButtonMakePayment from './ButtonMakePayment.vue';
import api from './api';

export default {
  name: 'SubscriptionFromBalance',
  components: {
    ButtonMakePayment,
  },

  props: {
    email: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      pending: false,
      created: false,
      idSubscription: null,
    };
  },

  methods: {
    async createSubscription() {
      if (this.pending) {
        return;
      }
      this.pending = true;

      try {
        const subscriptionData = this.getSubscriptionData();
        const subscription = await api.createSubscription(formurlencoded(subscriptionData));
        this.created = true;
        this.idSubscription = subscription.id;
      } catch (e) {
        alert('К сожалению, не удалось оформить подписку. На сервере произошла ошибка.');
      }

      this.pending = false;
    },

    getSubscriptionData() {
      return window.MNewCompaniesSubscription.getFormData(this.email);
    },
  },
};
</script>

<style scoped src="./../../../../../assets/css/color.css"></style>

<style scoped src="../assets/css/style.css"></style>

<style scoped>
.subscription_from_balance {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  align-self: flex-start;
}

.explanation {
  margin-top: 0;
}

.email {
  margin-top: 10px;
  text-align: center;
}

.email_value {
  font-weight: bold;
}

.button {
  margin-top: 15px;
  margin-left: 0;
}

.success {
  margin-top: 15px;
  font-size: 17px;
  text-align: center;
}

.subscription-arrow {
  width: 30px;
  height: 30px;
}
</style>
