<template>
  <div class="orderDescription">
    <div v-if="!hasSelectedOkveds || !hasSelectedCities || !(monthsSelected || isSubscriptionMode)">
      <div
        v-if="!hasSelectedCities"
        class="orderSettingsInfo"
        @click="openCityTree"
      >
        Города не выбраны,
        <span class="_dotted">
          откройте список
        </span>
        и выберите
      </div>
      <div
        v-else-if="!hasSelectedOkveds"
        class="orderSettingsInfo"
        @click="openOkvedTree"
      >
        ОКВЭД не выбраны,
        <span class="_dotted">
          откройте список
        </span>
        и выберите
      </div>
      <div
        v-else-if="!monthsSelected && !isSubscriptionMode"
        class="orderSettingsInfo"
      >
        Период не выбран, выберите его из списка ниже.
      </div>
    </div>
    <div v-else>
      <div v-if="!isSubscriptionMode">
        <span
          v-if="allOkvedsSelected && hasSelectedCities && monthsSelected"
          class="configParams approved-item"
        >
          <span>Выбраны</span>
          <span
            class="_dotted"
            @click="openOkvedTree"
          >Все ОКВЭД</span>
          <span> по </span>
          <span
            class="_dotted"
            @click="openCityTree"
          >{{ getCityDescription }}</span>
          <span> за {{ selectedMonthsDescription }}</span>
        </span>
        <span
          v-else-if="!allOkvedsSelected && hasSelectedOkveds && hasSelectedCities && monthsSelected"
          class="configParams approved-item"
        >
          <span>Выбраны</span>
          <span
            class="_dotted"
            @click="openOkvedTree"
          >{{ getSelectedOkveds.length }} ОКВЭД</span>
          <span> по </span>
          <span
            class="_dotted"
            @click="openCityTree"
          >{{ getCityDescription }}</span>
          <span> за {{ selectedMonthsDescription }}</span>
        </span>
      </div>
      <div
        v-else
        class="flex-column"
      >
        <span
          v-if="allOkvedsSelected && hasSelectedCities"
          class="configParams approved-item"
        >
          <span>Выбраны</span>
          <span
            class="_dotted"
            @click="openOkvedTree"
          >Все ОКВЭД</span>
          <span> по </span>
          <span
            class="_dotted"
            @click="openCityTree"
          >{{ getCityDescription }}</span>
        </span>
        <span
          v-else-if="!allOkvedsSelected && hasSelectedOkveds && hasSelectedCities"
          class="configParams approved-item"
        >
          <span>Выбраны</span>
          <span
            class="_dotted"
            @click="openOkvedTree"
          >{{ getSelectedOkveds.length }} ОКВЭД</span>
          <span> по </span>
          <span
            class="_dotted"
            @click="openCityTree"
          >{{ getCityDescription }}</span>
        </span>

        <span
          v-if="isSubscriptionMode && hasSelectedCities && hasSelectedOkveds"
          class="configParams approved-item"
        >
          получение {{ periodAdverb }}
        </span>

        <span
          v-if="!compCountPerSubscrPeriodIsLow"
          class="approved-item"
        >
          <span>По выбранным вами критериям подписки поступает примерно</span>
          <b>{{ approximateCompCountPerSubscrPeriod }} {{ companiesWord }} в {{ periodNoun }}</b>
        </span>
        <span
          v-else
          class="disapproved-item"
        >
          Похоже, по выбранным вами критериям не найдется компаний в течение нескольких
          {{ periodGenitive }}
        </span>

        <NotificationComponent
          flipper-position="without-flipper"
          class="configNotification"
        >
          <div v-if="!compCountPerSubscrPeriodIsLow">
            Если вы хотите получать больше компаний, измените фильтры или добавьте другие города или
            ОКВЭД!
          </div>
          <div v-else>
            Рекомендуем добавить города или коды ОКВЭД.
          </div>
        </NotificationComponent>
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapState, mapGetters, mapActions, mapMutations,
} from 'vuex';
import plural from 'plural-ru';
import NotificationComponent from '@/common_components/NotificationComponent/NotificationComponent.vue';

export default {
  name: 'NCSummary',
  components: { NotificationComponent },

  computed: {
    getCityDescription() {
      if (this.selectedCities.length === 1) {
        return this.selectedCities[0].value.obj.name;
      }
      if (this.selectedCities.length > 1 && Array.isArray(this.selectedCities)) {
        return `${this.selectedCities.length} ${
          plural(this.selectedCities.length, 'городу', 'городам', 'городам')}`;
      }

      return 'Города не выбраны';
    },

    compCountPerSubscrPeriodIsLow() {
      return (this.approximateCompCountPerSubscrPeriod === 1);
    },

    companiesWord() {
      return plural(this.approximateCompCountPerSubscrPeriod, 'компания', 'компании', 'компаний');
    },

    periodNoun() {
      switch (this.chosenSubscriptionPeriod) {
        case 'week':
          return 'неделю';
        case 'month':
          return 'месяц';
      }
    },

    periodGenitive() {
      switch (this.chosenSubscriptionPeriod) {
        case 'week':
          return 'недель';
        case 'month':
          return 'месяцев';
      }
    },

    periodAdverb() {
      switch (this.chosenSubscriptionPeriod) {
        case 'week':
          return 'Еженедельно';
        case 'month':
          return 'Ежемесячно';
      }
    },

    ...mapState('summary', [
      'isSubscriptionMode',
      'approximateCompCountPerSubscrPeriod',
    ]),

    ...mapGetters('monthSelector', ['selectedMonthsDescription', 'monthsSelected']),

    ...mapGetters('tree', ['selectedCities', 'hasSelectedCities']),

    ...mapGetters('okvedTree', [
      'getSelectedOkveds',
      'hasSelectedOkveds',
      'allOkvedsSelected',
    ]),

    ...mapGetters('summary', ['chosenSubscriptionPeriod']),
  },

  mounted() {
    this.setPrice();
    this.setSubscriptionInitialData();
  },

  methods: {
    ...mapMutations('okvedTree', { setVisibleOkvedTree: 'setVisibleTree' }),
    ...mapMutations(['setVisibleTree']),
    ...mapActions('summary', [
      'setPrice',
      'setSubscriptionInitialData',
    ]),

    openCityTree() {
      this.setVisibleTree(true);
    },

    openOkvedTree() {
      this.setVisibleOkvedTree(true);
    },
  },
};
</script>

<style scoped>
.orderDescription {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgb(153 153 153) 0 2px 5px 0;
  background: var(--background-color-configurator);
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.configParams {
  font-style: italic;
  font-weight: bold;
}

.configNotification {
  margin-bottom: 0;
}

.approved-item {
  padding-left: 2em;
  line-height: 1.2;
  display: flex;
  gap: 5px;
}

.approved-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: -.2em;
  height: .8em;
  width: .4em;
  display: inline-block;
  transform: rotate(45deg);
  border-bottom: .3em solid var(--color-button-prime-hover);
  border-right: .3em solid var(--color-button-prime-hover);
}

.disapproved-item {
  position: relative;
  padding-left: 2em;
  line-height: 1.2;
}

.disapproved-item::before {
  content: url("/images/warning-info-orange.svg");
  position: absolute;
  left: -.35em;
  height: 1.3em;
  width: 1.3em;
  display: inline-block;
}

.orderSettingsInfo {
  font-size: 16px;
  font-weight: bold;
}

._dotted {
  border-bottom: 1px dashed;
  cursor: pointer;
}
</style>
