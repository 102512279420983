<template>
  <div
    class="additional_filters_new_company"
    title="Настройте доп. фильтры у вашей базы"
    @click="toggleExpandFilters"
  >
    <Checkbox
      class="checkbox"
      :chosen="additionalFiltersChosen"
      :partially-fill="false"
      :mode="['dark']"
    />
    <span class="text">Дополнительные фильтры</span>
  </div>
</template>

<script>
import Checkbox from 'checkbox';
import { mapGetters } from 'vuex';

export default {
  name: 'AdditionalFilters',
  components: {
    Checkbox,
  },

  emits: ['toggleExpandFilters'],

  computed: {
    ...mapGetters([
      'additionalFiltersChosen',
    ]),
  },

  methods: {
    toggleExpandFilters() {
      this.$emit('toggleExpandFilters');
    },
  },
};
</script>

<style scoped>
    .additional_filters_new_company {
        display: flex;
        cursor: pointer;
    }

    .checkbox {
        margin-right: 10px;
    }

    .text {
        position: relative;
        font-weight: bold;
        border-bottom: 1px dashed;
        font-size: 15px;
    }

    .text::after {
        content: '';
        position: absolute;
        right: -28px;
        top: 50%;
        transform: translateY(-50%);
        display: inline-block;
        width: 21px;
        height: 21px;
        background: url('/dist/css/images/arrow_for_index.png') center center no-repeat;
        background-size: contain;
    }
</style>
