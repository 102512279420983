<template>
  <div class="demo-section">
    <transition
      name="transition-obtaining"
      mode="out-in"
    >
      <div
        v-if="beforeDemoRequest"
        key="before"
        class="before-demo-obtaining"
      >
        <p class="demo-section__description">
          Укажите ваш электронный адрес и нажмите <b>"Скачать демо"</b>, вы получите тестовую
          выгрузку по сформированным вами рубрикам и настроенным фильтрам. Скачиваемая
          демо-выгрузка <b>абсолютно бесплатна</b>.
        </p>
        <form
          class="demo-form"
          @submit.prevent="downloadDemo"
        >
          <img
            class="demo-form__arrow"
            src="/dist/css/images/arrow.png"
            alt="arrow"
          >
          <TextInput
            title="Ваш e-mail"
            placeholder=""
            :value="getEmail"
            :valid="isValidEmail"
            :need-validation="needValidationEmail"
            :disabled="customerIsAuthorized"
            @handle-input="setEmail"
            @click="clickEmail"
          />

          <label class="demo-agreement">
            <input
              v-model="agree"
              type="checkbox"
              class="demo-agreement__checkbox"
            >
            <span class="demo-agreement__text">
              Даю согласие на обработку моего e-mail в соответствии с
              <a
                class="demo-agreement__link"
                href="https://export-base.ru/privacy_policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                политикой конфиденциальности ExportBase
              </a>
            </span>
          </label>
          <img
            alt="arrow"
            class="demo-form__arrow"
            src="/dist/css/images/arrow.png"
          >
          <button class="demo-download-button eb-button-prime">
            <span>Cкачать демо</span>
          </button>
        </form>
      </div>

      <div
        v-else
        key="after"
        class="after-demo-obtaining"
      >
        <div
          v-if="showPromocodeSection"
          class="promocode-section"
        >
          <div
            v-if="promocode.createdRightNow"
            class="promocode-created-right-now"
          >
            <span class="promocode-main">
              Дарим промокод <Promocode :code="promocode.promocode" />
              &mdash; скидка {{ promocode.discount }}%
            </span>
            <span class="promocode-additional">
              Используйте при оплате заказа, действует в течение {{ promocodeActivityPeriod }}
            </span>
          </div>
          <div
            v-else
            class="promocode-existing"
          >
            <span class="promocode-main">
              Не забудьте использовать промокод
              <Promocode :code="promocode.promocode" /> при оформлении заказа
              (скидка {{ promocode.discount }}%)
            </span>
            <span class="promocode-additional">
              Промокод будет действовать ещё в течение {{ promocodeActivityPeriod }}
            </span>
          </div>
        </div>
        <span
          class="info"
          :class="{'warning': !createdSuccessfully}"
        >{{ infoText }}</span>
        <a
          v-if="['egrul', 'main_base'].includes(orderType) && createdSuccessfully && !creationIsRunning"
          class="eb-button-prime demo-view-button"
          target="_blank"
          :href="getDemoTableHref"
          @click="goToWebDemoViewerMetric"
        >
          <span>Посмотреть демо онлайн</span>
        </a>
        <button
          type="button"
          class="obtain-again"
          @click="beforeDemoRequest = true;"
        >
          Скачать Демо ещё раз
        </button>
        <div class="feedback">
          <div class="text">
            <span class="fb-title">Ищете базы данных?</span>
            <span class="fb-help">
              Поможем в конфигурации базы, подберем настройки, дадим консультацию по покупке
            </span>
            <span class="fb-phone">
              Телефон: <a
                class="fb-phone-link"
                href="tel:+78007752912"
              >8 800 775-29-12</a>
            </span>
            <span class="fb-email">
              E-mail: <a
                class="fb-email-link"
                href="mailto:info@export-base.ru"
              >info@export-base.ru</a>
            </span>
          </div>
          <img
            src="./manager_Nikita.png"
            alt=""
            class="manager"
          >
        </div>
      </div>
    </transition>

    <button
      type="button"
      class="prev-section"
      @click.prevent="prevSection"
    >
      Вернуться назад, к оплате
    </button>
  </div>
</template>

<script>
import TextInput from '@/common_components/PayPopup/cashlessForm/Form/TextInput.vue';
import Checkbox from 'checkbox';
import plural from 'plural-ru';
import { mapState } from 'vuex';
import api from './api';
import Promocode from '../../Promocode/Promocode.vue';

export default {
  name: 'DemoSection',
  components: {
    Promocode,
    TextInput,
    Checkbox,
  },

  props: {
    orderType: {
      type: String,
      required: false,
      default: '',
    },

    creationIsRunning: {
      type: Boolean,
      required: true,
      default: false,
    },

    createdSuccessfully: {
      type: Boolean,
      required: true,
      default: false,
    },

    propEmail: {
      type: String,
      required: true,
    },

    customerIsAuthorized: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      beforeDemoRequest: true,

      email: '',
      chosenFormat: 'xlsx',
      chosenCallTo: false,
      needValidationEmail: false,
      agree: true,

      promocode: {
        promocode: null,
        discount: null,
        createdRightNow: null,
        expiresInHours: null,
      },
    };
  },

  computed: {
    ...mapState('payPopup', [
      'demoHash',
    ]),

    ...mapState({
      mainBaseDemoHash: 'demoHash',
    }),

    isValidEmail() {
      const regExpEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Zа-яА-Я\-0-9]+\.)+[a-zA-Zа-яА-Я]{2,}))$/;
      return regExpEmail.test(this.getEmail);
    },

    getEmail() {
      return this.customerIsAuthorized ? this.propEmail : this.email;
    },

    infoText() {
      if (this.creationIsRunning) {
        return 'Демо-выгрузка формируется. Пожалуйста, подождите...';
      }
      if (this.createdSuccessfully) {
        return 'Демо-выгрузка скачивается на ваше устройство';
      }
      return 'Не удалось сформировать Демо-выгрузку';
    },

    showPromocodeSection() {
      return (this.promocode.promocode && this.promocode.expiresInHours > 0);
    },

    promocodeActivityPeriod() {
      const hours = this.promocode.expiresInHours;
      const words = plural(hours, 'часа', 'часов', 'часов');
      return `${hours} ${words}`;
    },

    getDemoTableHref() {
      const optionText = this.chosenCallTo ? '&options=hyperlinks_phone' : '';
      switch (this.orderType) {
        case 'main_base':
          if (this.mainBaseDemoHash) {
            return `/api/main_base/demo_viewer?code=${this.mainBaseDemoHash}${optionText}`;
          }
          return `/api/main_base/demo_viewer?code=${this.demoHash}${optionText}`;
        case 'egrul':
          return `/api/egrul_service/demo_viewer?code=${this.demoHash}${optionText}`;
        default:
          return '';
      }
    },
  },

  watch: {
    async creationIsRunning() {
      if (!this.creationIsRunning) {
        try {
          const {
            promocode, discount, createdRightNow, expiresInHours,
          } = await api.tryCreatePromocode(this.getEmail);
          this.promocode = {
            promocode, discount, createdRightNow, expiresInHours,
          };
        } catch (e) {
          console.error('Не удалось получить промокод');
        }
      }
    },
  },

  methods: {
    goToWebDemoViewerMetric() {
      window.MFuncs.yandexReachGoalWithTarget('openWebDemoViewer');
    },

    setEmail(e) {
      this.needValidationEmail = true;
      this.email = e.target.value;
    },

    async downloadDemo() {
      if (!this.isValidEmail) {
        return echo('Введите корректный E-mail', 6000, 'warning');
      }
      if (!this.agree) {
        return echo('Подтвердите согласие с политикой конфиденциальности!');
      }

      this.$emit('download-demo', {
        format: this.chosenFormat,
        option: this.chosenCallTo,
        email: this.getEmail,
      });
      this.beforeDemoRequest = false;
    },

    clickEmail() {
      if (this.customerIsAuthorized) {
        echo('Чтобы изменить эл. адрес, необходимо выйти из личного кабинета', 6000, 'warning');
      }
    },

    prevSection() {
      this.$emit('choose-main-section');
    },
  },
};
</script>

<style scoped src="./DemoSection.css"></style>

<style scoped>
.transition-obtaining-enter-active, .transition-obtaining-leave-active {
  transition: opacity .3s ease;
}

.transition-obtaining-enter, .transition-obtaining-leave-to {
  opacity: 0;
}
</style>
